import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    TextField,
    Button, Grid,
    Box, Divider, Stack, IconButton
} from '@mui/material';
import {AnswerCommentDto, AnswersService, OpenAPI, PreviewUserDto} from "../services/autogen";
import ThemedAvatarChips, {ThemedCard} from "./StylizedComponents";
import SendIcon from '@mui/icons-material/Send';
import TimeHelper from "../helpers/TimeHelper";
import {LoadingCircle} from "./LoadingCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {useAuth} from "../contexts/AuthContext";
import { useConfirm } from "material-ui-confirm";


interface AnswerCommentsProps {
    comments: AnswerCommentDto[],
    answerId: string,
}

function AnswerComments({comments, answerId}:AnswerCommentsProps) {
    const [currentComments, setComments] = useState<AnswerCommentDto[]>(comments);
    const [newComment, setNewComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {currentUser} = useAuth();
    
    const [commentMenuAnchorEl, setCommentMenuAnchorEl] = useState<null | HTMLElement>(null);
    const isCommentMenuOpen = Boolean(commentMenuAnchorEl);
    const confirm = useConfirm();

    const handleOpenAnswerCommentMenu = (event: React.MouseEvent<HTMLElement>) => {
        setCommentMenuAnchorEl(event.currentTarget);
    };
    
    const handleCloseAnswerCommentMenu = () => {
        setCommentMenuAnchorEl(null);
    };


    const handleCommentChange = (event: any) => {
        setNewComment(event.target.value);
    };

    const addComment = async () => {
        if (newComment.trim() !== '') {
            setIsLoading(true);
            let response : AnswerCommentDto = await AnswersService.postApiV1AnswersCreateComment({
                answerId,
                comment: newComment.trim()
            });
            setComments([ response, ...currentComments]);
            setNewComment('');
            setIsLoading(false);
        }
    };
    
    const onDeleteCommentClicked = async (commentId: string) => {
        try {
            await confirm({ description: "Are you sure you want to delete this comment?"});
            let response : AnswerCommentDto = await AnswersService.deleteApiV1AnswersRemoveComment(commentId);
            handleCloseAnswerCommentMenu();
            setComments(currentComments.filter(c => c.id !== commentId));
        } catch(e){
            console.log("abort delete comment");  
        }
    }

    return (
        <Box sx={{ml:1}}>
            <Divider textAlign="left"><Typography variant="caption">Comments</Typography></Divider>
            {currentComments.map((comment) => (
                <ThemedCard key={comment.id} >
                    <CardContent sx={{display: 'flex', fontSize:"small"}}>
                        <Grid container>
                            <Grid item xs={8}>
                                <ThemedAvatarChips name={comment.publisher?.userName} color="primary" imgSrc={comment.publisher?.profilePicture || ""}/>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack direction="row-reverse">
                                    {comment.publisher?.id === currentUser?.id &&
                                        <Box sx={{display:'flex', justifyContent: 'end'}}>
                                            <IconButton aria-label="settings" sx={{alignItems: 'start', padding: '0px'}}
                                                        aria-controls={isCommentMenuOpen ? 'answer-comment-menu' : undefined}
                                                        aria-expanded={isCommentMenuOpen ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={handleOpenAnswerCommentMenu}>
                                                <MoreVertIcon/>
                                            </IconButton>
                                            <Menu
                                                id="answer-comment-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={commentMenuAnchorEl}
                                                open={isCommentMenuOpen}
                                                onClose={handleCloseAnswerCommentMenu}>
                                                <MenuItem key="DeleteComment" onClick={() => onDeleteCommentClicked(comment.id!)}>Delete Comment</MenuItem>
                                            </Menu>
                                        </Box>
                                    }
                                    <Typography sx={{display:'flex', justifyContent: 'end'}} variant="caption">{ TimeHelper.getFriendlyTime(comment.updatedAt)}</Typography>                                     
                                </Stack>
                                
                            </Grid>
                            <Grid item xs={12}>
                                { comment.comment}
                            </Grid>
                        </Grid>
                    </CardContent>
                </ThemedCard>
            ))}
            <TextField
                label="Add a Comment ..."
                variant="filled"
                fullWidth
                value={newComment}
                sx={{maxWidth: '85%', mt: 1}}
                inputProps={{
                    sx: {
                        background: 'white'
                    }
                }}
                onChange={handleCommentChange}
            />
            {
                isLoading ?
                    <LoadingCircle></LoadingCircle>
                    :
                    <IconButton color="default" aria-label="gesture-icon" onClick={addComment} sx={{mt:2, ml:1}}>
                        <SendIcon/> 
                    </IconButton>
            }
            
        </Box>
    );
}

export default AnswerComments;
