
import React, {Dispatch, SetStateAction} from "react";
import {UserDto} from "../services/autogen";

export interface AuthContextType {
    currentUser: UserDto | null;
    setCurrentUser: (user: UserDto) => void;
}

export interface ImageContextType {
    currentUserImage: any | null;
    setCurrentUserImage: (image: any) => void;
}

export const AuthContext = React.createContext<AuthContextType>({
    currentUser: null,
    setCurrentUser: (_) => {},
});

export const ImageContext = React.createContext<ImageContextType>({
    currentUserImage: null,
    setCurrentUserImage: (_) => {},
});


export function AuthProvider({ children }: { children: React.ReactNode }) {
    
    let [user, setUser] = React.useState<any>(null);

    let setCurrentUser = (newUser: UserDto) => {
        setUser(newUser);
    };

    let value = { currentUser: user, setCurrentUser };
    
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function AuthImageProvider({ children }: { children: React.ReactNode }) {

    let [userImage, setUserImage] = React.useState<any>(null);

    let setCurrentUserImage = (newUserImage: any) => {
        setUserImage(newUserImage);
    };

    let value = { currentUserImage: userImage, setCurrentUserImage };

    return <ImageContext.Provider value={value}>{children}</ImageContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext); 
}

export function useUserImage() {
    return React.useContext(ImageContext);
}