import * as React from 'react';
import {Box, Divider, useMediaQuery} from "@mui/material";
import Button from "@mui/material/Button";
import CreateIcon from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Typography from "@mui/material/Typography";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { PrimaryTheme, PrimaryCardTheme } from './themes';
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import {WalletDto} from "../services/autogen";
let theme = PrimaryCardTheme;

type WalletDialogProps =
    {
        children?: any,
        insertImage?: (imgSource : any, height : number, width : number) => void;
        contents?: WalletDto;
    };

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

export default function WalletDialog(props : WalletDialogProps) {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    let wallet = props.contents!;
    
    return (
        <>
            <Button variant="outlined" startIcon={<AccountBalanceWalletIcon></AccountBalanceWalletIcon>} onClick={handleClickOpen}>
                Wallet
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Wallet</DialogTitle>
                <DialogContent dividers>
                    <Box sx={{display:'flex', flexDirection:'column', /*width:'300px',*/ maxWidth: desktop ? '300px' : window.outerWidth, justifyContent:'center'}}>
                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                            <Typography component="span" sx={{display: 'flex', fontSize: '0.9rem', fontWeight:'600' }}>
                                Account Balance
                            </Typography>
                            <Button variant="contained" disabled startIcon={<CurrencyExchangeIcon/>} color="secondary">
                                Top Up
                            </Button>
                        </Box>
                        <Box sx={{backgroundColor: theme.palette.secondary.light, marginTop:'10px', paddingLeft:'40px', paddingRight:'40px', paddingTop:'20px', paddingBottom:'20px'}}>
                            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                <Typography component="span" sx={{display: 'flex', fontSize: '0.85rem', fontWeight:'600'}}>
                                    Available
                                </Typography>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <Typography component="span" sx={{display: 'flex', fontSize: '0.85rem', fontWeight:'600', marginRight:'3px'  }}>
                                        {wallet?.availableAmount || 0}
                                    </Typography>
                                    <MonetizationOnOutlinedIcon color="secondary" sx={{height:'20px', width:'20px'}}/>
                                </Box>
                            </Box>
                            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                <Typography component="span" sx={{display: 'flex', fontSize: '0.85rem', fontWeight:'600'}}>
                                    Receivable
                                </Typography>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <Typography component="span" sx={{display: 'flex', fontSize: '0.85rem', fontWeight:'600', marginRight:'3px'  }}>
                                        {wallet?.receivableAmount || 0}
                                    </Typography>
                                    <MonetizationOnOutlinedIcon color="secondary" sx={{height:'20px', width:'20px'}}/>
                                </Box>
                            </Box>
                            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                <Typography component="span" sx={{display: 'flex', fontSize: '0.85rem', fontWeight:'600'}}>
                                    Frozen
                                </Typography>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <Typography component="span" sx={{display: 'flex', fontSize: '0.85rem', fontWeight:'600', marginRight:'3px'  }}>
                                        {wallet?.frozenAmount || 0}
                                    </Typography>
                                    <MonetizationOnOutlinedIcon color="secondary" sx={{height:'20px', width:'20px'}}/>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{display:'flex', flexDirection:'column', marginTop: '30px' }}>
                            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                <Typography component="span" sx={{display: 'flex', fontSize: '0.9rem', fontWeight:'600' }}>
                                    Rewards
                                </Typography>
                                <Button variant="contained" disabled color="secondary">
                                    Redeem
                                </Button>
                            </Box>
                            <Box sx={{backgroundColor: theme.palette.secondary.light, marginTop:'10px'}}>
                                <Carousel
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    responsive={responsive}
                                    partialVisible={false}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={true}
                                    //autoPlay={false}
                                    //autoPlay={this.props.deviceType !== "mobile" ? true : false}
                                    shouldResetAutoplay={false}
                                    //autoPlaySpeed={1000}
                                    keyBoardControl={true}
                                    //customTransition="all .5"
                                    //transitionDuration={500}
                                    containerClass="carousel-container"
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    //deviceType={this.props.deviceType}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                >
                                    <Card sx={{ maxWidth: 300 , m: 1, backgroundColor: theme.palette.background.default}}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image="/images/ntuc10.jpg"
                                            alt="ntuc voucher"
                                        />
                                        <CardContent sx={{padding:1}}>
                                            <Typography gutterBottom variant="subtitle1" component="div" align='center' sx={{fontWeight:'600'}}>
                                                NTUC FairPrice voucher
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{ maxWidth: 300 , m: 1, backgroundColor: theme.palette.background.default}}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image="/images/starbucks10.jpg"
                                            alt="ntuc voucher"
                                        />
                                        <CardContent sx={{padding:1}}>
                                            <Typography gutterBottom variant="subtitle1" component="div" align='center' sx={{fontWeight:'600'}}>
                                                Starbucks voucher
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Carousel>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            
        </>

    );
}