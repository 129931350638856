import {useParams} from "react-router-dom";
import {
    Accordion,
    AccordionDetails, AccordionSummary,
    Box,
    Grid,
    Stack,
    Toolbar, useMediaQuery,
    Typography,
    Paper
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {useContext, useEffect, useRef, useState} from "react";

import { ThemedGrid } from "../components/StylizedComponents";

import AnswerCard from "../components/AnswerCard";
import {AnswerDetailDto, AnswersService, QuestionDto} from "../services/autogen";
import QuestionCard from "../components/QuestionCard";
import {PrimaryTheme} from "../components/themes";


export default function Answer() {
    let {answerId} = useParams();
    const [answer, setAnswer] = useState<AnswerDetailDto| null>(null);
    let [question, setQuestion] = useState<QuestionDto>();
    
    useEffect(()=> {
        if(answerId){
            AnswersService.getApiV1AnswersGetAnswerDetail(answerId!)
                .then(val => {
                    setAnswer(val);
                });
        }
    },[answerId]);
    
   
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    
    return (
        <Box sx={{flex:1, alignItems:'', height:window.innerHeight}}>
            <Toolbar sx={{width: "100%", display:"block"}}/>
            {desktop &&
                <Grid container
                      spacing={2}
                      sx={{paddingTop: "1rem",
                          '&.MuiGrid-root': {/*height:window.innerHeight*/ maxHeight:'100vh', height:'calc(100vh - 50px)' }
                      }}
                >
                    <ThemedGrid item xs={12} md={6} sx={{height:'100%', '&.MuiGrid-root': { height:'100%' } }}>
                        <Stack spacing={2} sx={{alignItems: 'center', }}>
                            <QuestionCard question={answer?.question}/>
                        </Stack>
                    </ThemedGrid>
                    <ThemedGrid item xs={12}
                                md={6}
                                sx={{
                                    '&.MuiGrid-root': {height:'100%'}
                                }}
                    >
                        <Stack spacing={2}>
                            <AnswerCard answerPreview={answer?.answer} isAnswerPreview={false} isAnswerPage={true}/>
                        </Stack>
                    </ThemedGrid>
                </Grid>
            }
            {mobile &&
                <Stack spacing={2}>
                    <AnswerCard answerPreview={answer?.answer} isAnswerPreview={false} isAnswerPage={true}/>
                </Stack>
            }
        </Box>
        );
}


 
