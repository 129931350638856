import React, {ChangeEvent, createRef, useCallback, useEffect, useRef} from "react";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

// @ts-ignore
import ImageEditor from '@toast-ui/react-image-editor';

//import ImageEditor from 'tui-image-editor';
import './tui-image-editor.css';
import './tui-color-picker.css';


import {Box, IconButton, useMediaQuery} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import UndoIcon from "@mui/icons-material/Undo";
import CanvasDraw from "react-canvas-draw";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {alpha} from "@mui/material/styles";
import useHookWithRefCallback from "../hooks/useHookWithRefCallback";
import {AnswersService} from "../services/autogen";

var mobileTheme = {
    "common.bi.image": "",
    "common.bisize.width": "0px",
    "common.bisize.height": "0px",
    // "common.backgroundImage": "./img/bg.png",
    "common.backgroundColor": "#fff",
    //"common.border": "1px solid #c1c1c1",

    // header
    "header.backgroundImage": "none",
    "header.backgroundColor": "transparent",
    "header.border": "0px",
    "header.display": "none",

    // load button
    "loadButton.backgroundColor": "#fff",
    "loadButton.border": "1px solid #ddd",
    "loadButton.color": "#222",
    "loadButton.fontFamily": "'Noto Sans', sans-serif",
    "loadButton.fontSize": "12px",
    "loadButton.marginLeft": "0px",

    // download button
    "downloadButton.backgroundColor": "#fdba3b",
    "downloadButton.border": "1px solid #fdba3b",
    "downloadButton.color": "#fff",
    "downloadButton.fontFamily": "'Noto Sans', sans-serif",
    "downloadButton.fontSize": "12px",

    // main icons
    "menu.normalIcon.color": "#8a8a8a",
    "menu.activeIcon.color": "#555555",
    "menu.disabledIcon.color": "#434343",
    "menu.hoverIcon.color": "#e9e9e9",
    "menu.iconSize.width": "24px",
    "menu.iconSize.height": "24px",

    // submenu icons
    "submenu.normalIcon.color": "#8a8a8a",
    "submenu.activeIcon.color": "#555555",
    "submenu.iconSize.width": "16px",
    "submenu.iconSize.height": "16px",

    // submenu primary color
    "submenu.backgroundColor": "transparent",
    "submenu.partition.color": "#e5e5e5",

    // submenu labels
    "submenu.normalLabel.color": "#858585",
    "submenu.normalLabel.fontWeight": "normal",
    "submenu.activeLabel.color": "#000",
    "submenu.activeLabel.fontWeight": "normal",

    // checkbox style
    "checkbox.border": "1px solid #ccc",
    "checkbox.backgroundColor": "#fff",

    // rango style
    "range.pointer.color": "#333",
    "range.bar.color": "#ccc",
    "range.subbar.color": "#606060",

    "range.disabledPointer.color": "#d3d3d3",
    "range.disabledBar.color": "rgba(85,85,85,0.06)",
    "range.disabledSubbar.color": "rgba(51,51,51,0.2)",

    "range.value.color": "#000",
    "range.value.fontWeight": "normal",
    "range.value.fontSize": "11px",
    "range.value.border": "0",
    "range.value.backgroundColor": "#f5f5f5",
    "range.title.color": "#000",
    "range.title.fontWeight": "lighter",

    // colorpicker style
    "colorpicker.button.border": "0px",
    "colorpicker.title.color": "#000"
};

type ImageEditorDialogProps =
    {
        children?: any,
        insertImage?: (imgSource : any) => void;
        defaultImage?: any,
    };



const imageEditorMobileOptions = {
    // sort of option properties.
    includeUI:{
        loadImage: {
            path: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
            name: 'Blank'
        },
        //theme: myTheme,
        menu: ['shape', 'filter', 'text', 'draw', 'crop', 'icon'],
        //initMenu: 'filter',
        initMenu: "text",
        theme: mobileTheme,
        uiSize: {
            width: '320px',
            height: '500px',
        },
        menuBarPosition: 'bottom',
    },
    cssMaxHeight:500,
    cssMaxWidth:350,
    selectionStyle:{
        cornerSize: 20,
        rotatingPointOffset: 70,
    },
    usageStatistics: false
};

export default function ImageEditorDialog(props : ImageEditorDialogProps) {

    const imageEditorOptions = {
        // sort of option properties.
        includeUI: {
            //theme: myTheme,
            loadImage: {
                path: props.defaultImage ?? 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
                //path: "https://qa4-cdata-app.sprinklr.com/DAM/400002/882f0bb5-4d7d-4f81-a043-bda1b3794f93-1388469934/aspectratio2.jpg",
                //path: "https://firebasestorage.googleapis.com/v0/b/peaktureit-images/o/users%2FLWlo6SJAWvUcLhfgFeui8837b7G3%2Fquestions%2FaiIdHMzJ0f3EpTqsUqHD%2Fimage0?alt=media&token=186a305d-df9e-41fd-88ee-3c97109ed1b1",
                //path: "https://res.cloudinary.com/boyang/image/upload/v1603008920/sample.jpg",
                name: 'defurl',
                crossOrigin: true
            },
            menu: ['shape', 'filter', 'text', 'draw', 'crop', 'icon'],
            initMenu: 'filter',
            uiSize: {
                width: '1000px',
                height: '700px',
            },
            menuBarPosition: 'bottom',
        },
        cssMaxHeight: 500,
        cssMaxWidth: 700,
        selectionStyle: {
            cornerSize: 20,
            rotatingPointOffset: 70,
        },
    };

    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;

    const [open, setOpen] = React.useState(false);
    const [urlImage, setUrlImage] = React.useState<any | null>();

    const editorRef = useRef<any | null>(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (props.insertImage && editorRef && editorRef.current) {
            const data = editorRef.current.imageEditorInst.toDataURL();
            props.insertImage(data);
        }
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const dataURItoBlob = (dataURI: string) => {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], {type: mimeString});
    }

    const saveImage = () => {
        if (editorRef.current) {
            const data = editorRef.current.toDataURL();
            var blob = dataURItoBlob(data);
            const file = new File([blob], `edited-` + "image", {
                type: "image/jpeg",
                lastModified: Number(new Date()),
            });
        }
    }

    const loadImage = (e: ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        let file: File | null = null;
        if (target.files) {
            file = target.files[0];
        }

        if (file) {
            if (editorRef.current) {
                const editorInstance = editorRef.current.getInstance();
                console.log(file);
                console.log(editorRef);
                editorInstance.loadImageFromFile(file, 'image').then(() => {
                    editorInstance.clearUndoStack();
                });
            }
        }
    }

    const fetchImage = async (url: string) => {

        const response = await fetch(url, {mode: 'cors'});
        console.log(response);
        const blob = await response.blob();
        return blob;

    }

    const downloadURLImage = async (url: string) => {
        const imageBlob = await fetchImage(url);
        const imageBase64 = URL.createObjectURL(imageBlob);

        console.log({imageBase64});
        setUrlImage(imageBase64);
    }

    useEffect(() => {
        //let urlPath = "https://firebasestorage.googleapis.com/v0/b/peaktureit-images/o/users%2FLWlo6SJAWvUcLhfgFeui8837b7G3%2Fquestions%2FaiIdHMzJ0f3EpTqsUqHD%2Fimage0?alt=media&token=186a305d-df9e-41fd-88ee-3c97109ed1b1";
        let urlPath = "https://res.cloudinary.com/boyang/image/upload/v1603008920/sample.jpg";
        downloadURLImage(urlPath);
    }, []);

    const [imgEditorRef, setImgEditorRef] = useHookWithRefCallback((current: any) => {
        if (current) {
            const editorInstance = current.getInstance();

            let urlPath = "https://firebasestorage.googleapis.com/v0/b/peaktureit-images/o/users%2FLWlo6SJAWvUcLhfgFeui8837b7G3%2Fquestions%2FaiIdHMzJ0f3EpTqsUqHD%2Fimage0?alt=media&token=186a305d-df9e-41fd-88ee-3c97109ed1b1";

            editorInstance.loadImageFromFile(urlImage, 'image').then(() => {
                editorInstance.addImageObject(urlImage);
            });

        }

    }, [open]);

    const imageLoaderInput = useRef<HTMLInputElement|null>(null);
    
    return (
        <>
            <IconButton color="default" aria-label="image-alt-icon" onClick={handleClickOpen}>
                <AddPhotoAlternateIcon/>
            </IconButton>
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        '&.MuiDialog-paper': {
                            maxWidth: window.innerWidth,
                            maxHeight: '100%',
                            margin: desktop ? '32px' : '5px'
                        }
                    }
                }}
            >
                <DialogTitle>Edit Image</DialogTitle>

                {desktop &&
                <DialogContent>
                    <ImageEditor
                        ref={editorRef}
                        {...imageEditorOptions}
                    />
                </DialogContent>
                }
                {mobile &&
                <DialogContent sx={{padding: '0px'}}>
                    <ImageEditor
                        ref={editorRef}
                        {...imageEditorMobileOptions}
                    />
                </DialogContent>
                }


                <DialogActions>
                    {mobile &&
                    <>
                        <input id="styled-tui-image-loader" type="file"
                               hidden={true} 
                               accept="image/*"
                               onChange={loadImage}
                               ref={imageLoaderInput}
                        />
                        <Button variant="contained" color="secondary"
                                onClick={()=> imageLoaderInput.current?.click()}
                        >
                            Load
                        </Button>
                    </>
                    }
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={handleClose}>
                        Insert
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}