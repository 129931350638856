import {
    Box,
    Card,
    Chip,
    Link, useMediaQuery,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import UsernameAvatar from "../components/avatarChips";
import Typography from "@mui/material/Typography";
import React from "react";
import CardActions from "@mui/material/CardActions";
import { ThemeProvider, createTheme,  } from '@mui/material/styles';
import { Rating } from '@mui/material';


import { PrimaryTheme } from './themes';
import {AnswerReviewDto} from "../services/autogen";
import TimeHelper from "../helpers/TimeHelper";

type ReviewCardProps = {
    children?: React.ReactNode;
    theme?: React.ReactNode;
    rating?: number;
    review?: AnswerReviewDto;
}

const labels: { [index: string]: string } = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export default function ReviewCard( props: ReviewCardProps ) {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    const [value, setValue] = React.useState<number | null>(props.rating ? props.rating : 3);
    const [hover, setHover] = React.useState(-1);

    let review = props.review;
    
    return (
        <ThemeProvider theme={props.theme ? props.theme : PrimaryTheme}>
            <Card sx={{borderRadius: 3, flexShrink: 0, zIndex: 10, marginBottom: '10px' }}>
                <CardContent sx={{display: 'flex' , paddingBottom:0}}>
                    <Box sx={{flexShrink: 0}}>
                        <UsernameAvatar name={review?.publisher?.userName} color="primary" imgSrc={review?.publisher?.profilePicture || ""}/>
                    </Box>

                    <Typography component="span" sx={{display: 'flex', fontSize: '0.9rem' , marginLeft:'10px', alignItems: 'center'}}>
                        on {TimeHelper.getFriendlyTime(review?.createdAt)}
                    </Typography>
                    
                </CardContent>
                <CardContent sx={{padding: 1}}>
                    <Link href="/questions/1"
                          underline="none"
                          variant="body2"
                          sx={{fontSize: desktop ? "inherit" : "0.8rem"}}>
                        {review?.review}
                    </Link>
                </CardContent>
                <CardActions disableSpacing sx={{display: 'flex'}}>
                    {review &&
                        <Rating
                            name="rating-display"
                            value={review?.rating } //{value}
                            readOnly
                            precision={0.5}
                            getLabelText={getLabelText}
                            onChangeActive={(event, newHover) => {
                                setHover(newHover);
                            }}
                        />
                    }
                    {value !== null && (
                        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : review?.rating || 0 ]}</Box>
                    )}
                </CardActions>

            </Card>
        </ThemeProvider>
    );
}