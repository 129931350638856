/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GlobalSearchDto } from '../models/GlobalSearchDto';
import type { RelatedQuestionDto } from '../models/RelatedQuestionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SearchService {

    /**
     * @param key
     * @param from
     * @returns RelatedQuestionDto Success
     * @throws ApiError
     */
    public static getApiV1SearchGetRelatedQuestions(
        key?: string,
        from?: number,
    ): CancelablePromise<RelatedQuestionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Search/GetRelatedQuestions',
            query: {
                'key': key,
                'from': from,
            },
        });
    }

    /**
     * @param key
     * @param from
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1SearchGlobalSearch(
        key?: string,
        from?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Search/GlobalSearch',
            query: {
                'key': key,
                'from': from,
            },
        });
    }

    /**
     * @param key
     * @returns GlobalSearchDto Success
     * @throws ApiError
     */
    public static getApiV1SearchAutoComplete(
        key?: string,
    ): CancelablePromise<GlobalSearchDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Search/AutoComplete',
            query: {
                'key': key,
            },
        });
    }

    /**
     * @param key
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1SearchAutoCompleteGroupName(
        key?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Search/AutoCompleteGroupName',
            query: {
                'key': key,
            },
        });
    }

    /**
     * @param key
     * @param from
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1SearchSearchGroupsByName(
        key?: string,
        from?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Search/SearchGroupsByName',
            query: {
                'key': key,
                'from': from,
            },
        });
    }

}