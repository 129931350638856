import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {Box, Button, Divider, Grid, Stack, Tab, Tabs, Typography, useMediaQuery} from "@mui/material";

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {PrimaryAvatarTheme, PrimaryCardTheme, PrimaryTheme} from './themes';
import MessageIcon from "@mui/icons-material/Message";
import {TransactionDto, TransactionListDto} from "../services/autogen";
import TimeHelper from "../helpers/TimeHelper";

function createData(
    action: string,
    amount: number,
    counterparty: string,
    status: string,
    timestamp: string,
) {
    return { action, 
             amount, 
             counterparty, 
             status, 
             timestamp,
             transactionLink: [
                 {
                     sender: 'Answerer',
                     subject: 'Question no 888'
                 }
             ],
    };
}

const rows = [
    createData('Receive', 2, 'WilliamSoh', 'Completed', 'Mar 22, 2022'),
    createData('Pay', 10, 'PaulSmith', 'Completed', 'Mar 22, 2022'),
    createData('Receive', 6, 'Glenn Yong', 'Completed', 'Mar 22, 2022'),
    createData('Receive', 5, 'Chong Kit', 'Completed', 'Mar 21, 2022'),
    createData('Receive', 8, 'TheBull', 'Completed', 'Mar 20, 2022'),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

//function Row(props: { row: ReturnType<typeof createData> }) {
function Row(props: { row: TransactionDto }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <StyledTableRow
                key={row.id /*row.action*/}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    {row.isSeller ? 'Receive' : 'Pay' /*row.action*/}
                </StyledTableCell>
                <StyledTableCell align="center">{row.totalAmount /*row.amount*/}</StyledTableCell>
                <StyledTableCell align="center">{row.counterparty?.userName /*row.counterparty*/}</StyledTableCell>
                <StyledTableCell align="center">{row.status /*row.status*/}</StyledTableCell>
                <StyledTableCell align="center">{TimeHelper.getFriendlyTime(row.createdAt) /*row.timestamp*/}</StyledTableCell>
            </StyledTableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center' }}>
                            <Typography variant='caption' sx={{display:'flex', justifyContent: 'center', marginLeft:'80px'}}>Payment for the transaction </Typography>
                            <Button variant="contained" color="info">
                                View
                            </Button>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const renderDesktopUserTable = (props: TransactionsTableProps) => (
    <>
        <TableHead>
            <StyledTableRow>
                <StyledTableCell/>
                <StyledTableCell>Action</StyledTableCell>
                <StyledTableCell align="center">Amount</StyledTableCell>
                <StyledTableCell align="center">Counterparty</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Timestamp</StyledTableCell>
            </StyledTableRow>
        </TableHead>
        <TableBody>
            {/*{rows.map((row) => (
                <Row key={row.action} row={row} />
            ))}*/}
            {props?.items?.map((row) => (
                <Row key={row.id} row={row} />
            ))}
        </TableBody>
    </>
);

const StyledMobileTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
        padding: '2px',
        fontSize: '0.65rem',
    },
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '0.65rem',
    },
}));

const StyledMobileTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function RowMobile(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <StyledMobileTableRow
                key={row.action}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <StyledMobileTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledMobileTableCell>
                <StyledMobileTableCell component="th" scope="row">
                    {row.action}
                </StyledMobileTableCell>
                <StyledMobileTableCell align="center">{row.amount}</StyledMobileTableCell>
                <StyledMobileTableCell align="center">{row.counterparty}</StyledMobileTableCell>
                <StyledMobileTableCell align="center">{row.status}</StyledMobileTableCell>
                <StyledMobileTableCell align="center">{row.timestamp}</StyledMobileTableCell>
            </StyledMobileTableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center' }}>
                            <Typography sx={{display:'flex', justifyContent: 'center', marginLeft:'20px', fontSize:'0.7rem'}}>Payment for the transaction </Typography>
                            <Button variant="contained" 
                                    color="info"
                                    sx={{
                                        '&.MuiButtonBase-root': {
                                            '&.MuiButton-root': {
                                                borderRadius: '10px',
                                                width: '50px',
                                                minWidth: '50px',
                                                fontSize: '0.7rem'
                                            }
                                        }
                                    }}
                            >
                                View
                            </Button>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const renderMobileUserTable = (
    <>
        <TableHead>
            <StyledMobileTableRow>
                <StyledMobileTableCell/>
                <StyledMobileTableCell>Action</StyledMobileTableCell>
                <StyledMobileTableCell align="center">Amount</StyledMobileTableCell>
                <StyledMobileTableCell align="center">Counterparty</StyledMobileTableCell>
                <StyledMobileTableCell align="center">Status</StyledMobileTableCell>
                <StyledMobileTableCell align="center">Timestamp</StyledMobileTableCell>
            </StyledMobileTableRow>
        </TableHead>
        <TableBody>
            {rows.map((row) => (
                <RowMobile key={row.action} row={row} />
            ))}
        </TableBody>

    </>
);

interface TransactionsTableProps {
    items?: Array<TransactionDto>;
}

export default function TransactionsTable(props: TransactionsTableProps) {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: desktop ? 650 : 200, maxWidth: window.outerWidth }} aria-label="simple table">
                {desktop && renderDesktopUserTable(props)}
                {mobile && renderMobileUserTable}
            </Table>
        </TableContainer>
    );
}
