import {Box, Button, Chip, Divider, Grid, Stack, Tab, Tabs, Typography, useMediaQuery} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import * as React from "react";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import MessageIcon from '@mui/icons-material/Message';
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { BarChart, Bar, XAxis, YAxis, Tooltip} from 'recharts';
import {FC, PureComponent, useContext} from 'react';

import QuestionCard from "../components/QuestionCard";
import AnswerCard from "../components/AnswerCard";
import ReviewCard from "../components/ReviewCard";
import TransactionsTable from "../components/TransactionsTable";

import EditUserProfileDialog from "../components/EditUserProfileDialog";
import AccountSettingsDialog from "../components/AccountSettingsDialog";
import WalletDialog from "../components/WalletDialog";

import { useParams } from 'react-router';

import QuizIcon from '@mui/icons-material/Quiz';
import ReviewsIcon from '@mui/icons-material/Reviews';
import FaceIcon from '@mui/icons-material/Face';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssistantIcon from '@mui/icons-material/Assistant';
import {useEffect, useState} from "react";
import {AuthContext, ImageContext, useAuth} from "../contexts/AuthContext";
import {
    AnswerReviewDto, AnswersService,
    OpenAPI, PaymentsService,
    ProfileDto, PublicProfileDto, QuestionDto, QuestionPreviewDto,
    QuestionsService, RatingStatsDto, SettingDto, TransactionDto,
    UsersService, WalletDto
} from "../services/autogen";
import QuestionListItem from "../components/questionListItem";
import {last} from "lodash";
import { VariableSizeList, FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {Waypoint} from "react-waypoint";
import {ViewDay} from "@mui/icons-material";

import { ColorRing } from  'react-loader-spinner';
import { Audio } from 'react-loader-spinner';
import { Oval } from "react-loader-spinner";
import ProfilePictureDialog from "../components/ProfilePictureDialog";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 2}}>
                    <Box sx={{display:'flex', justifyContent: 'center'}}>{children}</Box>
                </Box>
            )}
        </div>
    );
}


export default function UserProfile() {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;

    //const { currentUser, setCurrentUser } = useContext(AuthContext);
    const {currentUser} = useAuth();

    const { currentUserImage, setCurrentUserImage } = useContext(ImageContext);

    const { userId } = useParams();
    
    const [profile, setProfile] = useState<ProfileDto | undefined>();
    const [activityList, setActivityList] = useState<Array<QuestionDto>>([]);
    const [publicProfile, setPublicProfile] = useState< PublicProfileDto | undefined>();
    const [ratingStats, setRatingStats] = useState<RatingStatsDto | undefined>();
    const [reviewList, setReviewList] = useState<Array<AnswerReviewDto>>([]);

    let [txnList, setTxnList] = useState<Array<TransactionDto>>([]);
    let [hasMoreTxn, setHasMoreTxn] = useState<boolean>(true);
    let [lastTxnId, setLastTxnId] = useState<string | null | undefined>(undefined);

    const [wallet, setWallet] = useState<WalletDto | undefined>();
    const [userSetting, setUserSetting] = useState<SettingDto | undefined>();

    const [myOwnProfile, setMyOwnProfile] = useState<boolean>(false);

    const [save, setSave] = useState<boolean>(false);

    const [viewedActivity, setViewedActivity] = useState<Array<QuestionDto>>([]);
    const [viewThreshold, setViewThreshold] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);

    const [isContentLoading, setIsContentLoading] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    
    const [currentUserId, setCurrentUSerId] = useState<string | undefined>();
    const [overrideProfilePicture, setOverrideProfilePicture] = React.useState(null);

    const setNewActivityList = (list : Array<QuestionPreviewDto>) => {
        setActivityList(list);
    }

    const loadMoreActivity = (activity : Array<QuestionDto> ) => {

        
        setIsLoading(true);
        
        if (activity) {
            
            let addThreshold = 10;
            let availableView = viewThreshold;
            let newActivityList = viewedActivity;

            if (userId != currentUserId) { // if new user, reset everything
                newActivityList = [];
                availableView = 0
            }
            
            if (activity.length < availableView + addThreshold) {
                addThreshold = activity.length - availableView;
            }
            
            for (let i = 0; i < addThreshold; i++) {
                newActivityList.push(activity[availableView + i]);
            }
            
            // set new activity list
            setViewedActivity(newActivityList);
            setViewThreshold(availableView + addThreshold);

            setIsLoading(false);
            console.log("set new list:", availableView + addThreshold);
            console.log(" out of:", activity.length);
        }

        console.log("WAYPOINT REACHED");

    }
    
    useEffect(()=> {
        async function initProfile() : Promise<void> {
            setIsProfileLoading(true);
            setMyOwnProfile(false);
            
            let profileResponse = await UsersService.getApiV1UsersGetProfile();
            if (profileResponse) {
                let profile = profileResponse.profile;
                setProfile(profile);
                
                if (userId != currentUserId) {
                    setActivityList([]);
                    setViewedActivity([]);
                }
                
                setIsContentLoading(true);
                
                let reviewsPromise =  AnswersService.getApiV1AnswersGetReviewsForProfile(userId || profile?.userId || "")
                let activitiesPromise = QuestionsService.getApiV1QuestionsGetActivitiesForProfile(userId || profile?.userId || "");
                let publicProfilesPromise = UsersService.getApiV1UsersGetPublicProfile(userId || profile?.userId || "")
                
                //await Promise.all([reviewsPromise, activitiesPromise, publicProfilesPromise]);
                
                let reviews = await reviewsPromise;
                let activities = await activitiesPromise;
                let publicProfiles = await publicProfilesPromise;
                
                if (activities) {
                    setActivityList(activities);
                    loadMoreActivity(activities);
                }

                if (reviews) {
                    setReviewList(reviews);
                }
                
                if (publicProfiles) {
                    setPublicProfile(publicProfiles.profile);
                }
                
                setCurrentUSerId(userId);

                setIsContentLoading(false);

                setIsProfileLoading(false);
            }
        }

        initProfile();
        
        if (currentUser) {
            if (currentUser.id == userId) {
                setMyOwnProfile(true);
            }
        }

        console.log(viewedActivity);


    },[userId, currentUser]);

    useEffect(()=> {
        async function initProfile(): Promise<void> {
            if (currentUser) {
                let new_profile = await UsersService.getApiV1UsersGetProfile();
                setProfile(new_profile.profile);
                
                if (myOwnProfile) {
                    setPublicProfile({user: currentUser, profile: new_profile.profile});
                }
            }
        }

        if (save) {
            initProfile();
            setSave(false);
        }

    },[save]);

    useEffect(()=> {
        UsersService.getApiV1UsersGetRatingStats()
            .then(val => {
                if (val) {
                    setRatingStats(val.stats);
                }
            });

    },[]);

    useEffect(()=> {
        UsersService.getApiV1UsersGetSetting()
            .then(val => {
                if (val) {
                    setUserSetting(val);
                }
            });

    },[]);

    useEffect(()=> {
        PaymentsService.getApiV1PaymentsGetWalletForUser()
            .then(val => {
                if (val) {
                    setWallet(val);
                }
            });

    },[]);

    useEffect(()=> {
        if(hasMoreTxn){
            PaymentsService.getApiV1PaymentsGetTransactionsForUser(lastTxnId || undefined)
                .then(val => {
                    setTxnList(txnList.concat(val.data!));
                    setHasMoreTxn(val.hasMore || false);
                    // @ts-ignore
                    let lastId = last(val.data!)?.id.toString();
                    //setLastTxnId(last(val.data!)?.id);
                    setLastTxnId(lastId);
                });
        }
    },[]); // TODO invoke this when we scroll the answer list to the bottom
    
    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    }

    let statsData = [
        {
            name: '# Stars Received',
            number: ratingStats ? ratingStats.totalStarsReceived : 0,
        },
        {
            name: '# Upvotes Received',
            number: ratingStats ? ratingStats.totalUpVotesReceived : 0,
        },
        {
            name: '# Questions answered',
            number: ratingStats ? ratingStats.questionAnswered : 0,
        },
        {
            name: '# Payments received',
            number: ratingStats ? ratingStats.paymentsReceived : 0,
        },
        {
            name: '# Tenders completed',
            number: ratingStats ? ratingStats.completedTender : 0,
        }
    ];
    

    const ActivityRow = ({ index, style } : any) => {
        console.log("rendering index:", index);
        return(
        <QuestionListItem key={activityList[index].id} item={activityList[index]} qnList={activityList} setQnList={setNewActivityList}/>
        )
    };
    
    const rowHeights = (index : number) => {
        return(400)
    };

    const getItemSize = (index : number) => rowHeights(index);
    
    
    
    // @ts-ignore
    const renderDesktopUserProfile =(
        <>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example" centered>
                <Tab label="Activities"/>
                {/*<Tab label="Answers"/>*/}
                {/*<Tab label="Posts"/>*/}
                <Tab label="Profile"/>
                <Tab label="Review"/>
                {/*<Tab label="Groups"/>*/}
                {myOwnProfile && <Tab label="Transactions"/>}
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
                <Box sx={{ width: '80%', display: 'flex', flexDirection: 'column', overflowY: 'scroll', maxHeight: 'calc(100vh - 300px)'}}>
                    {/*
                    <VariableSizeList itemSize={getItemSize} height={600} itemCount={activityList.length} width={"100%"}>
                    {
                        //activityList?.map(qli => <QuestionListItem key={qli.id} item={qli}/>)
                        ActivityRows

                    }
                    </VariableSizeList>
                    */}
                    
                    {!isContentLoading &&
                        viewedActivity?.slice(0,viewedActivity.length-1).map(qli => <QuestionListItem key={qli.id} item={qli} qnList={activityList} setQnList={setNewActivityList}/>)
                    }
                    <Waypoint onEnter={() => loadMoreActivity(activityList)} />
                    {
                        viewedActivity?.slice(viewedActivity.length-1,viewedActivity.length).map(qli => <QuestionListItem key={qli.id} item={qli} qnList={activityList} setQnList={setNewActivityList}/>)
                    }
                    {/*
                        !isLoading && <Waypoint onEnter={() => loadMoreActivity(activityList)} />*/
                    }
                </Box>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                {publicProfile &&
                    <Stack spacing={1}>
                        <Typography variant="h6">Credentials</Typography>
                        <Divider></Divider>
                        <Box id="credbox" sx={{display:'flex', flexDirection: 'column', alignItems:'center'}}>
                            <Box id="inside-credbox" sx={{}}>
                                <Stack direction="row" spacing={3}>
                                    <WorkIcon></WorkIcon><Typography variant="body2">{publicProfile?.profile?.occupation}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={3}>
                                    <SchoolIcon></SchoolIcon><Typography variant="body2">{publicProfile?.profile?.education}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={3}>
                                    <LocationOnIcon></LocationOnIcon><Typography variant="body2">{publicProfile?.profile?.location}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <AssistantIcon></AssistantIcon>{publicProfile?.profile?.expertise?.map((skill) => <Chip label={skill}/>)}
                                </Stack>
                                <Typography variant="body2" sx={{marginTop:'10px', marginBottom:'10px'}}>
                                    {publicProfile?.profile?.description}
                                </Typography>
                            </Box>
                        </Box>
                        <Typography variant="h6">Stats</Typography>
                        <Divider></Divider>
                        <BarChart layout="vertical"
                                  width={800}
                                  height={230}
                                  data={statsData}
                                  barCategoryGap={5}
                                  margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                  }}
                        >
                            <XAxis type="number"/>
                            <YAxis width={200} type="category" dataKey="name"/>
                            <Tooltip/>
                            <Bar layout="vertical" dataKey="number" fill="#8884d8"/>
                        </BarChart>
                    </Stack>
                }
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                <Box sx={{ width: '80%', display: 'flex', flexDirection: 'column', }}>
                    {
                        reviewList?.map(qli => <ReviewCard key={qli.id} review={qli}/>)
                    }
                </Box>
            </TabPanel>
            {myOwnProfile &&
                <TabPanel value={selectedTab} index={3}>
                    <Box sx={{width: '80%', display: 'flex', flexDirection: 'column',}}>
                        <TransactionsTable items={txnList}/>
                    </Box>
                </TabPanel>
            }
        </>
    );

    const renderMobileUserProfile =(
        <>
            <Box sx={{maxWidth: window.innerWidth}}>
            <Tabs value={selectedTab} 
                  onChange={handleTabChange} 
                  aria-label="basic tabs example"
                  //variant="scrollable"
                  //scrollButtons="auto"
                  centered>
                <Tab icon={<QuizIcon />} label="Activities" sx={{fontSize:'0.65rem', minWidth:'60px', padding:'2px'}}/>
                {/*<Tab icon={<QuestionAnswerIcon />} label="Answers" sx={{fontSize:'0.65rem', minWidth:'60px', padding:'2px'}}/>*/}
                {/*<Tab label="Posts"/>*/}
                <Tab icon={<FaceIcon />} label="Profile" sx={{fontSize:'0.65rem', minWidth:'60px', padding:'2px'}}/>
                <Tab icon={<ReviewsIcon />} label="Review" sx={{fontSize:'0.65rem', minWidth:'60px', padding:'2px'}}/>
                {/*<Tab label="Groups"/>*/}
                <Tab icon={<ReceiptIcon />} label="Transactions" sx={{fontSize:'0.65rem', minWidth:'60px', padding:'2px'}}/>
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                    <QuestionCard/>
                    <AnswerCard/>
                </Box>
            </TabPanel>
            <TabPanel value={selectedTab} index={1} >
                {publicProfile &&
                    <Box sx={{maxWidth: window.outerWidth * 0.9}}>
                        <Stack spacing={1} sx={{maxWidth: window.outerWidth * 0.9}}>
                            <Typography variant="h6">Credentials</Typography>
                            <Divider></Divider>
                            <Stack direction="row" spacing={1}>
                                <WorkIcon></WorkIcon><Typography variant="body2">{publicProfile?.profile?.occupation}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <SchoolIcon></SchoolIcon><Typography variant="body2">{publicProfile?.profile?.education}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <LocationOnIcon></LocationOnIcon><Typography variant="body2">{publicProfile?.profile?.location}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <AssistantIcon></AssistantIcon>{publicProfile?.profile?.expertise?.map((skill) => <Chip label={skill}/>)}
                            </Stack>
                            <Typography variant="body2">
                                {publicProfile?.profile?.description}
                            </Typography>
                            <Typography variant="h6">Stats</Typography>
                            <Divider></Divider>
                            <BarChart layout="vertical"
                                      width={window.outerWidth * 0.9}
                                      height={230}
                                      data={statsData}
                                      barCategoryGap={5}
                                      margin={{
                                          top: 5,
                                          right: 30,
                                          left: 20,
                                          bottom: 5,
                                      }}
                            >
                                <XAxis type="number" tick={{fontSize: '0.8rem'}}/>
                                <YAxis width={100} type="category" dataKey="name" tick={{fontSize: '0.7rem'}}/>
                                <Tooltip/>
                                <Bar layout="vertical" dataKey="number" fill="#8884d8"/>
                            </BarChart>
                        </Stack>
                    </Box>
                }
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', }}>
                    <ReviewCard rating={4}/>
                    <ReviewCard rating={5}/>
                    <ReviewCard rating={2}/>
                    <ReviewCard/>
                    <ReviewCard/>
                </Box>
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', }}>
                    <TransactionsTable/>
                </Box>
            </TabPanel>
            </Box>
        </>
    );

    

    useEffect(()=> {
        getProfilePicture();
    },[overrideProfilePicture]); // TODO invoke this when we scroll the answer list to the bottom
    
    const setNewProfilePicture = (img : any) => {
        setOverrideProfilePicture(img);
    }
    
    const getProfilePicture = () => {
        
        if (overrideProfilePicture) {
            setCurrentUserImage(overrideProfilePicture);
            return overrideProfilePicture;
        }
        
        let srcImg = "null";
        if (currentUser) {
            if (currentUser.id == userId) {
                srcImg = currentUser.profilePicture || "null";
            }
            else {
                if (publicProfile) {
                    srcImg = publicProfile?.user?.profilePicture || "null";
                }
            }
        }
        
        return srcImg;
    }

    const getProfileName = () => {
        let name = "anonymous";
        if (currentUser) {
            if (currentUser.id == userId) {
                name = profile?.realName || "anonymous";
            }
            else {
                if (publicProfile) {
                    name = publicProfile?.user?.userName || "anonymous";
                }
            }
        }

        return name;
    }
    
    return (
        
        <Box sx={{width: "100%", height: 'calc(100vh - 10px)'}}>
            { isProfileLoading &&
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '100px'}}>
                    <Oval
                        height={35}
                        width={35}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
        
                    />
                </Box>

            }
            <Toolbar/>
            <Grid container spacing={2} sx={{paddingLeft: 5, paddingRight: 5, paddingTop: 2,}}>
                <Grid item xs={2}/>
                <Grid item xs={ (desktop && myOwnProfile) ? 4 : 12}>
                    <Stack direction="row" spacing={desktop ? 2 : 0} sx={{justifyContent: 'center'}}>
                        <Box>
                            <Avatar alt="profile-image" src={getProfilePicture()} sx={{width: 100, height: 100}}/>
                            {myOwnProfile &&
                                <IconButton sx={{position: "relative", left: 70, top: -25}}>
                                    <ProfilePictureDialog image={getProfilePicture()} userid={userId} setImage={setNewProfilePicture}/>
                                </IconButton>
                            }

                        </Box>
                        {mobile &&
                        <Stack sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100px',
                                justifyContent: 'center'
                            }}>
                                <Typography variant="h5"
                                            sx={{fontSize: desktop ? '1.5rem' : '1rem'}}>{getProfileName()}</Typography>
                                <Typography variant="h6"
                                            sx={{fontSize: desktop ? '1.25rem' : '0.8rem'}}>Rating: {publicProfile && publicProfile?.profile?.rating ? publicProfile?.profile?.rating.answererRating : '3000'}</Typography>
                                <Typography variant="subtitle1"
                                            sx={{fontSize: desktop ? '1rem' : '0.7rem'}}>[{publicProfile && publicProfile?.profile?.rating ? publicProfile?.profile?.rating.answererGrade : 'Regular'}]</Typography>
                            </Box>
                        </Stack>
                        }
                        {desktop &&
                        <Stack>
                            <Typography variant="h5"
                                        sx={{fontSize: desktop ? '1.5rem' : '1rem'}}>{getProfileName()}</Typography>
                            <Typography variant="h6"
                                        sx={{fontSize: desktop ? '1.25rem' : '0.8rem'}}>Rating: {publicProfile && publicProfile?.profile?.rating ? publicProfile?.profile?.rating.answererRating : '3000'}</Typography>
                            <Typography variant="subtitle1"
                                        sx={{fontSize: desktop ? '1rem' : '0.7rem'}}>[{publicProfile && publicProfile?.profile?.rating ? publicProfile?.profile?.rating.answererGrade : 'Regular'}]</Typography>
                        </Stack>
                        }
                    </Stack>

                    {mobile &&
                    <Stack spacing={0.5}>
                        <EditUserProfileDialog/>
                        <AccountSettingsDialog/>
                        <WalletDialog contents={wallet}/>
                    </Stack>
                    }

                </Grid>
                {/*desktop &&
                <Grid item xs={2} sx={{display: "flex", justifyContent: "flex-end", flexDirection: "column"}}>
                    <Button sx={{marginBottom: "22px"}} variant="contained" startIcon={<MessageIcon></MessageIcon>}>
                        Message
                    </Button>
                </Grid>
                */
                }
                {desktop &&
                <Grid item xs={2}>
                    <Stack spacing={0.5}>
                        {myOwnProfile &&
                        <EditUserProfileDialog profile={profile!} currentUser={currentUser!} saveFunc={setSave}/>}
                        {myOwnProfile && <AccountSettingsDialog settings={userSetting!} currentUser={currentUser!}/>}
                        {myOwnProfile && <WalletDialog contents={wallet}/>}
                    </Stack>
                </Grid>
                }
                <Grid item xs={2}/>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={desktop ? 1 : 0}/>
                <Grid item xs={desktop ? 10 : 12}>
                    {desktop && renderDesktopUserProfile}
                    {mobile && renderMobileUserProfile}
                </Grid>
                <Grid item xs={desktop ? 1 : 0}/>
            </Grid>

        </Box>
        
    );
        
}
