import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {Link} from "react-router-dom";
import {UserDto} from "../services/autogen";

type AvatarChipsProps =
    {
        imgSrc?: string | undefined;
        name?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
        color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
        onClick?: () => void;
        userDto?: UserDto | null;
        colorOverride?: string | undefined;
        fontOverride?: string | undefined;
        userId?: string | null | undefined;
    };


export default function AvatarChips(props: AvatarChipsProps) {
    let userDto = props.userDto;
    let uri = userDto?.profilePicture ?? userDto?.facebookUserPicture ?? userDto?.googleUserPicture ?? props.imgSrc;
    let userUrl = "/user/" + props.userId || "1";
    
    return (
        <Stack direction="row" spacing={1} sx={{width: "100%", alignItems: "center", justifyContent: "start"}}>
            <Link to={userUrl} style={{ textDecoration: 'none' }}>
                <Chip
                    avatar={<Avatar alt="Natacha" src={uri}/>}
                    label={userDto?.userName ?? props.name}
                    //label={""}
                    size="medium"
                    color={props.color}
                    clickable={true}
                    onClick={ props.onClick }
                    sx={{
                        '& .MuiChip-label': {
                            color: props.colorOverride ? props.colorOverride : "inherit",
                        },
                        "& .MuiChip-root": {
                            borderRadius: 10
                        }
                    }}
                />
            </Link>
            
        </Stack>
    );
}