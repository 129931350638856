import axios from "axios";
import {API_GET_TOP_QUESTIONS}from "./ApiUrls";

export type Question = {
    id: number,
    title: string,
    content: string,
    publisher: string
}

let questions: Question[] = [
    {
        id: 1,
        title: "Question 1",
        content: "This is question number 1",
        publisher: "Aditya"
    },
    {
        id: 2,
        title: "Question 2",
        content: "This is another question. Number 2",
        publisher: "Aditya"
    }

];

export function getQuestions() : Promise<Question[]> {
    let token = localStorage.getItem("token");
    axios.get(API_GET_TOP_QUESTIONS, {
        headers: {'Authorization': `Bearer ${token}`}
    })
    return Promise.resolve(questions);
}

