import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Grid, Paper, Stack,
    useMediaQuery
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";

import { PrimaryTheme } from '../components/themes';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ThemedGrid} from "../components/StylizedComponents";

import '../components/tagstyle.css';

import QuestionCard from "../components/QuestionCard";
import PostAnswerPage from "./PostAnswerPage";
import {useParams} from "react-router-dom";
import {AnswerDetailDto, AnswersService, OpenAPI, QuestionDto, QuestionsService} from "../services/autogen";

let theme = PrimaryTheme;


export function PostAnswer() {
    let {questionId, answerId} = useParams();
    let [question, setQuestion] = useState<QuestionDto>();
    let [answer, setAnswer] = useState<AnswerDetailDto>();

    useEffect(()=> {
        if(questionId){
            QuestionsService.getApiV1Questions(questionId!)
                .then(val => {
                    setQuestion(val);
                });
        }
        if (answerId){
            AnswersService.getApiV1AnswersGetAnswerDetail(answerId)
                .then(val => {
                     setAnswer(val);
                });
        }
    },[]);
    
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    return (

        <Box sx={{flex:1, alignItems:''}}>
            <Toolbar sx={{width: "100%", display:"block"}}/>
            {desktop &&
                <Grid container spacing={2} sx={{paddingTop: "1rem", height:'calc(100vh - 50px)'}}>
                    <ThemedGrid item xs={12} md={6} sx={{height:'100%', '&.MuiGrid-root': { height:'100%' } }}>
                        <Stack spacing={2}>
                            <QuestionCard question={question}/>
                            {/*
                            <Accordion sx={{background: PrimaryTheme.palette.background.default}} defaultExpanded={true}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography>Similar Questions</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack spacing={2}>
                                        <Paper sx={{padding: "0.3rem"}}>
                                            <Typography>
                                                A 20.0-gram hanging mass (m2) is attached to a 250.0-gram air track glider
                                                (m1). Determine the acceleration of the system and the tension in the string
                                            </Typography>
                                        </Paper>
                                        <Paper sx={{padding: "0.3rem"}}>
                                            <Typography>
                                                A 200.0-gram mass (m1) is attached to a 50.0-gram mass (m2) are connected by
                                                a string. The string is stretched over a pulley. Determine the acceleration
                                                of the system and the tension in the string
                                            </Typography>
                                        </Paper>
                                    </Stack>
    
                                </AccordionDetails>
                            </Accordion>
                            */}
                        </Stack>
                    </ThemedGrid>
                    <ThemedGrid item xs={12} md={6} sx={{height: '100%', '&.MuiGrid-root': { height:'100%' } }}>
                        <Stack spacing={2}>
                            <PostAnswerPage question={question!} answer={answer}/>
                        </Stack>
                    </ThemedGrid>
                </Grid>
            }
            {mobile &&
                <Stack spacing={2}>
                    <QuestionCard question={question}/>
                    <PostAnswerPage question={question!}/>
                </Stack>
            }
        </Box>

    );
}