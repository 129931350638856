import * as React from 'react';
import Card from '@mui/material/Card';
import {ThemeProvider, createTheme, styled, alpha} from '@mui/material/styles';

import UsernameAvatar from './avatarChips';
import {Grid, ToggleButtonGroup} from "@mui/material";
import {PrimaryAvatarTheme, PrimaryCardTheme, PrimaryTheme} from './themes';

type ThemeChipsProps =
    {
        imgSrc: string | undefined;
        name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
        color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
        colorOverride?: string | undefined;
        fontOverride?: string | undefined;
        userId?: string | null | undefined;
    };

export default function ThemedAvatarChips(props: ThemeChipsProps) {
    return (
        <ThemeProvider theme={PrimaryAvatarTheme}>
            <UsernameAvatar name={props.name} 
                            color={props.color} 
                            imgSrc={props.imgSrc}
                            colorOverride={props.colorOverride}
                            userId={props.userId}
            />
        </ThemeProvider>
    );
}

type ThemeCardProps =
    {
        children?: any,
        color?: "primary" | "secondary" | any | null | undefined,
        sx?: any,
    };

export function ThemedCard(props: ThemeCardProps) {
    return (
        <ThemeProvider theme={PrimaryCardTheme}>
            <Card sx={props.sx ? [props.sx, {backgroundColor: props.color == "primary" ? PrimaryCardTheme.palette.primary.main : PrimaryCardTheme.palette.secondary.main}]: 
                                 {paddingTop: 5, borderRadius: 3, marginTop: -5, backgroundColor: props.color == "primary" ? PrimaryCardTheme.palette.primary.main : PrimaryCardTheme.palette.secondary.main, }} >
                {props.children}
            </Card>
        </ThemeProvider>
    );
}

type ThemeGridProps =
    {
        children?: any,
        color?: "primary" | "secondary" | any | null | undefined,
        sx?: any | null | undefined,
        item?: any,
        xs?: any,
        md?: any,
    };

export function ThemedGrid(props: ThemeGridProps) {
    return (
        <ThemeProvider theme={PrimaryTheme}>
            <Grid item xs={props.xs ? props.xs : 12} md={props.md ? props.md : 6}
                  sx={[props.sx,
                      {overflowY: 'scroll', overflowX: 'hidden',
                      height: '50vw',
                      "&::-webkit-scrollbar": {
                          width: 15,
                      },
                      "&::-webkit-scrollbar-track": {
                          boxShadow: `inset 0 0 1px rgba(0, 0, 0, 0.8)`,
                      },
                      "&::-webkit-scrollbar-thumb": {
                          backgroundColor: PrimaryTheme.palette.background.paper
                      },
                  }]}>
                {props.children}
            </Grid>
        </ThemeProvider>
    );
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(1),
        border: "1px solid #0000001f",
        '&.Mui-disabled': {
            border: "1px solid #0000001f",
        },
        '&:not(:first-of-type)': {
            border: "1px solid #0000001f",
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            border: "1px solid #0000001f",
            borderRadius: theme.shape.borderRadius,
        },
        '&.Mui-selected':{
            background: `${alpha(theme.palette.primary.main, 0.3)}`
        }
    },
}));

type ThemeToggleButtonGroupProps =
    {
        children?: any,
        color?: "primary" | "secondary" | any | null | undefined,
        id?: string | any | null | undefined,
        value?: any,
        onChange?: ((event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void) | undefined,
        size?: "small" | "medium" | any,
        exclusive ?: boolean
    };

export function ThemedStyledToggleButtonGroup(props: ThemeToggleButtonGroupProps) {
    return (
        <ThemeProvider theme={PrimaryTheme} >
            <StyledToggleButtonGroup id={props.id}
                                     color={props.color}
                                     value={props.value}
                                     onChange={props.onChange}
                                     size={props.size}
                                     exclusive={props.exclusive}
                                     sx={{justifyContent: 'center'}}
            >
                {props.children}
            </StyledToggleButtonGroup>
        </ThemeProvider>
    );
}

