import {
    Box,
    Card,
    Chip, IconButton,
    useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ThemeProvider, createTheme,} from '@mui/material/styles';
import {Link, Navigate} from "react-router-dom";

import {PrimaryTheme} from './themes';
import {useNavigate} from "react-router-dom";
import GestureIcon from "@mui/icons-material/Gesture";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import UndoIcon from "@mui/icons-material/Undo";
import CanvasDraw from "react-canvas-draw";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';
import 'react-image-picker-editor/dist/index.css';
import {OpenAPI, QuestionDto, UsersService} from "../services/autogen";
import {request as __request, sendRequest} from "../services/autogen/core/request";

type ProfilePictureDialogProps =
    {
        children?: any,
        insertImage?: (imgSource : any, height : number, width : number) => void,
        image?: any,
        userid?: string,
        setImage?: any,
    };

export default function ProfilePictureDialog(props : ProfilePictureDialogProps) {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;

    const [open, setOpen] = React.useState(false);
    const canvasRef = useRef<any | null>(null);
    const [imageUri, setImageUri] = React.useState<string>("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleSave() {
        // send image uri to the backend server
        //let formdata = new FormData();

        let formdata:Record<string,any> = {};
        //formdata.append("userId", props.userid || "");
        formdata["userId"] = props.userid;
        
        
        console.log(imageUri);
        let imageAsBlob = await (await fetch(imageUri)).blob();
       
        if (imageUri) {
            //formdata.append('file', imgdata as unknown as Blob);
            formdata["file"] = imageAsBlob;
        }
        
        try {

            let response: { profilePicture: any };

            response = await __request(
                
                OpenAPI, {
                    method: 'POST',
                    url: '/api/v1/Users/UploadProfilePicture',
                    formData: formdata
                //mediaType: 'multipart/form-data',
                }
            );

            console.log(response);
            props.setImage(response.profilePicture);
            
        } catch(e){

            console.log(e);
        }
        
        //UsersService.postApiV1UsersUploadProfilePicture(formdata);
         
        setOpen(false);
    };

    const config2: ImagePickerConf = {
        borderRadius: '8px',
        language: 'en',
        width: '330px',
        height: '250px',
        objectFit: 'contain',
        compressInitial: null,
    };
    // const initialImage: string = '/assets/images/8ptAya.webp';
    const initialImage = props.image;

    return (
        <>
            <IconButton color="default" aria-label="gesture-icon" onClick={handleClickOpen}>
                <PhotoCameraIcon/>
            </IconButton>
            <Dialog open={open} onClose={handleClose} fullWidth PaperProps={{sx:{width: desktop? '580px' : window.innerWidth*0.9, margin: desktop ? '32px' : '5px'} }} >
                <DialogTitle>Profile Picture</DialogTitle>
                <DialogContent sx={{padding:'5px'}}>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent: 'center'}} >
                        <ReactImagePickerEditor
                            config={config2}
                            imageSrcProp={initialImage}
                            imageChanged={(newDataUri: any) => { console.log(newDataUri); setImageUri(newDataUri) }} 
                        />


                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={handleSave}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}