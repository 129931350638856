/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePrivateQuestionRequest } from '../models/CreatePrivateQuestionRequest';
import type { ExtendTenderRequest } from '../models/ExtendTenderRequest';
import type { MarkQuestionAsReadRequestDto } from '../models/MarkQuestionAsReadRequestDto';
import type { MoveToPastRequest } from '../models/MoveToPastRequest';
import type { QuestionDto } from '../models/QuestionDto';
import type { QuestionListDto } from '../models/QuestionListDto';
import type { QuestionVoteRequest } from '../models/QuestionVoteRequest';
import type { RemoveQuestionAttachment } from '../models/RemoveQuestionAttachment';
import type { StringStringValuesKeyValuePair } from '../models/StringStringValuesKeyValuePair';
import type { UpdateViewCountRequest } from '../models/UpdateViewCountRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class QuestionsService {

    /**
     * @param formData
     * @returns QuestionDto Success
     * @throws ApiError
     */
    public static postApiV1Questions(
        formData?: {
            form?: Array<StringStringValuesKeyValuePair>;
        },
    ): CancelablePromise<QuestionDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1QuestionsUploadQuestionPicture(
        formData?: {
            form?: Array<StringStringValuesKeyValuePair>;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/UploadQuestionPicture',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns QuestionDto Success
     * @throws ApiError
     */
    public static postApiV1QuestionsEditQuestion(
        formData?: {
            form?: Array<StringStringValuesKeyValuePair>;
        },
    ): CancelablePromise<QuestionDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/EditQuestion',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1QuestionsUpdatePrivateQuestion(
        requestBody?: CreatePrivateQuestionRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/UpdatePrivateQuestion',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1QuestionsCreatePrivateQuestion(
        requestBody?: CreatePrivateQuestionRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/CreatePrivateQuestion',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1QuestionsRemoveAttachment(
        requestBody?: RemoveQuestionAttachment,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/RemoveAttachment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param formData
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1QuestionsUploadAttachment(
        formData?: {
            form?: Array<StringStringValuesKeyValuePair>;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/UploadAttachment',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1QuestionsExtendTender(
        requestBody?: ExtendTenderRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/ExtendTender',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1QuestionsMoveToPast(
        requestBody?: MoveToPastRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/MoveToPast',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1QuestionsUpdateViewCount(
        requestBody?: UpdateViewCountRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/UpdateViewCount',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param lastId
     * @param status
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1QuestionsGetMyQuestions(
        lastId?: string,
        status?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Questions/GetMyQuestions',
            query: {
                'lastId': lastId,
                'status': status,
            },
        });
    }

    /**
     * @param userId
     * @returns QuestionDto Success
     * @throws ApiError
     */
    public static getApiV1QuestionsGetActivitiesForProfile(
        userId?: string,
    ): CancelablePromise<Array<QuestionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Questions/GetActivitiesForProfile',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @param groupId
     * @param lastTime
     * @param count
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1QuestionsGetQuestionsInGroup(
        groupId?: string,
        lastTime?: string,
        count: number = 30,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Questions/GetQuestionsInGroup',
            query: {
                'groupId': groupId,
                'lastTime': lastTime,
                'count': count,
            },
        });
    }

    /**
     * @param answerId
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1QuestionsGetQuestionByAnswerId(
        answerId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Questions/GetQuestionByAnswerId',
            query: {
                'answerId': answerId,
            },
        });
    }

    /**
     * @param lastTime
     * @param topicId
     * @param metadataFilter
     * @param lastVoteCount
     * @param count
     * @param webOnly
     * @returns QuestionListDto Success
     * @throws ApiError
     */
    public static getApiV1QuestionsGetQuestions(
        lastTime?: string,
        topicId?: string,
        metadataFilter?: string,
        lastVoteCount: number = 2147483647,
        count: number = 30,
        webOnly: boolean = false,
    ): CancelablePromise<QuestionListDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Questions/GetQuestions',
            query: {
                'lastTime': lastTime,
                'topicId': topicId,
                'metadataFilter': metadataFilter,
                'lastVoteCount': lastVoteCount,
                'count': count,
                'webOnly': webOnly,
            },
        });
    }

    /**
     * @param topicId
     * @param webOnly
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1QuestionsGetLastQuestionActivityTime(
        topicId?: string,
        webOnly: boolean = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Questions/GetLastQuestionActivityTime',
            query: {
                'topicId': topicId,
                'webOnly': webOnly,
            },
        });
    }

    /**
     * @param questionId
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1QuestionsGetInvitedUsers(
        questionId?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Questions/GetInvitedUsers',
            query: {
                'questionId': questionId,
            },
        });
    }

    /**
     * @param topQuestionTime
     * @param topicId
     * @param webOnly
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1QuestionsCheckQuestionUpdates(
        topQuestionTime?: string,
        topicId?: string,
        webOnly: boolean = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Questions/CheckQuestionUpdates',
            query: {
                'topQuestionTime': topQuestionTime,
                'topicId': topicId,
                'webOnly': webOnly,
            },
        });
    }

    /**
     * @param id
     * @returns QuestionDto Success
     * @throws ApiError
     */
    public static getApiV1Questions(
        id: string,
    ): CancelablePromise<QuestionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Questions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiV1Questions(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Questions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1QuestionsMarkQuestionAsRead(
        requestBody?: MarkQuestionAsReadRequestDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/MarkQuestionAsRead',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1QuestionsUpVoteQuestion(
        requestBody?: QuestionVoteRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/UpVoteQuestion',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1QuestionsRemoveUpVoteQuestion(
        requestBody?: QuestionVoteRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Questions/RemoveUpVoteQuestion',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}