import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Container, Divider, Link, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';

import {PrimaryTheme} from '../components/themes';
import {useLocation, useNavigate} from "react-router-dom";
import {AuthContext} from "../contexts/AuthContext";
import FacebookLogin, {FacebookLoginClient, LoginStatus} from '@greatsumini/react-facebook-login';
import {UserDto, UsersService} from '../services/autogen';
import GoogleLogin from "react-google-login";

let theme = PrimaryTheme;

interface LocationState {
    from: {
        pathname: string;
    };
}

export function Login() {
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    let [fbToken, setFbToken] = useState<string|undefined>("");
    let [email, setEmail] = useState<string>("");
    let [password, setPassword] = useState<string>("");
    let navigate = useNavigate();
    let location = useLocation();
    let locationState = location.state as LocationState;
    let from = locationState?.from?.pathname || "/";

    useEffect(() => {
        FacebookLoginClient.loadSdk('en_US', false).then(() =>{
            FacebookLoginClient.init({version: 'v9.0',
                xfbml: false,
                cookie: false,
                localStorage: true,
                appId:'330039171226113' }); // TODO replace for BETA and Production    
        });
        
    }, []);
    
    
    const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
        };
    
    const onPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    function postLoginAction(userDto: UserDto) {
        console.log(`User DTO: ${userDto}`);
        if (userDto.token) {
            localStorage.setItem("token", userDto.token);
            setCurrentUser(userDto);
            navigate(from, {replace: true});
        }
    }

    async function onLoginButtonClicked(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        let userDto = await UsersService.postApiV1UsersLogin({
            email,
            password
        });
        postLoginAction(userDto);
    }
    
    function invokeFacebookLogin() {
        FacebookLoginClient.login(loginRes => {
            if(loginRes.status === LoginStatus.Connected){
                FacebookLoginClient.getProfile(async (response: any) => {
                    let userDto = await UsersService.postApiV1UsersLoginWithFacebook({
                        email: email,
                        id: response.id,
                        name: response.name,
                        picture: response.picture?.data?.url,
                        token: loginRes.authResponse?.accessToken
                    })
                    postLoginAction(userDto);
                }, {
                    fields: "name,email,picture"
                });
            }
        }, {
            scope: 'public_profile, email',
        });
    }

    async function onGoogleLoginSuccessful(res: any) {
        let profileObj = res.profileObj;
        let userDto = await UsersService.postApiV1UsersLoginWithGoogle({
            email: profileObj.email,
            id: profileObj.googleId,
            idToken: res.tokenObj.id_token
        })
        postLoginAction(userDto);
    }

    return (
        <>
            <Box sx={{ display: "flex", backgroundColor: theme.palette.background.paper, minHeight: '1000px', height:'100vw', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: "flex", width:'75vw', maxWidth:'750px', minWidth: '500px', backgroundColor: theme.palette.primary.main, height:'100%', justifyContent: 'center',  }}>
                    <Box sx={{ display: "flex", flexDirection: 'column', width:'75%', maxWidth:'600px',backgroundColor: "white", minWidth: '400px', height:'60%', alignItems: 'center', marginTop: '50px' }}>

                        <Box sx={{ display: 'flex', marginTop: '30px'
                        }}>
                            <Link href="/" underline="none">
                                <Container sx={{display:"inline", width: "auto", margin: "unset", paddingRight: 1}} disableGutters={true}>
                                    <img src="/images/logo.jpg" alt="Logo" width="50" height="50" />
                                </Container>
                                <Typography
                                    variant="h2"
                                    noWrap
                                    component="span"
                                    sx={{ display: { xs: 'none', sm: 'inline', fontSize:'25' }, color: "#008526" }}
                                >
                                    Peak
                                </Typography>
                                <Typography
                                    variant="h2"
                                    noWrap
                                    component="span"
                                    sx={{ display: { xs: 'none', sm: 'inline',  }, color: "#D21A1A" }}
                                >
                                    Qitt
                                </Typography>
                            </Link>
                        </Box>
                        <Typography variant="caption" component="div" sx={{ fontWeight: '600', fontSize: '0.9rem', marginTop: '3px', marginBottom: '10px', color: theme.palette.primary.main }}>
                            Connect the society through knowledge
                        </Typography>
                        
                        <Box sx={{ width:'80%' }}>
                            <TextField fullWidth id="email-field" label="Email Address" variant="outlined" margin="normal" value={email} onChange={onEmailChanged} />
                            <TextField fullWidth type="password" id="password-field" label="Password" variant="outlined" autoComplete="current-password" margin="normal" 
                                       value={password} onChange={onPasswordChanged}/>
                        </Box>
                        <Box sx={{ width:'80%', display: 'flex', justifyContent: 'space-between' }}>
                            <Link href="#" underline="always">
                                {'Reset Password'}
                            </Link>
                            <Link href="/">
                                <Button variant="contained" color="success" onClick={onLoginButtonClicked}>
                                    Log in
                                </Button>
                            </Link>
                            
                        </Box>
                        <Divider sx={{width:'100%', margin:'20px', }} />
                        <Box sx={{ width:'80%' }}>
                            <GoogleLogin
                                clientId="43273504302-7v85ukbic173fkheq0irlfstt0f97q0f.apps.googleusercontent.com"
                                buttonText="Login"
                                onSuccess={onGoogleLoginSuccessful}
                                //onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                                render={rProps => (
                                    <Button 
                                        disabled={rProps.disabled}
                                        fullWidth variant="outlined" color="primary" startIcon={<GoogleIcon />} 
                                        onClick={rProps.onClick} >
                                        Login with Google
                                    </Button>        
                                    )}
                            />
                            
                            <Button fullWidth variant="outlined" color="primary" startIcon={<FacebookIcon />} sx={{ marginTop: '20px'}} onClick={invokeFacebookLogin}>
                                Login with Facebook
                            </Button>
                            <Link href="/signup">
                                <Button fullWidth variant="contained" color="info" sx={{ marginTop: '20px'}}>
                                    Sign Up
                                </Button>
                            </Link>
                            <Typography variant="subtitle1" component="div" sx={{ fontSize: '0.75rem', marginTop: '30px' }}>
                                By continuing you indicate that you agree to PeakQitt’s Terms of Service and Privacy Policy. You can view our Terms & Conditions&nbsp;
                                <Link href="#" underline="always" color="secondary">
                                    {'here'}
                                </Link>
                            </Typography>
                        </Box>
                        
                    </Box>
                </Box>
            </Box>
        </>
    );
}
