import {useCallback, useRef} from "react";

// https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
// https://gist.github.com/thebuilder/fb07c989093d4a82811625de361884e7#file-hookwithref-js
/**
 * Use this hook if you need to invoke a callback upon ref being set
 */
export default function useHookWithRefCallback(callback: Function, deps: Array<any>) {
    const ref = useRef(null)
    const setRef = useCallback(node => {
        if (ref.current) {
            // Make sure to cleanup any events/references added to the last instance
        }

        if (node) {
            // Check if a node is actually passed. Otherwise node would be null.
            // You can now do what you need to, addEventListeners, measure, etc.
        }

        // Save a reference to the node
        ref.current = node
        
        if(callback){
            callback(ref.current);
        }
    }, deps)

    return [ref, setRef]
}