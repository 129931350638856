import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import { styled, alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from '@mui/icons-material/Home';
import Avatar from '@mui/material/Avatar';

import MoreIcon from '@mui/icons-material/MoreVert';
import { MathJaxContext } from "better-react-mathjax";
import jwt_decode, {JwtPayload} from "jwt-decode";



import {
    Button,
    Container, Snackbar,
    //Link,
    Tab,
    Tabs, useMediaQuery
} from "@mui/material";

import {Link} from "react-router-dom";

import { PrimaryTheme } from './components/themes';

import AvatarChips from './components/avatarChips';
import {Outlet} from "react-router-dom";
import {useAuth, useUserImage} from "./contexts/AuthContext";
import NotificationItem from "./components/NotificationItem";
import {AlertDto, AlertListDto, AlertsService, OpenAPI, UsersService} from "./services/autogen";

import FlatList from 'flatlist-react';
import {Waypoint} from "react-waypoint";
import SearchBar from "./components/SearchBar";
import {useNavigate} from "react-router-dom";
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";


let theme = PrimaryTheme;

const Search = styled('div')(({ theme }) => ({
    flex: 1,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.4),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.35),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));


function App() {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;

    const navigate = useNavigate();

    const {currentUserImage} = useUserImage();
    
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const openEl = Boolean(anchorEl);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<Element | null>(null);

    const [anchorNotify, setAnchorNotify] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorNotify);

    let [searchIconClick, setSearchIconClick] = useState<boolean>(false);

    const token = useMemo(() => {
        let token = localStorage.getItem("token");
        if (token) {
            console.log("Token initialized ");
            OpenAPI.TOKEN = token;
        }
        return token;
    }, []);

    useEffect(() => {
        if (!token) {
            navigate('/Login');
        } else {
            let decoded = jwt_decode(token) as JwtPayload;
            if (new Date((decoded.exp ?? 0) * 1000) < new Date() ){
                navigate('/Login');
            }
        }
    }, []);
    
    
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorNotify(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorNotify(null);
    };

    const handleSearchIconClick = (event: React.MouseEvent<HTMLElement>) => {
       setSearchIconClick(!searchIconClick);
    };
    

    const handleNotificationClick = (alert : AlertDto) => {
        readNotification(alert);
        setAnchorNotify(null);
    };
    
    const readNotification = async (alert: AlertDto) => {
        // mark that is already ready
        if (!alert.isRead) {
            await AlertsService.postApiV1AlertsReadAlert({alertId: alert.id?.toString() || ""});
        }

        // Navigate to that question or answer
        if (alert.answerId) {
            navigate(`/answer/${alert.answerId}`);
        } else if (alert.questionId) {
            navigate(`/questions/${alert.questionId}/${alert?.question?.slugUrl || 'display'}`);
        }
    };

    const [isLogInButtonVisible, setIsLogInButtonVisible] = useState<boolean>(true);
    const {currentUser, setCurrentUser} = useAuth();

    const [alertList, setAlertList] = useState<AlertListDto| null>(null);
    const [hasMoreAlert, setHasMoreAlert] = useState<boolean | undefined>(false);
    const [lastAlertId, setLastAlertId] = useState<string | undefined>();
    const [alertNo, setAlertNo] = useState<number | undefined>(0);
    const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
    const [notificationMessage, setNotificationMessage] = useState<string>("");
    
    
    const signalrConn = useMemo(()=>{
        let token = localStorage.getItem("token");
        if(token){
            const connection = new HubConnectionBuilder()
                .withUrl("/notification", {
                    accessTokenFactory: ()=> (localStorage.getItem("token") || "")
                })
                .configureLogging(LogLevel.Information)
                .build();
            
            connection.on("NotifyAlert", (alertDto:AlertDto) => {
                console.log(alertDto);
                setAlertNo((alertNo || 0 ) +1);
                setNotificationOpen(true);
                setNotificationMessage(alertDto.content || "You received new notification");
            });
            return connection;
        }
    },[]);

    async function startSignalr() {
        try {
            if(signalrConn) {
                await signalrConn.start();
                console.log("SignalR Connected.");
            }

        } catch (err) {
            console.log(err);
            setTimeout(startSignalr, 5000);
        }
    }



    useEffect(()=> {
        if (!currentUser) {
            UsersService.getApiV1UsersGetCurrentUser().then(u => {
                setCurrentUser(u);
                setIsLogInButtonVisible(false);
                return startSignalr().then(()=>{
                    return signalrConn?.send("SubscribeNotificationForUser", u.id);
                })
            });
        }
        
        if(currentUser){
            setIsLogInButtonVisible(false); 
        }
    },[]);

    useEffect(()=> {
        let token = localStorage.getItem("token");
        if(token){
            OpenAPI.TOKEN = token;
        }
        if(currentUser){
            AlertsService.getApiV1Alerts(false)
                .then(val => {
                    setAlertList(val);
                    setHasMoreAlert(val?.hasMore);
                    
                    //setLastAlertId(val?.data?[val.data.length-1] || "");
                    if (val?.data && val?.data.length > 0) {
                        console.log(val.data[val.data.length - 1]);
                        setLastAlertId(val.data[val.data.length-1].id || "");
                    }
                });

            AlertsService.getApiV1AlertsGetNoAlertsSinceLastVisit()
                .then(val => {
                    setAlertNo(val.alertNo);
                    
                    console.log("get number of alert since last visit: ",val.alertNo)
                    
                });
        }
    },[currentUser]);
    
    const getMoreAlert = () => {
        let token = localStorage.getItem("token");
        if(token){
            OpenAPI.TOKEN = token;
        }
        if(currentUser){
            AlertsService.getApiV1Alerts(false, lastAlertId)
                .then(val => {
                    let oldArr : Array<AlertDto> | undefined | null = alertList?.data;
                    let newArr : Array<AlertDto> | undefined | null = val.data;
                    
                    let combArr = alertList?.data;
                    if (oldArr && newArr) {
                        combArr = oldArr.concat(newArr);
                    }
                    
                    let newAlertList : AlertListDto = {data: combArr, hasMore: val?.hasMore}
                    
                    setAlertList(newAlertList);
                    
                    setHasMoreAlert(val?.hasMore);
                    console.log("hasMore alert:",val?.hasMore);
                    
                    if (val?.data && val?.data?.length > 0) {
                        const lastAlertId = val.data[val.data.length - 1].id;
                        console.log("last alert id:", lastAlertId);
                        setLastAlertId(lastAlertId || "");
                    }
                });
        }
    }
    
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLAnchorElement> 
        | React.MouseEvent<HTMLLIElement> 
        | React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    
    const handleLogout = () => {
        handleMenuClose();

        localStorage.removeItem("token");
    }

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    
    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    
    /*
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem key={1} onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem key={2} onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    ); */

    function handleLogOut() {
        localStorage.removeItem("token");
        OpenAPI.TOKEN = undefined;
        navigate('/Login');
        handleMenuClose();
    }
    
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openEl}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
            <MenuItem key={1}>
                <Link to={"/user/" + currentUser?.id || "1"} style={{ color: 'inherit', textDecoration: 'inherit' }}>Profile</Link>
            </MenuItem>
            <MenuItem key={2}>
                <Link to={"/login"} onClick={handleLogOut} style={{ color: 'inherit', textDecoration: 'inherit' }}>Logout</Link>
            </MenuItem>
        </Menu>
    );

    const renderMenuRight = (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openEl}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
            <MenuItem key={1} onClick={handleLogOut}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem key={1}>
                <IconButton size="large" aria-label="show 4 new mails">
                    <Badge badgeContent={4} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem key={2}>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                >
                    <Badge badgeContent={alertNo} color="error">
                        <NotificationsIcon color="primary"/>
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem key={3} onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 , paddingTop: desktop ? '24px' : '10px', maxHeight: '500px', overflowY: 'scroll', overflowX: 'hidden'}}> 
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,

        };
    }
    
    let notificationList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

    /*
    const notificationItems = alertList?.data?.map((row) => {
            return(
                <MenuItem key={4} onClick={handleClose}>
                    <NotificationItem data={row}/>
                </MenuItem>);
        }
    );*/

    
    const notificationItemsPre = alertList?.data?.slice(0,alertList.data.length-1).map((row) => {
            return(
                <MenuItem key={4} onClick={() => handleNotificationClick(row)}>
                    <NotificationItem data={row}/>
                </MenuItem>);
        }
    );

    const notificationItemsPost = alertList?.data?.slice(alertList.data.length-1,alertList.data.length).map((row) => {
            return(
                <MenuItem key={4} onClick={() => handleNotificationClick(row)}>
                    {hasMoreAlert && alertList?.data &&  alertList?.data?.length > 10 &&
                    <Waypoint onEnter={() => getMoreAlert()} />
                    }
                    <NotificationItem data={row}/>
                </MenuItem>);
        }
    );
    
    
    const renderNotification = (row : any) => {
        return (
            <MenuItem key={5} onClick={() => handleNotificationClick(row)}>
                <NotificationItem data={row}/>
            </MenuItem>
        );
    }
    
            
    let notificationList2 = [0, 1, 2, 3, 4, 5]
    const notificationItems2 = notificationList2.map((i) => {
            return(
                <MenuItem key={5} onClick={handleClose}>
                    <NotificationItem/>
                </MenuItem>);
        }
    );

    const renderHotButtons = (
        <>
            <Box sx={{ flexGrow: 0.2, display: "flex", justifyContent: "flex-end", alignItems:'center'}}>
                <Link to="/postquestion" style={{ textDecoration: 'none' }} /*sx={{fontSize: "inherit"}}*/>
                    <Button variant="contained" color="secondary" >
                        Ask Question
                    </Button>
                </Link>
            </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex', height: "100%" }, alignItems:'center' }}>
            {/*}
                              <IconButton size="large" aria-label="show 4 new mails">
                                  <Badge badgeContent={4} color="error">
                                      <MailIcon color="primary"/>
                                  </Badge>
                              </IconButton>*/}
            <Link to="/" style={{ textDecoration: 'none' }} /*underline="none" sx={{display:'flex', alignItems:'center'}} */>
                <IconButton size="large"
                            sx={{
                                '&.MuiButtonBase-root': {
                                    '&.MuiIconButton-root': {
                                        '&.MuiIconButton-sizeLarge': {
                                            paddingRight: 0
                                        }
                                    }
                                }
                            }}
    
                >
                    <HomeIcon color="primary"/>
                </IconButton>
            </Link>
            <IconButton
                size="large"
                aria-label="show 17 new notifications"
                onClick={handleClick}
                //sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                
                <Badge badgeContent={alertNo} color="error">
                    <NotificationsIcon color="primary"/>
                </Badge>
                
            </IconButton>
            <Menu
                anchorEl={anchorNotify}
                id="account-menu"
                open={open}
                onClose={handleClose}
                //onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        padding:0,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                sx={{
                    '& .MuiMenuItem-root': {
                        padding:0
                    }}}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
    
                <Box sx={{ width: '100%', maxWidth: '500px'}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display:'flex', justifyContent:'center', flexDirection:'column' }}>
                        <Typography sx={{ display:'flex', justifyContent:'center',  fontSize:'1.2rem', fontWeight:'600'  , color: theme.palette.primary.main }}>Notifications
                        </Typography>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="All" {...a11yProps(0)} />
                            {/*<Tab label="Unread" {...a11yProps(1)} /> TODO */}
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0} >
                        {notificationItemsPre}
                        {notificationItemsPost}
                        {/*
                        <FlatList
                            list={alertList?.data}
                            renderItem={renderNotification}
                            renderWhenEmpty={() => <div>List is empty!</div>}
                            hasMoreItems={hasMoreAlert}
                            loadMoreItems={getMoreAlert}
                            paginate={{
                                hasMore: hasMoreAlert,
                                loadMoreItems: getMoreAlert
                            }}
                        />*/}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {notificationItems2}
                    </TabPanel>
                </Box>
    
            </Menu>
            <Box sx={{ display: 'flex',
                //width: "216px",
                height: "100%",
                backgroundColor: theme.palette.background.paper
            }}>
                {isLogInButtonVisible && <Button variant="contained" href="/Login" sx={{alignSelf: "center", justifySelf: "center", marginLeft:"1rem"}}>Log In</Button>}
                {/*!isLogInButtonVisible && <AvatarChips userDto={currentUser} color="primary" userId={currentUser?.id} /> */}
                {!isLogInButtonVisible &&
                    <IconButton
                        onClick={handleClickMenu}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }} src={currentUserImage ? currentUserImage : (currentUser?.profilePicture ? currentUser.profilePicture : "")}/>
                    </IconButton>
                }
            </Box>
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
            >
                <MoreIcon />
            </IconButton>
        </Box>
        </>
    );

    const renderMobileHotButtons = (
        <>
            <Box sx={{ flexGrow: 0.2, display: "flex", justifyContent: "flex-end"}}>
                <Link to="/postquestion" style={{ textDecoration: 'none' }} /*sx={{fontSize: "inherit"}}*/>
                    <Button variant="contained" 
                            color="secondary" 
                            //size="small"
                            sx={{
                                '&.MuiButtonBase-root': {
                                    '&.MuiButton-root': {
                                        borderRadius: '30px',
                                        width: '30px',
                                        minWidth: '30px',
                                        fontSize: '0.6rem'
                                    }
                                }
                            }}
                    >
                        Ask
                    </Button>
                </Link>
            </Box>
            <Box sx={{ display:'flex'  }}>
                <IconButton
                    size="small"
                    aria-label="show 17 new notifications"
                    onClick={handleSearchIconClick}
                    //sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <SearchIcon color="primary"/>
                </IconButton>
                
                <Link to="/" style={{ textDecoration: 'none' }} /*underline="none" sx={{display:'flex', alignItems:'center'}} */>
                    <IconButton size="small"
                                sx={{
                                    '&.MuiButtonBase-root': {
                                        '&.MuiIconButton-root': {
                                            '&.MuiIconButton-sizeLarge': {
                                                paddingRight: 0
                                            }
                                        }
                                    }
                                }}

                    >
                        <HomeIcon color="primary"/>
                    </IconButton>
                </Link>
                
                <IconButton
                    size="small"
                    aria-label="show 17 new notifications"
                    onClick={handleClick}
                    //sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    
                    <Badge badgeContent={alertNo} color="error">
                        <NotificationsIcon color="primary"/>
                    </Badge>
                    
                </IconButton>

                <Menu
                    anchorEl={anchorNotify}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    //onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            padding:0,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    sx={{
                        '& .MuiMenuItem-root': {
                            padding:0
                        }}}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >

                    <Box sx={{ width: '100%', }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', display:'flex', justifyContent:'center', flexDirection:'column' }}>
                            <Typography sx={{ display:'flex', justifyContent:'center',  fontSize:'0.85rem', fontWeight:'600'  , color: theme.palette.primary.main }}>Notifications
                            </Typography>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label={<Typography sx={{fontSize: '0.8rem'}}>All</Typography>} {...a11yProps(0)} />
                                {/*<Tab label={<Typography sx={{fontSize: '0.8rem'}}>Unread</Typography>} {...a11yProps(1)} /> TODO */}
                            </Tabs>
                        </Box>
                        <TabPanel value={tabValue} index={0}>
                            {/*notificationItems*/}
                            <FlatList
                                list={alertList?.data}
                                renderItem={renderNotification}
                                renderWhenEmpty={() => <div>List is empty!</div>}
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            {notificationItems2}
                        </TabPanel>
                    </Box>

                </Menu>
                
                <Box sx={{ display: 'flex',
                    width: "100px",
                    //height: "100%",
                    backgroundColor: theme.palette.background.paper,
                    alignItems: 'center'
                }}>
                    {isLogInButtonVisible && <Button variant="contained" size="small" href="/Login" sx={{alignSelf: "center", justifySelf: "center", marginLeft:"1rem", }}>Log In</Button>}
                    {/*!isLogInButtonVisible && <AvatarChips userDto={currentUser} color="primary" /> */}
                    {!isLogInButtonVisible &&
                    <IconButton
                        onClick={handleClickMenu}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }} src={currentUserImage ? currentUserImage : (currentUser?.profilePicture ? currentUser.profilePicture : "")}/>
                    </IconButton>
                    }
                </Box>
                {/*
                <IconButton
                    size="medium"
                    edge="start"
                    aria-label="open drawer"
                    //sx={{mr: 2}}
                >
                    <MenuIcon color="primary"/>
                </IconButton>*/}
            </Box>
        </>
    );
    
  let mjConfig = {
      "fast-preview": {
          disabled: true
      },
      tex2jax: {
          inlineMath: [
              ["$", "$"],
              ["\\(", "\\)"]
          ],
          displayMath: [
              ["$$", "$$"],
              ["\\[", "\\]"]
          ]
      },
      messageStyle: "none",
      "HTML-CSS": {
          linebreaks: { automatic: true, width: "container" }
      },
      jax: ["input/MathML", "input/TeX", "input/LaTeX","input/AsciiMath", "output/SVG"],
      SVG: {
          useGlobalCache: false,
          useFontCache: false
      },
      extensions: ["content-mathml.js", "tex2jax.js", "mml2jax.js", "toMathML.js"],
      TeX: {
          extensions: ["AMSmath.js","AMSsymbols.js","noErrors.js","noUndefined.js"]
      }
  };

    
  return (
      <MathJaxContext version={2}
                      src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=default"
                      config={mjConfig} onStartup={(mathJax) => {
          mathJax.Hub.processSectionDelay = 0;
          mathJax.Hub.Config({
              extensions: ["toMathML.js"]
          });
      }}>
          <Box sx={{ flexGrow: 1, display: "flex", backgroundColor: theme.palette.background.paper, maxWidth: window.outerWidth,  /*height: window.outerHeight, maxHeight: "100vh" */ }}>
              <CssBaseline />
              <AppBar position="fixed"
                      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1,
                          backgroundColor: theme.palette.background.paper,
                          '&.MuiPaper-root': {
                              '&.MuiAppBar-root': {left: '0px', right: 'auto', maxWidth: window.outerWidth}
                          }
                      }}>
                  <Container maxWidth= {false}
                             sx={{ position: 'relative',
                                 id: "header-container",
                                 display:'flex',
                                 paddingLeft: 0,
                                 paddingRight: 0,
                                 maxWidth: window.innerWidth,

                             }}>
                      <Toolbar sx={{ width: "100%", alignItems:"center", height: mobile ? '30px' : 'inherit'}} disableGutters={true} >
                          {!searchIconClick &&
                              <Box sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  alignItems: 'center'
                              }}>
                                  <Box sx={{
                                      display: 'flex',
                                      //width: desktop ? "216px" : "120px",
                                      maxWidth: window.innerWidth,
                                      height: "100%",
                                      alignItems: 'center',
                                      marginLeft: '8px',
                                      backgroundColor: theme.palette.background.paper
                                  }}>
                                      {/* desktop &&
                                  <IconButton
                                      size="large"
                                      edge="start"
                                      aria-label="open drawer"
                                      sx={{mr: 2}}
                                      onClick={handleClickMenu}
                                  >
                                      <MenuIcon color="primary"/>
                                  </IconButton>
                                  */
                                      }
                                      {/*desktop && renderMenu */}
                                      {/*desktop && renderMenu */}
                                      <Link to="/" style={{textDecoration: 'none'}} /*underline="none"*/>
                                          {desktop &&
                                          <Container
                                              sx={{display: "inline", width: "auto", margin: "unset", paddingRight: 1}}
                                              disableGutters={true}>
                                              <img src="/images/logo.jpg" alt="Logo" width="30" height="30"/>
                                          </Container>
                                          }
                                          <Typography
                                              variant="h5"
                                              noWrap
                                              component="span"
                                              sx={{
                                                  display: { /*xs: 'none', sm: 'inline',*/
                                                      fontSize: mobile ? '20px' : '25px'
                                                  }, color: "#008526" /*"#EA8044"*/
                                              }}
                                          >
                                              Peak
                                          </Typography>
                                          <Typography
                                              variant="h5"
                                              noWrap
                                              component="span"
                                              sx={{display: {fontSize: mobile ? '20px' : '25px'}, color: "#D21A1A"}}
                                          >
                                              Qitt
                                          </Typography>
                                      </Link>

                                  </Box>
                                  {desktop &&
                                  <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center',}}>
                                      <SearchBar/>
                                  </Box>

                                  }
                                  <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                      {mobile && renderMobileHotButtons}
                                      {desktop && renderHotButtons}
                                  </Box>
                              </Box>
                          }
                          {searchIconClick &&
                              <Box sx={{display:'flex', flexDirection:'row'}}>
                                  <IconButton
                                      size="small"
                                      aria-label="show 17 new notifications"
                                      onClick={handleSearchIconClick}
                                      //sx={{ ml: 2 }}
                                      aria-controls={open ? 'account-menu' : undefined}
                                      aria-haspopup="true"
                                      aria-expanded={open ? 'true' : undefined}
                                  >
                                      <SearchIcon color="primary"/>
                                  </IconButton>
                                  <SearchBar setMobileSearch={setSearchIconClick} mobileMode={true}/>
                              </Box>
                          }
                      </Toolbar>
                  </Container>
              </AppBar>
              {renderMobileMenu}
              {renderMenu}
              <Container maxWidth='lg'
                         sx={{ position: 'relative',
                             id: "drawer-container",
                             display:'flex',
                             //height:'100vw',
                             maxHeight: '$(window.innerHeight * 1)',
                             backgroundColor: theme.palette.background.default,
                             "&.MuiContainer-root": {
                                 paddingLeft: 0,
                                 paddingRight: 0,

                             },

                         }}>
                  <Outlet />
              </Container>
              <Snackbar 
                  open={notificationOpen}
                  autoHideDuration={6000}
                  onClose={() => setNotificationOpen(false)}
                  message={notificationMessage}
              />
          </Box>
      </MathJaxContext>
  );
}
 
export default App;
