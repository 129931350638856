import React from 'react';
import { ThemeProvider, createTheme,  } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

export const PrimaryTheme = createTheme({
  palette: {
    background: {
      default: '#faf0dc',
      paper: '#eae4d1',
    },
    primary: {
      main: '#765945',
    },
    secondary: {
      main: '#F0A50E',
    },
    warning: {
      main:"#f10cb2",
    },
    info: {
      main:"#11489C",
    },
  },

});

export const PrimaryAvatarTheme = createTheme({
  palette: {
    primary: {
      main: '#a39f92',
    },
    secondary: {
      main: '#F0A50E',
    },
  },
});

export const PrimaryCardTheme = createTheme({
  palette: {
    primary: {
      main: '#eae4d1', 
    },
    secondary: {
      main: '#f2eee3', 
    },
  },
  typography: {
    h1: {
      fontSize:"1rem"
    },
  }
});

export const TextColorTheme = createTheme({
  palette: {
    primary: {
      main: '#765945', 
    },
    secondary: {
      main: '#357a38',
    },
    warning: {
      main:"#aa2e25",
    },

  },
  typography: {
    h1: {
      fontSize:"1rem"
    },
  }
});