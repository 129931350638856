import React from 'react';

import Box from '@mui/material/Box';

import QuestionCard from "./QuestionCard";
import AnswerCard from "./AnswerCard";

import { PrimaryTheme, } from './themes';
import { QuestionPreviewDto } from '../services/autogen/models/QuestionPreviewDto';
let theme = PrimaryTheme;

type QuestionListItemProps = {
    item: QuestionPreviewDto;
    qnList: Array<QuestionPreviewDto>;
    setQnList: Function;
}

export default function QuestionListItem(props: QuestionListItemProps) {
    // @ts-ignore
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: window.innerWidth, alignItems: 'center'}}>
            <QuestionCard questionPreview={props.item} answerButton={true} isQuestionPreview={true} qnList={props.qnList} setQnList={props.setQnList}/>
            {
                props.item.answerPreview ? <AnswerCard answerPreview={props.item.answerPreview!} isAnswerPreview={true}/> : <Box mt={1}/>   
            }
        </Box>
    );
}