import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CreateIcon from "@mui/icons-material/Create";
import SettingsIcon from "@mui/icons-material/Settings";
import {
    createStyles,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Switch, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {alpha, styled} from '@mui/material/styles';
import {SettingDto, UserDto} from "../services/autogen";

interface State {
    password: string;
    showPassword: boolean;
}

interface LeftAlignedSwitchProps {
    label: string;
    sx?: any;
    isChecked?: boolean;
}


function LeftAlignedSwitch(props: LeftAlignedSwitchProps){
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;

    const stylesTypo: any = createStyles({
        formControlLabel: { fontSize: '0.8rem',
            '& label': { fontSize: '0.8rem' } }
    });
    
    return <FormControlLabel sx={{justifyContent: "space-between", 
                                  marginLeft:0,
                                 }} 
                             labelPlacement="start" control={<Switch defaultChecked={!!props.isChecked} />} 
                             label={<Typography style={stylesTypo.formControlLabel}> {props.label} </Typography>}/>
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(1),
        border: "1px solid #0000001f",
        '&.Mui-disabled': {
            border: "1px solid #0000001f",
        },
        '&:not(:first-of-type)': {
            border: "1px solid #0000001f",
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            border: "1px solid #0000001f",
            borderRadius: theme.shape.borderRadius,
        },
        '&.Mui-selected':{
            background: `${alpha(theme.palette.primary.main, 0.3)}`
        }
    },
}));

interface AccountSettingsDialogProps {
    settings?: SettingDto;
    currentUser?: UserDto;
}

export default function AccountSettingsDialog(props: AccountSettingsDialogProps) {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    let {settings, currentUser} = props;
    
    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState<State>({
        password: '',
        showPassword: false,
    });

    
    const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [selectedTopics, setSelectedTopics] = React.useState(() => ['Maths', 'Physics']);

    const handleSelectedTopicChanges = (
        event: React.MouseEvent<HTMLElement>,
        newTopics: string[],
    ) => {
        setSelectedTopics(newTopics);
    };


    // @ts-ignore
    return (
        <>
            <Button variant="outlined" startIcon={<SettingsIcon></SettingsIcon>} onClick={handleClickOpen}>
                Settings
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Account Settings</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        autoFocus
                        id="name" disabled
                        label="Email"
                        type="email"
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                        defaultValue={currentUser?.email}
                        margin="normal"
                    />
                    <FormControl fullWidth variant="standard" sx={{marginTop:2}}>
                        <InputLabel sx={{fontSize: desktop ? '1rem':'0.8rem'}} htmlFor="standard-adornment-password">New Password</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <TextField autoFocus 
                               id="phone" 
                               label={settings && settings.phoneNumber != "" ? settings.phoneNumber : "Phone" }
                               type="text" 
                               fullWidth 
                               variant="standard" 
                               margin="normal"
                               InputLabelProps={{ sx:{fontSize: desktop ? '1rem':'0.8rem'}} }
                    />
                    <Stack>
                        <LeftAlignedSwitch label="Notify questions by email" isChecked={settings ? settings.allowQuestionNotificationByEmail : false}></LeftAlignedSwitch>
                        <LeftAlignedSwitch label="Enable my group notifications" isChecked={settings ? settings.allowNotificationOnGroupActivities : false}></LeftAlignedSwitch>
                        <LeftAlignedSwitch label="Allow others to ask me privately" isChecked={settings ? settings.allowPrivateQuestion : false}></LeftAlignedSwitch>    
                    </Stack>
                    <InputLabel sx={{fontSize: desktop ? '0.8rem':'0.8rem', marginTop:'10px'}} htmlFor="selectedTopicsButtonGroup">Topics to include in "My Feed"</InputLabel>
                    <StyledToggleButtonGroup id="selectedTopicsButtonGroup"
                        color="primary"
                        value={settings ? settings.topics : selectedTopics}
                        onChange={handleSelectedTopicChanges}
                        size="small"
                        exclusive                     
                    >
                        {
                            ["Maths", "Physics", "Chemistry", "Biology", "General Papers"].map(topic => (
                                <ToggleButton sx={{borderRadius:1, margin:1, fontSize:desktop?'1rem':'0.55rem', padding:desktop?'7px':'1px' }} id={topic} key={topic} value={topic}>{topic}</ToggleButton>
                            ))
                        }
                    </StyledToggleButtonGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={handleClose}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}