import React from "react";
import {Editor} from "@tinymce/tinymce-react";

export const create_file_picker_callback = (editorRef: React.MutableRefObject<any>) => {
    return (cb: Function) => {
        let input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        /*
          Note: In modern browsers input[type="file"] is functional without
          even adding it to the DOM, but that might not be the case in some older
          or quirky browsers like IE, so you might want to add it to the DOM
          just in case, and visually hide it. And do not forget do remove it
          once you do not need it anymore.
        */

        input.onchange = function (e) {
            const target = e.target as HTMLInputElement;
            let file: Blob | null = null;
            if (target.files) {
                file = target.files[0];
            }

            const reader = new FileReader();
            reader.onload = function () {
                /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
                const id = 'blobid' + (new Date()).getTime();
                const blobCache = editorRef.current.editorUpload.blobCache;
                let base64 = null;
                if (reader.result) {
                    if (typeof reader.result === "string") {
                        base64 = reader.result.split(',')[1];
                    }
                }

                let blobInfo = null
                if (file) {
                    blobInfo = blobCache.create(id, file, base64);
                }

                if (blobInfo) {
                    blobCache.add(blobInfo);
                }

                /* call the callback and populate the Title field with the file name */
                if (file && blobInfo) {
                    cb(blobInfo.blobUri(), {title: 'image'/*file.name*/});
                }
            };
            if (file) {
                reader.readAsDataURL(file);
            }
        };

        input.click();
    }
};

const commonEditorAttributes = {
    theme: 'silver',
    menubar: false,
    branding: false,
    non_empty_elements: "td,th,iframe,video,audio,object,script,pre,code,area,base,basefont,br,col,frame,hr,img,input,isindex,link,meta,param,embed,source,wbr,track"
        // Now we add tags related to SVGs that it doesn't normally know about:
        + "svg,defs,pattern,desc,metadata,g,mask,path,line,marker,rect,circle,ellipse,polygon,polyline,linearGradient,radialGradient,stop,image,view,text,textPath,title,tspan,glyph,symbol,switch,use",

    verify_html: false,

    extended_valid_elements: "*[*]",
    images_dataimg_filter: function (img: HTMLElement) {
        return !img.hasAttribute('data-latex');
    },

    file_picker_types: 'image',
    block_unsupported_drop: false,

    automatic_uploads: true,
}

export const desktopEditorAttributes = {
    ...commonEditorAttributes,
    mobile: {
        theme: 'mobile',
        plugins: 'autosave lists autolink',
    },
    resize: false,
    height: 450,
    plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
        'media',
    ],
    toolbar1:
        'undo redo | formatselect | ' +
        'bold italic backcolor | alignleft aligncenter alignright alignjustify',
    
    toolbar2:
        'bullist numlist outdent indent | removeformat wordcount image | tiny_mce_wiris_formulaEditor | media | help ',

    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
};

export const mobileEditorAttributes = {
    ...commonEditorAttributes,
    height: 300,
    toolbar: false,
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
};

interface ITinyMceEditorWrapperProps {
    editorRef: React.MutableRefObject<any>,
    initValue: string,
    placeholder: string,
    otherAttributes: any
}
export function TinyMceEditorWrapper({editorRef, initValue, placeholder, otherAttributes}: ITinyMceEditorWrapperProps) {
    return <Editor
        tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
        // apiKey="iktb8z6l23ifk8a1a0t3a6ka1qkesm6xjjble6d9u0hnkhw1"
        onInit={(evt, editor) => {

            return editorRef.current = editor;
        }}
        initialValue={initValue}
        init={{
            ...otherAttributes,
            placeholder: placeholder,
            /* and here's our custom image picker*/
            file_picker_callback: create_file_picker_callback(editorRef),
        }}
    />
}