import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Questions from "./routes/questions";
import Topics from "./routes/topics";
import Question from "./routes/question";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { PrimaryTheme } from './components/themes';
import {Home} from "./pages/Home";
import UserProfile from './routes/UserProfile';
import {Login} from "./pages/Login";
import {Signup} from "./pages/Signup";
import {PostQuestion} from "./pages/PostQuestion";
import {AuthImageProvider, AuthProvider} from "./contexts/AuthContext";
import {PostAnswer} from "./pages/PostAnswer";
import Answer from "./routes/answer";
import { ConfirmProvider } from "material-ui-confirm";



//https://stackoverflow.com/questions/38839510/forcing-a-react-router-link-to-load-a-page-even-if-were-already-on-that-page
ReactDOM.render(
  <React.StrictMode>
      <AuthProvider>
          <AuthImageProvider>
              <ThemeProvider theme={PrimaryTheme}>
                  <ConfirmProvider>
                      <BrowserRouter>
                          <Routes>
                              <Route path="/" element={<App />}>
                                  <Route index element={<Home/>}/>
                                  <Route path="questions" element={ <Questions />}>
                                      <Route path=":questionId/:slug" element={<Question/>}/>
                                  </Route>
                                  <Route path="answer/:answerId" element={<Answer/>}/>
                                  <Route path="topics" element={ <Topics />} />
                                  <Route path="user/">
                                      <Route path=":userId" element={<UserProfile key={Date.now()}/>}/>
                                  </Route>
                                  <Route path="postquestion" element={<PostQuestion/>}/>
                                  <Route path="postquestion/:questionId" element={<PostQuestion/>}/>
                                  <Route path="postanswer/:questionId" element={<PostAnswer/>}/>
                                  <Route path="postanswer/:questionId/:answerId" element={<PostAnswer/>}/>
                                  <Route
                                      path="*"
                                      element={
                                          <main style={{ padding: "1rem" }}>
                                              <p>There's nothing here!</p>
                                          </main>
                                      }
                                  />
                              </Route>
                              <Route path="/login" element={<Login/>}/>
                              <Route path="/signup" element={<Signup/>}/>
                          </Routes>
                      </BrowserRouter>    
                  </ConfirmProvider>
              </ThemeProvider>
          </AuthImageProvider>
      </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
