/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertListDto } from '../models/AlertListDto';
import type { ArchiveAlertRequest } from '../models/ArchiveAlertRequest';
import type { DeleteFirebaseTokenRequest } from '../models/DeleteFirebaseTokenRequest';
import type { GetNoAlertStatsResponse } from '../models/GetNoAlertStatsResponse';
import type { ReadAlertRequest } from '../models/ReadAlertRequest';
import type { SendChatAlertRequest } from '../models/SendChatAlertRequest';
import type { UpdateFirebaseTokenRequest } from '../models/UpdateFirebaseTokenRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AlertsService {

    /**
     * @param isArchived
     * @param lastId
     * @returns AlertListDto Success
     * @throws ApiError
     */
    public static getApiV1Alerts(
        isArchived?: boolean,
        lastId?: string,
    ): CancelablePromise<AlertListDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Alerts',
            query: {
                'isArchived': isArchived,
                'lastId': lastId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AlertsArchiveAlert(
        requestBody?: ArchiveAlertRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Alerts/ArchiveAlert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AlertsReadAlert(
        requestBody?: ReadAlertRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Alerts/ReadAlert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AlertsUpdateFirebaseToken(
        requestBody?: UpdateFirebaseTokenRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Alerts/UpdateFirebaseToken',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AlertsDeleteFirebaseToken(
        requestBody?: DeleteFirebaseTokenRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Alerts/DeleteFirebaseToken',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AlertsSendChatAlert(
        requestBody?: SendChatAlertRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Alerts/SendChatAlert',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1AlertsUpdateAlertVisitTime(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Alerts/UpdateAlertVisitTime',
        });
    }

    /**
     * @returns GetNoAlertStatsResponse Success
     * @throws ApiError
     */
    public static getApiV1AlertsGetNoAlertsSinceLastVisit(): CancelablePromise<GetNoAlertStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Alerts/GetNoAlertsSinceLastVisit',
        });
    }

}