import {useParams} from "react-router-dom";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid, Link,
    Paper,
    Stack,
    Toolbar, useMediaQuery
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useContext, useEffect, useRef, useState} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PrimaryTheme } from '../components/themes';

import { ThemedCard, ThemedGrid } from "../components/StylizedComponents";

import QuestionCard from "../components/QuestionCard";
import AnswerCard from "../components/AnswerCard";
import {
    AnswerDto,
    AnswersService,
    OpenAPI,
    QuestionDto,
    QuestionsService,
    RelatedQuestionDto, SearchService
} from "../services/autogen";
import {last} from 'lodash';

import { Oval } from "react-loader-spinner";


export default function Question() {
    let {questionId} = useParams();
    let [question, setQuestion] = useState<QuestionDto>();
    let [answerList, setAnswerList] = useState<Array<AnswerDto>>([]);
    let [hasMoreAnswer, setHasMoreAnswer] = useState<boolean>(true);
    let [lastAnswerId, setLastAnswerId] = useState<string|null|undefined>(undefined);

    const [isQuestionLoading, setIsQuestionLoading] = useState(false);
    const [isAnswerLoading, setIsAnswerLoading] = useState(false);

    let [relatedQuestions, setRelatedQuestions] = useState<RelatedQuestionDto>();
    
    useEffect(()=> {
        if(questionId){
            setIsQuestionLoading(true);
            QuestionsService.getApiV1Questions(questionId!)
                .then(val => {
                    setQuestion(val);
                    setIsQuestionLoading(false);
                    
                    if (val) {
                        SearchService.getApiV1SearchGetRelatedQuestions(val.title || "",0)
                            .then(res => { 
                                setRelatedQuestions(res);
                                console.log(res);
                            });
                    }
                });
            
            
        }         
    },[questionId]);

    useEffect(()=> {
        if(questionId && hasMoreAnswer){
            AnswersService.getApiV1Answers(questionId!, lastAnswerId || undefined)
                .then(val => {
                    setAnswerList(answerList.concat(val.data!));
                    setHasMoreAnswer(val.hasMore || false);
                    setLastAnswerId(last(val.data!)?.id)
                });
        }
    },[]); // TODO invoke this when we scroll the answer list to the bottom


    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    return (
        <Box sx={{flex:1, alignItems:'', /*height:window.innerHeight*/ maxHeight:'100vh'}}>
            <Toolbar sx={{width: "100%", display:"block"}}/>
            {desktop &&
                <Grid container 
                      spacing={2} 
                      sx={{paddingTop: "1rem", 
                          '&.MuiGrid-root': {/*height:window.innerHeight*/ maxHeight:'100vh', height:'calc(100vh - 50px)' }
                      }}
                >
                    <ThemedGrid item xs={12} md={6} sx={{height:'100%', '&.MuiGrid-root': { height:'100%' } }}>
                        <Stack spacing={2} sx={{alignItems: 'center', fontSize: '0.9rem'}}>
                            {isQuestionLoading &&
                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '100px'}}>
                                    <Oval
                                        height={35}
                                        width={35}
                                        color="#4fa94d"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#4fa94d"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
    
                                    />
                                </Box>
                            }
                            {!isQuestionLoading &&
                                <QuestionCard question={question} answerButton={true}/>
                            }
                            <Accordion sx={{background: PrimaryTheme.palette.background.default , width:'100%'}} defaultExpanded={true}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography>Similar Questions</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack spacing={2}>
                                        {
                                            relatedQuestions && relatedQuestions.questions?.slice(0,6).map((relatedQn) => {
                                                if (relatedQn) {
                                                    var hstr = '/questions/'.concat(relatedQn.id || "",'/display');
                                                    return (
                                                        
                                                        <Paper sx={{padding: "0.3rem"}}>
                                                            <Typography>
                                                                <Link href={hstr} underline="none">
                                                                    {relatedQn.title}
                                                                </Link>
                                                            </Typography>
                                                        </Paper>
                                                        
                                                    );
                                                }
                                            })
                                            
                                        }
                             
                                    </Stack>
    
                                </AccordionDetails>
                            </Accordion>
    
                        </Stack>
                    </ThemedGrid>
                    <ThemedGrid item xs={12} 
                                md={6}
                                sx={{
                                    '&.MuiGrid-root': {height:'100%'}
                                }}
                    >
                        <Stack spacing={2}>
                            {answerList.map(al => <AnswerCard key={al.id} answerPreview={al} isAnswerPage={true}/>)}
                        </Stack>
                    </ThemedGrid>
                </Grid>
            }
            {mobile &&
                <Stack spacing={2} sx={{alignItems: 'center'}}>
                    <QuestionCard question={question}/>
                    {
                        answerList.map(al => <AnswerCard key={al.id} answerPreview={al} isAnswerPage={true}/>)
                    }
                </Stack>
            }
        </Box>
        );
}


 