import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GestureIcon from '@mui/icons-material/Gesture';
import FunctionsIcon from '@mui/icons-material/Functions';
import {Box, IconButton, Modal, Stack, TextField, useMediaQuery} from "@mui/material";
import BackspaceIcon from '@mui/icons-material/Backspace';
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {
    KeyboardMemory,
    LatexConfiguration,
    StandardLeafNode,
    getViewModeLatex,
    getEditModeLatex,
    insert,
    inSelectionMode,
    TreeNode, Placeholder, DescendingBranchingNode
} from 'mathkeyboardengine';
import * as mke from 'mathkeyboardengine';
import {MathJax, MathJaxBaseContext} from "better-react-mathjax";
import {Canvg} from "canvg";
import CloseIcon from '@mui/icons-material/Close';

type MathKeyboardDialogProps =
    {
        children?: any,
        insertEquation: (htmlContent: string, latex: string) => void;
    };

///
// https://github.com/yoksel/url-encoder/blob/master/src/js/script.js
// Encoding
// ----------------------------------------

const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;

function encodeSVG (data: string) {
    // Use single quotes instead of double to avoid encoding.
    data = data.replace(/"/g, `'`);

    data = data.replace(/>\s{1,}</g, `><`);
    data = data.replace(/\s{2,}/g, ` `);

    // Using encodeURIComponent() as replacement function
    // allows to keep result code readable
    return data.replace(symbols, encodeURIComponent);
}

// Namespace
// ----------------------------------------

function addNameSpace (data: string) {
    if (data.indexOf(`http://www.w3.org/2000/svg`) < 0) {
        data = data.replace(/<svg/g, `<svg xmlns="http://www.w3.org/2000/svg"`);
    }

    return data;
}

export default function MathKeyboardDialog(props : MathKeyboardDialogProps) {
    const mjContext = useContext(MathJaxBaseContext);
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    const [open, setOpen] = React.useState(false);
    
    const [editModeText, setEditModeText] = useState('');
    const [viewModeText, setViewModeText] = useState('');

    const rendererCanvas = useRef<HTMLCanvasElement>(null);
    const viewModeDiv = useRef<HTMLElement>();
    const editModeDiv = useRef<HTMLElement>();
    
    const keyboardMemory = useMemo(() => new KeyboardMemory(), [open]) ;
    const latexConfiguration = useMemo(()=> new LatexConfiguration(), [open]);
    
    
    function displayResult() {
        if (keyboardMemory.current instanceof Placeholder && keyboardMemory.current.nodes.length == 0) {
            latexConfiguration.activePlaceholderShape = String.raw`\blacksquare`;
        } else {
            latexConfiguration.activePlaceholderShape = '|';
        }
        let editModeLatex = getEditModeLatex(keyboardMemory, latexConfiguration);
        setEditModeText(String.raw`\(\displaystyle ` + editModeLatex + String.raw`\)`);
        
        let viewModeLatex = getViewModeLatex(keyboardMemory, latexConfiguration);
        setViewModeText(String.raw`\(\displaystyle ` + viewModeLatex + String.raw`\)`);
    }



    const handleClickOpen = () => {
        setEditModeText('');
        setViewModeText('');
        setOpen(true);
    };

    // https://stackoverflow.com/questions/69839960/convert-mathjax-output-to-png 
    
    function toMathML(jax: any, callback: (mml: any) => void) {
        // @ts-ignore
        mjContext.promise.then((mathJax: any)=> {
            var mml;
            try {
                mml = jax.root.toMathML("");
            } catch(err: any) {
                if (!err.restart) {throw err} // an actual error
                return mathJax.Callback.After([toMathML,jax,callback],err.restart);
            }
            mathJax.Callback(callback)(mml);    
        });
    }
    
    const handleClose = async () => {
        if (mjContext && viewModeDiv.current) {
            // @ts-ignore

            let spanSvg = viewModeDiv.current?.getElementsByTagName("svg")[0];
            let namespaced = addNameSpace(spanSvg.outerHTML);
            let encodedSvg = encodeSVG(namespaced);
            let wrappedEncodedSvg = `data:image/svg+xml,${encodedSvg}`;
            
            props.insertEquation(wrappedEncodedSvg, viewModeText);

            setOpen(false);
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const getFractionNode = () => new DescendingBranchingNode(String.raw`\frac{`, '}{', '}');
    const getPowerNode = () => new mke.AscendingBranchingNode('', '^{', '}');
    const getSubscriptNode = () => new mke.DescendingBranchingNode('', '_{', '}');
    const getSquareRootNode = () => new mke.StandardBranchingNode(String.raw`\sqrt{`, '}');
    const getRoundBracketsNode = () => new mke.RoundBracketsNode();
    const getSquareBracketsNode = () => new mke.StandardBranchingNode(String.raw`\left[`, String.raw`\right]`);
    const getPipesNode = () => new mke.StandardBranchingNode(String.raw`\left|`, String.raw`\right|`);
    const getCurlyBracketsNode = () => new mke.StandardBranchingNode(String.raw`\left\{`, String.raw`\right\}`);
    const getNRootNode = () => new mke.DescendingBranchingNode(String.raw`\sqrt[`, ']{', '}');
    const getBinomialNode = () => new mke.DescendingBranchingNode(String.raw`\binom{`, '}{', '}');
    const getCMatrixNode = () => new mke.MatrixNode('pmatrix', 2, 2);
    const getDoublePipesNode = () => new mke.StandardBranchingNode(String.raw`\left\|`, String.raw`\right\|`);
    const getMatrixNode = (row : any, column : any) => new mke.MatrixNode('vmatrix', row, column);
    const getIntegralNode = () => new mke.AscendingBranchingNode(String.raw`\int_{`, '}^{', '}');
    const getSumNode = () => new mke.AscendingBranchingNode(String.raw`\sum_{`, '}^{', '}');
    const getProductNode = () => new mke.AscendingBranchingNode(String.raw`\prod_{`, '}^{', '}');
    const getLimitNode = () => new mke.StandardBranchingNode(String.raw`\lim_{`, '}');

    const keyContainerStyle = {minWidth: '35px', minHeight: '35px', maxWidth: '35px', maxHeight: '35px'};
    const medkeyContainerStyle = {minWidth: '50px', minHeight: '35px', maxWidth: '50px', maxHeight: '35px'};
    const longkeyContainerStyle = {minWidth: '70px', minHeight: '35px', maxWidth: '70px', maxHeight: '35px'};

    const functionContainerStyle = {minWidth: '70px', minHeight: '35px', maxWidth: '70px', maxHeight: '35px'};

    const dialogButtonStyle = {minWidth: '50px', minHeight: '20px', maxWidth: '50px', maxHeight: '20px'};

    function wrapLatex(latex: string) {
        return String.raw`\(` + latex  + String.raw`\)`;
    }
    
    function MatrixModal() {
        const style = {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '0.01px solid #000',
            boxShadow: 0,
            pt: 2,
            px: 4,
            pb: 3,
        };
        
        const [open, setOpen] = React.useState(false);
        const [row, setRow] = React.useState(2);
        const [column, setColumn] = React.useState(2);
        
        const handleSetRow = (e: any) => {
            setRow(e.target.value);
        };

        const handleSetColumn = (e : any) => {
            setColumn(e.target.value);
        };
        
        const handleOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };

        return (
            <React.Fragment>
                <Button sx={{fontSize:'0.7rem'}} variant="outlined" style={functionContainerStyle} onClick={handleOpen}>Matrices</Button>
                <Modal
                    hideBackdrop
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...style, width: 200, display:"flex", alignItems:"center", justifyContent: 'center' }}>
                        <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <Box flexGrow={1} fontSize='0.7rem' fontWeight={'600'}>Enter Matrix Dimensions</Box>
                            <Box id="dimEntry" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center' , marginTop: '5px'}}>
                                <TextField label="Rows" size="small" value={row} onChange={handleSetRow}
                                />
                                <TextField label="Columns" size="small" value={column} onChange={handleSetColumn}
                                />
                            </Box>
                            <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '3px'}}>
                                <Box onClick={()=> {
                                    insert(keyboardMemory, getMatrixNode(row,column)); displayResult();}}>
                                    <Button variant="outlined" style={dialogButtonStyle} sx={{fontSize:'0.7rem'}}>
                                        Insert
                                    </Button>
                                </Box>
                                <Button variant="outlined" style={dialogButtonStyle} onClick={handleClose} sx={{fontSize:'0.7rem', marginLeft:'2px', marginTop: '3px'}}>
                                    Close
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </React.Fragment>
        );
        
    }

    function CalculusModal() {
        const style = {
            position: 'relative' as 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '0.01px solid #000',
            boxShadow: 0,
            pt: 2,
            px: 4,
            pb: 3,
        };

        const [open, setOpen] = React.useState(false);
        const handleOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };

        return (
            <React.Fragment>
                <Button sx={{fontSize:'0.7rem'}} variant="outlined" style={functionContainerStyle} onClick={handleOpen}>Calculus</Button>
                <Modal
                    hideBackdrop
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...style, width: 200, display:"flex", alignItems:"center", justifyContent: 'center' }}>
                        <Box style={{display: 'flex', flexDirection: 'column'}}>
                            <Box style={{display: 'flex', flexDirection: 'row'}}>
                                <Box onClick={()=> {
                                    insert(keyboardMemory, getIntegralNode()); displayResult();}}>
                                    <Button variant="outlined" style={keyContainerStyle}>
                                        <MathJax inline>{wrapLatex(getViewModeLatex(getIntegralNode(), latexConfiguration))}</MathJax>
                                    </Button>
                                </Box>
                                <Box sx={{marginLeft:'2px'}} onClick={()=> {
                                    insert(keyboardMemory, getSumNode()); displayResult();}}>
                                    <Button variant="outlined" style={keyContainerStyle}>
                                        <MathJax inline>{wrapLatex(getViewModeLatex(getSumNode(), latexConfiguration))}</MathJax>
                                    </Button>
                                </Box>
                                <Box sx={{marginLeft:'2px'}} onClick={()=> {
                                    insert(keyboardMemory, getProductNode()); displayResult();}}>
                                    <Button variant="outlined" style={keyContainerStyle}>
                                        <MathJax inline>{wrapLatex(getViewModeLatex(getProductNode(), latexConfiguration))}</MathJax>
                                    </Button>
                                </Box>
                                <Box sx={{marginLeft:'2px'}} onClick={()=> {
                                    insert(keyboardMemory, getLimitNode()); displayResult();}}>
                                    <Button variant="outlined" style={keyContainerStyle}>
                                        <MathJax inline>{wrapLatex(getViewModeLatex(getLimitNode(), latexConfiguration))}</MathJax>
                                    </Button>
                                </Box>
                            </Box>

                            <Box style={{display: 'flex', flexDirection: 'row'}}>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`dx`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`dx`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`dy`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`dy`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`dz`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`dz`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\infty`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\infty`)}</MathJax>
                                </Button>
                            </Box>

                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                <Button variant="outlined" style={dialogButtonStyle} onClick={handleClose} sx={{fontSize:'0.7rem', marginLeft:'0px', marginTop: '3px'}}>
                                    Close
                                </Button>
                            </Box>
                            
                        </Box>
                    </Box>
                </Modal>
            </React.Fragment>
        );
    }

    function NotationModal() {
        const style = {
            position: 'relative' as 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '0.01px solid #000',
            boxShadow: 0,
            pt: 2,
            px: 4,
            pb: 3,
        };

        const [open, setOpen] = React.useState(false);
        const handleOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };
        
        function KeyButton(latexDisp: any, onClickIn: any, containerStyle : any ) {
            return (
                <Button
                    onClick={onClickIn}
                    variant="outlined" style={containerStyle }>
                    <MathJax inline>{wrapLatex(latexDisp)}</MathJax>
                </Button>
            );
        }

        return (
            <React.Fragment>
                <Button sx={{fontSize:'0.7rem'}} variant="outlined" style={functionContainerStyle} onClick={handleOpen}>Notation</Button>
                <Modal
                    hideBackdrop
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...style, width: 200, display:"flex", alignItems:"center", justifyContent: 'center' }}>
                        <Box style={{display: 'flex', flexDirection: 'column'}}>
                            <Box style={{display: 'flex', flexDirection: 'row'}}>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\subset`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\subset`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\subseteq`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\subseteq`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\in`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\in`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\forall`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\forall`)}</MathJax>
                                </Button>
                            </Box>

                            <Box style={{display: 'flex', flexDirection: 'row'}}>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\angle`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\angle`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\triangle`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\triangle`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\nabla`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\nabla`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\|`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\|`)}</MathJax>
                                </Button>
                            </Box>

                            <Box style={{display: 'flex', flexDirection: 'row'}}>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`T`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`T`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`A`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`A`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`B`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`B`)}</MathJax>
                                </Button>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`C`)); displayResult(); handleClose()}}
                                    variant="outlined" style={keyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`C`)}</MathJax>
                                </Button>
                            </Box>

                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                <Button variant="outlined" style={dialogButtonStyle} onClick={handleClose} sx={{fontSize:'0.7rem', marginLeft:'0px', marginTop: '3px'}}>
                                    Close
                                </Button>
                            </Box>

                        </Box>
                    </Box>
                </Modal>
            </React.Fragment>
        );
    }

    function TrigModal() {
        const style = {
            position: 'relative' as 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '0.01px solid #000',
            boxShadow: 0,
            pt: 2,
            px: 4,
            pb: 3,
        };
        
        const [open, setOpen] = React.useState(false);
        const handleOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };
        
        return (
            <React.Fragment>
                <Button sx={{fontSize:'0.7rem'}} variant="outlined" style={functionContainerStyle} onClick={handleOpen}>Trig</Button>
                <Modal
                    hideBackdrop
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Box sx={{ ...style, width: 200, display:"flex", alignItems:"center", justifyContent: 'center' }}>
                        <Box style={{display: 'flex', flexDirection: 'column'}}>
                            <Box style={{display: 'flex', flexDirection: 'row'}}>
                                <Button
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\sin`)); displayResult(); handleClose()}}
                                    variant="outlined" style={medkeyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\\sin`)}</MathJax>
                                </Button>
                                <Button sx={{marginLeft:'2px'}}
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\tan`)); displayResult(); handleClose()}}
                                    variant="outlined" style={medkeyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\\tan`)}</MathJax>
                                </Button>
                                <Button sx={{marginLeft:'2px'}}
                                    onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\cos`)); displayResult(); handleClose()}}
                                    variant="outlined" style={medkeyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\\cos`)}</MathJax>
                                </Button>
                                
                            </Box>
                            
                            <Box style={{display: 'flex', flexDirection: 'row', marginTop:'5px'}}>
                                <Button sx={{marginLeft:'0px'}}
                                        onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\cos^{-1}`)); displayResult(); handleClose()}}
                                        variant="outlined" style={medkeyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\\cos^{-1}`)}</MathJax>
                                </Button>
                                <Button sx={{marginLeft:'2px'}}
                                        onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\tan^{-1}`)); displayResult(); handleClose()}}
                                        variant="outlined" style={medkeyContainerStyle  }>
                                    <MathJax inline>{wrapLatex(String.raw`\\tan^{-1}`)}</MathJax>
                                </Button>
                                <Button sx={{marginLeft:'2px'}}
                                        onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\cos^{-1}`)); displayResult(); handleClose()}}
                                        variant="outlined" style={medkeyContainerStyle  }>
                                    <MathJax inline>{wrapLatex(String.raw`\\cos^{-1}`)}</MathJax>
                                </Button>
                            </Box>

                            <Box style={{display: 'flex', flexDirection: 'row', marginTop:'5px'}}>
                                <Button sx={{marginLeft:'0px'}}
                                        onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\sinh`)); displayResult(); handleClose()}}
                                        variant="outlined" style={medkeyContainerStyle }>
                                    <MathJax inline>{wrapLatex(String.raw`\\sinh`)}</MathJax>
                                </Button>
                                <Button sx={{marginLeft:'2px'}}
                                        onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\tanh`)); displayResult(); handleClose()}}
                                        variant="outlined" style={medkeyContainerStyle  }>
                                    <MathJax inline>{wrapLatex(String.raw`\\tanh`)}</MathJax>
                                </Button>
                                <Button sx={{marginLeft:'2px'}}
                                        onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\cosh`)); displayResult(); handleClose()}}
                                        variant="outlined" style={medkeyContainerStyle  }>
                                    <MathJax inline>{wrapLatex(String.raw`\\cosh`)}</MathJax>
                                </Button>
                            </Box>

                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                <Button variant="outlined" style={dialogButtonStyle} onClick={handleClose} sx={{fontSize:'0.7rem', marginLeft:'0px', marginTop: '3px'}}>
                                    Close
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </React.Fragment>
        );
    }

    function createHandler(matchingPredicateForKey : Function, handleForKeyboardMemoryAndKey : Function) {
        return { canHandle: matchingPredicateForKey, handle: handleForKeyboardMemoryAndKey };
    }

    const physicalKeydownHandlersNoShift = [
        createHandler((key : string) => key == 'ArrowUp', (k: any, key : string) => mke.moveUp(k)),
        createHandler((key : string) => key == 'ArrowDn', (k: any, key : string) => mke.moveDown(k)),
        createHandler((key : string) => key == 'ArrowLeft', (k: any, key : string) => mke.moveLeft(k)),
        createHandler((key : string) => key == 'ArrowRight', (k: any, key : string) => mke.moveRight(k)),
        createHandler((key : string) => key == '1', (k: any, key: string) => mke.insert(k, new StandardLeafNode('1'))),
        createHandler((key : string) => key == '2', (k: any, key: string) => mke.insert(k, new StandardLeafNode('2'))),
        createHandler((key : string) => key == '3', (k: any, key: string) => mke.insert(k, new StandardLeafNode('3'))),
        createHandler((key : string) => key == '4', (k: any, key: string) => mke.insert(k, new StandardLeafNode('4'))),
        createHandler((key : string) => key == '5', (k: any, key: string) => mke.insert(k, new StandardLeafNode('5'))),
        createHandler((key : string) => key == '6', (k: any, key: string) => mke.insert(k, new StandardLeafNode('6'))),
        createHandler((key : string) => key == '7', (k: any, key: string) => mke.insert(k, new StandardLeafNode('7'))),
        createHandler((key : string) => key == '8', (k: any, key: string) => mke.insert(k, new StandardLeafNode('8'))),
        createHandler((key : string) => key == '9', (k: any, key: string) => mke.insert(k, new StandardLeafNode('9'))),
        createHandler((key : string) => key == 'x', (k: any, key: string) => mke.insert(k, new StandardLeafNode('x'))),
        createHandler((key : string) => key == 'y', (k: any, key: string) => mke.insert(k, new StandardLeafNode('y'))),
        createHandler((key : string) => key == 'z', (k: any, key: string) => mke.insert(k, new StandardLeafNode('z'))),
        createHandler((key : string) => key == 'a', (k: any, key: string) => mke.insert(k, new StandardLeafNode('a'))),
        createHandler((key : string) => key == 'b', (k: any, key: string) => mke.insert(k, new StandardLeafNode('b'))),
        createHandler((key : string) => key == 'c', (k: any, key: string) => mke.insert(k, new StandardLeafNode('c'))),
        createHandler((key : string) => key == '+', (k: any, key: string) => mke.insert(k, new StandardLeafNode('+'))),
        createHandler((key : string) => key == '-', (k: any, key: string) => mke.insert(k, new StandardLeafNode('.'))),
        createHandler((key : string) => key == '=', (k: any, key: string) => mke.insert(k, new StandardLeafNode('='))),
        createHandler((key : string) => key == '.', (k: any, key: string) => mke.insert(k, new StandardLeafNode('-'))),
        createHandler((key : string) => key == '*', (k: any, key: string) => mke.insert(k, new StandardLeafNode(() => (String.raw`\times`)))),
        createHandler((key : string) => key == '/', (k: any, key: string) => mke.insertWithEncapsulateCurrent(k, getFractionNode(), { deleteOuterRoundBracketsIfAny: true })),
        createHandler((key : string) => key == '^', (k: any, key: string) => mke.insertWithEncapsulateCurrent(k, getPowerNode(), { deleteOuterRoundBracketsIfAny: true })),
        ];

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        console.log('User pressed: ', event.key);
        const key = event.key;
        physicalKeydownHandlersNoShift.find((handler) => handler.canHandle(key))?.handle(keyboardMemory, key);
        displayResult();
    };

    return (
        <>
            <canvas ref={rendererCanvas} width={editModeDiv?.current?.clientWidth} height={editModeDiv?.current?.clientHeight} hidden={true}/>
            <IconButton color="default" aria-label="gesture-icon" onClick={handleClickOpen}>
                <FunctionsIcon/>
            </IconButton>
            <Dialog open={open} onClose={handleClose} onKeyDown={handleKeyDown} fullWidth PaperProps={{sx:{width: desktop? '580px' : window.innerWidth*0.9, margin: desktop ? '32px' : '5px'} }} >
                <DialogTitle>Draw</DialogTitle>
                <DialogContent sx={{padding:'5px'}}>
                    <Stack>
                        <Box sx={{
                            border: '1px solid gray',
                            padding: '0.25rem',
                            minHeight: '2.5rem'
                        }}  id="typesetEditModeLatex" ref={editModeDiv} >
                            <MathJax hideUntilTypeset={"every"}
                                     inline
                                     dynamic>{editModeText}</MathJax>
                        </Box>
                        
                        <Box ref={viewModeDiv} id="viewModeLatex" hidden={true}>
                            <MathJax hideUntilTypeset={"every"}
                                     inline
                                     dynamic>{viewModeText}</MathJax>
                        </Box>
                        
                        
                        {/*
                        <Box onClick={()=> {insert(keyboardMemory, new StandardLeafNode('a')); displayResult();}}><MathJax inline>a</MathJax></Box>
                        <Box onClick={()=> {insert(keyboardMemory, new StandardLeafNode('b')); displayResult();}}><MathJax inline>b</MathJax></Box>
                        <Box onClick={()=> {
                            insert(keyboardMemory, getFractionNode()); displayResult();}}>
                            <MathJax inline>{wrapLatex(getViewModeLatex(getFractionNode(), latexConfiguration))}</MathJax>
                        </Box>
                        */}
                        <Box id="keyboard" style={{display: 'flex', flexDirection: 'column'}}>
                            <Box id="topBlock" style={{display: 'flex', flexDirection: 'row', width:'100%'}} >
                                <Box id="numberSegment" style={{display: 'flex', flexDirection: 'column'}}>
                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('7')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>7</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('8')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>8</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('9')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>9</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(() => (String.raw`\div`))); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\div`)}</MathJax>
                                        </Button>
                                    </Box>
                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('4')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>4</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('5')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>5</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('6')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>6</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(() => (String.raw`\times`))); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\times`)}</MathJax>
                                        </Button>
                                    </Box>
                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('1')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>1</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('2')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>2</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('3')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>3</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('-')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>-</MathJax>
                                        </Button>
                                    </Box>
                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('0')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>0</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('.')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>.</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('=')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>=</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('+')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>+</MathJax>
                                        </Button>
                                    </Box>
                                </Box>

                                <Box id="navigationSegment" style={{display: 'flex', flexDirection: 'column', marginLeft:'35px'}}>
                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(',')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>,</MathJax>
                                        </Button>
                                        <Box onClick={()=> {
                                            mke.moveUp(keyboardMemory);
                                            displayResult();
                                        }}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(`\\uparrow`)}</MathJax>
                                            </Button>
                                        </Box>
                                        <Box onClick={()=> {
                                            mke.deleteCurrent(keyboardMemory);
                                            displayResult();
                                        }}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <IconButton color="default" aria-label="gesture-icon"  style={{maxWidth:'20px', maxHeight:'20px'}}>
                                                    <BackspaceIcon/>
                                                </IconButton>
                                            </Button>
                                        </Box>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode('!')); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>!</MathJax>
                                        </Button>
                                    </Box>

                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Box onClick={()=> {
                                            if(mke.inSelectionMode(keyboardMemory)){
                                                mke.selectLeft(keyboardMemory);
                                            }
                                            else{
                                                mke.moveLeft(keyboardMemory)
                                            }
                                            displayResult();}}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(String.raw`\leftarrow`)}</MathJax>
                                            </Button>
                                        </Box>
                                        <Box onClick={()=> {
                                            mke.moveDown(keyboardMemory);
                                            displayResult();
                                        }}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(String.raw`\downarrow`)}</MathJax>
                                            </Button>
                                        </Box>
                                        <Box onClick={()=> {
                                            mke.moveRight(keyboardMemory);
                                            displayResult();
                                        }}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(String.raw`\rightarrow`)}</MathJax>
                                            </Button>
                                        </Box>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\sim`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(`\\sim`)}</MathJax>
                                        </Button>
                                    </Box>

                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`<`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(`<`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`>`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(`>`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\leq`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\leq`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\geq`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\geq`)}</MathJax>
                                        </Button>
                                    </Box>

                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`(`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(`(`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`)`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(`)`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\{`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex('\\{')}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\}`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex('\\}')}</MathJax>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                            <Box id="bottomBlock" style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                                <Box id="greekSegment" style={{display: 'flex', flexDirection: 'column', }}>
                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\alpha`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\alpha`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\beta`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\beta`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\lambda`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\lambda`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\mu`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\mu`)}</MathJax>
                                        </Button>
                                    </Box>

                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`x`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`x`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`y`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`y`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`z`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`z`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\pi`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\pi`)}</MathJax>
                                        </Button>
                                    </Box>

                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`a`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`a`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`b`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`b`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`c`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`c`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\gamma`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\gamma`)}</MathJax>
                                        </Button>
                                    </Box>
                                </Box>

                                <Box id="fractionSegment" style={{display: 'flex', flexDirection: 'column', marginLeft:'35px'}}>
                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Box onClick={()=> {
                                            insert(keyboardMemory, getFractionNode()); displayResult();}}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(getViewModeLatex(getFractionNode(), latexConfiguration))}</MathJax>
                                            </Button>
                                        </Box>
                                        <Box onClick={()=> {
                                            insert(keyboardMemory, getPowerNode()); displayResult();}}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(getViewModeLatex(getPowerNode(), latexConfiguration))}</MathJax>
                                            </Button>
                                        </Box>
                                        <Box onClick={()=> {
                                            insert(keyboardMemory, getSubscriptNode()); displayResult();}}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(getViewModeLatex(getSubscriptNode(), latexConfiguration))}</MathJax>
                                            </Button>
                                        </Box>
                                        <Box onClick={()=> {
                                            insert(keyboardMemory, getNRootNode()); displayResult();}}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(getViewModeLatex(getNRootNode(), latexConfiguration))}</MathJax>
                                            </Button>
                                        </Box>
                                    </Box>

                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        {/*<Box onClick={()=> {
                                            insert(keyboardMemory, getSquareBracketsNode()); displayResult();}}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(getViewModeLatex(getSquareBracketsNode(), latexConfiguration))}</MathJax>
                                            </Button>
                                        </Box>*/}
                                        <Box onClick={()=> {
                                            insert(keyboardMemory, getRoundBracketsNode()); displayResult();}}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(getViewModeLatex(getRoundBracketsNode(), latexConfiguration))}</MathJax>
                                            </Button>
                                        </Box>
                                        <Box onClick={()=> {
                                            insert(keyboardMemory, getPipesNode()); displayResult();}}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(getViewModeLatex(getPipesNode(), latexConfiguration))}</MathJax>
                                            </Button>
                                        </Box>
                                        <Box onClick={()=> {
                                            insert(keyboardMemory, getCurlyBracketsNode()); displayResult();}}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(getViewModeLatex(getCurlyBracketsNode(), latexConfiguration))}</MathJax>
                                            </Button>
                                        </Box>
                                        <Box onClick={()=> {
                                            insert(keyboardMemory, getDoublePipesNode()); displayResult();}}>
                                            <Button variant="outlined" style={keyContainerStyle}>
                                                <MathJax inline>{wrapLatex(getViewModeLatex(getDoublePipesNode(), latexConfiguration))}</MathJax>
                                            </Button>
                                        </Box>
                                    </Box>

                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\approx`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\approx`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\neq`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\neq`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\in`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\in`)}</MathJax>
                                        </Button>
                                        <Button
                                            onClick={()=> {insert(keyboardMemory, new StandardLeafNode(`\\Rightarrow`)); displayResult();}}
                                            variant="outlined" style={keyContainerStyle }>
                                            <MathJax inline>{wrapLatex(String.raw`\Rightarrow`)}</MathJax>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                            <Box id="functionBlock" style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                                <Box style={{display: 'flex', flexDirection: 'row'}}>
                                    <MatrixModal />
                                    <TrigModal />
                                    <Box style={{display: 'flex', flexDirection: 'row', marginLeft: '35px' }}>
                                        <CalculusModal />
                                        <NotationModal />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Stack>
                   
                   
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={handleClose}>
                        Insert
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}