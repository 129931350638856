/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
    Box,
    Card,
    Chip,
    Stack,
    useMediaQuery,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import UsernameAvatar from "../components/avatarChips";
import DescriptionImageThumbnails from "../components/DescriptionImageThumbnails";
import Typography from "@mui/material/Typography";
import React, {useContext, useEffect, useRef, useState} from "react";
import IconButton from "@mui/material/IconButton";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import CardActions from "@mui/material/CardActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import {ThemeProvider, createTheme,} from '@mui/material/styles';
import {Link, Navigate} from "react-router-dom";
import {TextColorTheme} from './themes';

// @ts-ignore
import ModalImage from "react-modal-image";

import {PrimaryTheme} from './themes';
import {OpenAPI, QuestionDto, QuestionPreviewDto, QuestionsService} from "../services/autogen";
import TimeHelper from "../helpers/TimeHelper";
import CoinAndClock from "./CoinAndClock";
import DOMPurify from "dompurify";
import useHookWithRefCallback from "../hooks/useHookWithRefCallback";
import {MathJaxBaseContext} from "better-react-mathjax";
import {strip} from "../helpers/DomManipulator";
import {useNavigate} from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {getCloudinarySmallUrl} from "../helpers/DomManipulator";

import {
    EmailIcon,
    EmailShareButton,
    TelegramIcon,
    TelegramShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {useAuth} from "../contexts/AuthContext";
import _ from "lodash";

type QuestionCardProps = {
    children?: React.ReactNode;
    theme?: React.ReactNode;
    answerButton?: boolean;
    questionPreview?: QuestionPreviewDto;
    question?: QuestionDto;
    isQuestionPreview?: boolean | undefined;
    qnList?: Array<QuestionPreviewDto>;
    setQnList?: Function;
}


const MAX_NUM_CHARACTERS_WITH_EQUATIONS = 500;

export default function QuestionCard(props: QuestionCardProps) {
    const {currentUser} = useAuth();
    let navigate = useNavigate();

    const [questionMenuAnchorEl, setQuestionMenuAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(questionMenuAnchorEl);
    const handleOpenQuestionMenu = (event: React.MouseEvent<HTMLElement>) => {
        setQuestionMenuAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setQuestionMenuAnchorEl(null);
    };

    const onEditQuestionClicked = () => {
        handleClose();
        navigate(`/postquestion/${question?.id || questionPreview?.id}`);
    }

    const onDeleteQuestionClicked = () => {
        handleClose();
        
        console.log("attempt to delete question");
        deleteQuestion();
    }

    const deleteQuestion = () => {
        async function delQuestion() : Promise<void> {
            let res = await QuestionsService.deleteApiV1Questions(question?.id || questionPreview?.id || ""  );

            if (res) {
                console.log(res);
                // set new question list that exclude this particular question
                if (props.qnList && props.setQnList) {
                    let newQuestionList = props.qnList.filter(function (qn) {
                        return (qn.id != questionPreview?.id && qn.id != question?.id)
                    })
                    
                    props.setQnList(newQuestionList);
                }
            }
        }

        delQuestion();
    }

    const questionContainerRef = useRef<any>(null);
    let [questionWidth, setQuestionWidth] = useState(570);

    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;

    let questionPreview = props.questionPreview;
    let question = props.question;

    const mjContext = useContext(MathJaxBaseContext);
    const [questionContentRef, setQuestionContentRef] = useHookWithRefCallback((current: any) => {
        if (mjContext && current) {
            mjContext.promise.then((mathJax: any) => {
                mathJax.Hub.Queue(["Typeset", mathJax.Hub, current])
            });
        }
        
        setUpvoted(props.question?.isUpVoted || false);
    }, [question]);

    useEffect(() => {
        setQuestionWidth(questionContainerRef.current?.offsetWidth - 30);
    }, []);

    let [upvoted, setUpvoted] = useState<boolean>(props.questionPreview?.isUpVoted || false);
    
    const submitUpvote = () => {
        async function upvote() : Promise<void> {
            let res = await QuestionsService.postApiV1QuestionsUpVoteQuestion({ questionId : props.questionPreview?.id || "" } || null );

            if (res) {
                setUpvoted(true);
            }
        }
        
        upvote();
    }
    
    const removeUpvote = () => {
        async function removeUpvote() : Promise<void> {
            let res = await QuestionsService.postApiV1QuestionsRemoveUpVoteQuestion({ questionId : props.questionPreview?.id || "" } || null );

            if (res) {
                setUpvoted(false);
            }
        }

        removeUpvote();
    }

    let actionIconStyle = {marginTop: '-5px', '&.MuiSvgIcon-root': {fontSize: '1.2rem'}, color: upvoted ? PrimaryTheme.palette.primary.main : 'inherit'};
    let linkToQuestionPage = `/questions/${question?.id || questionPreview?.id}/${question?.slugUrl || questionPreview?.slugUrl}`;
    let serverAddress = "https://staging.peakqitt.com";
    let inconspicuousLink = {textDecoration: 'none', color: PrimaryTheme.palette.primary.main, fontSize: '1.1rem', fontWeight: '600', marginBottom: '3px'};
    let questionDescLink = {textDecoration: 'none', color: PrimaryTheme.palette.common.black, fontSize: '0.9rem', };
    let canEditQuestion = question?.publisher?.id === currentUser?.id || questionPreview?.publisher?.id === currentUser?.id || _.includes(currentUser?.roles, 'editor') ;
    let canDeleteQuestion = (!((question?.answerNumber || 0) > 0 || (questionPreview?.answerNumber || 0) > 0) && (question?.publisher?.id === currentUser?.id || questionPreview?.publisher?.id === currentUser?.id)) || _.includes(currentUser?.roles, 'editor');
    let foobar = DOMPurify.sanitize(question?.description!, {
        ADD_TAGS: ["iframe"],
        ADD_ATTR: ['allow', 'fullscreen', 'frameborder', 'scrolling', 'linebreak'],
        USE_PROFILES: {mathMl: true, svg: true, html: true}
    });
    
    let numberAnswer = props.questionPreview?.answerNumber || props.question?.answerNumber;

    function renderTopicChipMobile(d: string | null | undefined) {
        return <Chip variant="outlined"
                     label={d}
                     size='small'
                     sx={{
                         marginLeft: 1,
                         marginBottom: '1px',
                         '&.MuiChip-root': {
                             fontSize: '0.65rem',
                             height: '18px'
                         }
                     }}
                     key={d} // TODO: use their ID
        />;
    }

    function renderTopicChipDesktop(d: string | null | undefined) {
        return <Chip variant="outlined"
                     label={d}
                     size='small'
                     sx={{marginLeft: 1}}
                     key={d} // TODO: use their ID
        />;
    }

// @ts-ignore
    return <ThemeProvider theme={props.theme ? props.theme : PrimaryTheme}>
        <Card sx={{borderRadius: 3, flexShrink: 0, width: '95%', marginBottom: '18px'}}>
            <CardContent sx={{display: 'flex', paddingBottom: 0}}>
                {!props.isQuestionPreview &&
                <Box sx={{flexShrink: 0}}>
                    <UsernameAvatar name={question?.publisher?.userName || questionPreview?.publisher?.userName!}
                                    color="primary"
                                    imgSrc={question?.publisher?.profilePicture || questionPreview?.publisher?.profilePicture!}
                                    userId={question?.publisher?.id}
                    />
                    <Typography variant="subtitle2" component="span" align="center"
                                sx={{display: 'block', fontSize: '0.75rem'}}>
                        Rating: {Math.round(props.question?.publisher?.rating?.answererRating as number)}
                    </Typography>
                </Box>
                }

                <Typography variant="h1" component="span" align="center"
                            sx={{display: 'block', fontWeight: '600', fontSize: '1.5rem', marginLeft: '5px'}}>
                    Q
                </Typography>
                {desktop && questionPreview &&
                <Box>
                    {questionPreview.topic && renderTopicChipDesktop(questionPreview.topic)}
                    {questionPreview?.metadata?.map(d => renderTopicChipDesktop(d.name))}
                </Box>
                }

                <Box sx={{flex: 1}}>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        {numberAnswer &&
                            <Link to={linkToQuestionPage} style={inconspicuousLink}>
                                <Typography variant="h1" component="span" align="center"
                                            sx={{
                                                display: 'block',
                                                fontWeight: '600',
                                                fontSize: '0.8rem',
                                                marginLeft: '5px',
                                                color: TextColorTheme.palette.primary.main
                                            }}>
                                    [ {numberAnswer} {(numberAnswer > 1) ? 'answers' : 'answer'} ]
                                </Typography>
                            </Link>
                        }
                        <CoinAndClock questionPreview={props.questionPreview}/>
                        {canEditQuestion &&
                            <>
                                <IconButton aria-label="settings" sx={{alignItems: 'start', padding: '0px'}}
                                            aria-controls={open ? 'question-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleOpenQuestionMenu}>
                                    <MoreVertIcon/>
                                </IconButton>
                                <Menu
                                    id="question-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={questionMenuAnchorEl}
                                    open={open}
                                    onClose={handleClose}>
                                    <MenuItem key="EditQuestion" onClick={onEditQuestionClicked} >Edit Question</MenuItem>
                                    {canDeleteQuestion &&
                                        <Link to="/" style={{textDecoration: 'none'}} >
                                            <MenuItem key="DeleteQuestion" onClick={onDeleteQuestionClicked}>Delete
                                                Question</MenuItem>
                                        </Link>
                                        
                                    }
                                </Menu>
                            </>
                        }

                    </Box>
                    {mobile && questionPreview &&
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap'}}>
                        {questionPreview.topic && renderTopicChipMobile(questionPreview.topic)}
                        {questionPreview?.metadata?.map(d => renderTopicChipMobile(d.name))}
                    </Box>
                    }
                </Box>
            </CardContent>
            <CardContent ref={questionContainerRef} sx={{padding: 1}}>
                <Link to={linkToQuestionPage} style={inconspicuousLink}>
                    {question?.title || questionPreview?.title}
                </Link>
                {
                    !props.isQuestionPreview &&
                    <>
                        <Box ref={setQuestionContentRef} dangerouslySetInnerHTML={{
                            __html: foobar
                        }} css={css`
                            img {
                                max-width:100%
                            }
                        `}>
                        </Box>
                        <DescriptionImageThumbnails imageUrls={question?.questionImages}/>
                    </>    
                    
                }
                {
                    props.isQuestionPreview && questionPreview && questionPreview?.description &&
                        <>
                            <Link to={linkToQuestionPage} style={questionDescLink} >
                                {
                                    // @ts-ignore
                                    questionPreview?.strippedDescription?.length <= MAX_NUM_CHARACTERS_WITH_EQUATIONS ?
                                    <Box dangerouslySetInnerHTML={{__html: questionPreview?.strippedDescription ?? strip(questionPreview?.description!)}}>
                                    </Box>
                                    :
                                    <ShowMoreText
                                        /* Default options */
                                        lines={5}
                                        more="Read more"
                                        less="Read less"
                                        className="content-css"
                                        expanded={false}
                                        width={questionWidth}
                                        truncatedEndingComponent={"... "}
                                        onClick={() => navigate(linkToQuestionPage)}
                                    >
                                        {strip(questionPreview?.description!)}
                                    </ShowMoreText>
                                }
                                {/*{questionPreview?.strippedDescription ?? strip(questionPreview?.description!)}*/}
                            </Link>
                            {questionPreview.firstImageUrl &&
                            <Box sx={{maxWidth: 100, display:'flex', flexDirection:'row'}}>
                                <ModalImage
                                    small={getCloudinarySmallUrl(questionPreview.firstImageUrl!)}
                                    large={questionPreview.firstImageUrl}
                                    alt="Preview"/>
                                <Link to={linkToQuestionPage} style={inconspicuousLink}>
                                    <Box sx={{display: 'flex', alignItems: 'stretch' }}>
                                    </Box>
                                </Link>
                            </Box>
                            
                            }
                            
                        </>
                }
            </CardContent>
            <CardActions disableSpacing sx={{display: 'flex'}}>
                <IconButton aria-label="upvote" onClick={upvoted ? removeUpvote : submitUpvote}>
                    <ThumbUpIcon fontSize={desktop ? "medium" : "small"}
                                 sx={actionIconStyle}
                    />
                </IconButton>
                {/*
                <IconButton aria-label="subscribe">
                    <RssFeedIcon fontSize={desktop ? "medium" : "small"}
                                 sx={actionIconStyle}
                    />
                </IconButton>
                */}
                <WhatsappShareButton url={serverAddress + linkToQuestionPage}>
                    <WhatsappIcon size={desktop ? 25 : 18} round={true}/>
                </WhatsappShareButton>
                <TelegramShareButton url={serverAddress + linkToQuestionPage}>
                    <TelegramIcon size={desktop ? 25 : 18} round={true}/>
                </TelegramShareButton>
                <EmailShareButton url={serverAddress + linkToQuestionPage}>
                    <EmailIcon size={desktop ? 25 : 18} round={true}/>
                </EmailShareButton>
                <Typography variant="subtitle1" component="div"
                            sx={{fontSize: desktop ? '0.75rem' : '0.6rem', marginTop: '2px', marginLeft: '5px'}}>
                    {props.questionPreview && TimeHelper.getFriendlyTime(props.questionPreview.updatedAt)}
                </Typography>
                <Box sx={{flexGrow: 1}}/>
                {props.answerButton &&
                <Link to={`/postanswer/${question?.id || questionPreview?.id}`}
                      style={{textDecoration: 'none'}}>
                    <Button variant="contained" color="success" size={desktop ? "medium" : "small"}>
                        Answer
                    </Button>
                </Link>
                }

            </CardActions>

        </Card>
    </ThemeProvider>;
}
