import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, IconButton, useMediaQuery} from "@mui/material";
import {useRef} from "react";

import CameraAltIcon from '@mui/icons-material/CameraAlt';

import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ImagePreview from "./ImagePreview/ImagePreview";

// @ts-ignore
import ImageEditor from "@toast-ui/react-image-editor";

type PhotoCameraDialogProps =
    {
        children?: any,
        insertImage?: (imgSource : any) => void;
    };
/*
const imageEditorOptions = {
    // sort of option properties.
    includeUI:{
        //theme: myTheme,
        loadImage: '',
        menu: ['shape', 'filter', 'text', 'draw', 'crop', 'icon'],
        initMenu: 'filter',
        uiSize: {
            width: '1000px',
            height: '700px',
        },
        menuBarPosition: 'bottom',
    },
    cssMaxHeight:500,
    cssMaxWidth:700,
    selectionStyle:{
        cornerSize: 20,
        rotatingPointOffset: 70,
    },
};
*/

var mobileTheme = {
    "common.bi.image": "",
    "common.bisize.width": "0px",
    "common.bisize.height": "0px",
    // "common.backgroundImage": "./img/bg.png",
    "common.backgroundColor": "#fff",
    //"common.border": "1px solid #c1c1c1",

    // header
    "header.backgroundImage": "none",
    "header.backgroundColor": "transparent",
    "header.border": "0px",
    "header.display": "none",

    // load button
    "loadButton.backgroundColor": "#fff",
    "loadButton.border": "1px solid #ddd",
    "loadButton.color": "#222",
    "loadButton.fontFamily": "'Noto Sans', sans-serif",
    "loadButton.fontSize": "12px",
    "loadButton.marginLeft": "0px",

    // download button
    "downloadButton.backgroundColor": "#fdba3b",
    "downloadButton.border": "1px solid #fdba3b",
    "downloadButton.color": "#fff",
    "downloadButton.fontFamily": "'Noto Sans', sans-serif",
    "downloadButton.fontSize": "12px",

    // main icons
    "menu.normalIcon.color": "#8a8a8a",
    "menu.activeIcon.color": "#555555",
    "menu.disabledIcon.color": "#434343",
    "menu.hoverIcon.color": "#e9e9e9",
    "menu.iconSize.width": "24px",
    "menu.iconSize.height": "24px",

    // submenu icons
    "submenu.normalIcon.color": "#8a8a8a",
    "submenu.activeIcon.color": "#555555",
    "submenu.iconSize.width": "16px",
    "submenu.iconSize.height": "16px",

    // submenu primary color
    "submenu.backgroundColor": "transparent",
    "submenu.partition.color": "#e5e5e5",

    // submenu labels
    "submenu.normalLabel.color": "#858585",
    "submenu.normalLabel.fontWeight": "normal",
    "submenu.activeLabel.color": "#000",
    "submenu.activeLabel.fontWeight": "normal",

    // checkbox style
    "checkbox.border": "1px solid #ccc",
    "checkbox.backgroundColor": "#fff",

    // rango style
    "range.pointer.color": "#333",
    "range.bar.color": "#ccc",
    "range.subbar.color": "#606060",

    "range.disabledPointer.color": "#d3d3d3",
    "range.disabledBar.color": "rgba(85,85,85,0.06)",
    "range.disabledSubbar.color": "rgba(51,51,51,0.2)",

    "range.value.color": "#000",
    "range.value.fontWeight": "normal",
    "range.value.fontSize": "11px",
    "range.value.border": "0",
    "range.value.backgroundColor": "#f5f5f5",
    "range.title.color": "#000",
    "range.title.fontWeight": "lighter",

    // colorpicker style
    "colorpicker.button.border": "0px",
    "colorpicker.title.color": "#000"
};

interface imageEditorOptionsType {
    includeUI : any;
    cssMaxHeight: number;
    cssMaxWidth: number;
    selectionStyle: any;
}

const imageEditorMobileOptions = {
    // sort of option properties.
    includeUI:{
        loadImage: {
            path: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
            name: 'Blank'
        },
        //theme: myTheme,
        menu: ['shape', 'filter', 'text', 'draw', 'crop', 'icon'],
        //initMenu: 'filter',
        initMenu: "text",
        theme: mobileTheme,
        uiSize: {
            width: '320px',
            height: '500px',
        },
        menuBarPosition: 'bottom',
    },
    cssMaxHeight:500,
    cssMaxWidth:350,
    selectionStyle:{
        cornerSize: 20,
        rotatingPointOffset: 70,
    },
    usageStatistics: false
};
 

export default function PhotoCameraDialog(props : PhotoCameraDialogProps) {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    const [open, setOpen] = React.useState(false);
    const [dataUri, setDataUri] = React.useState('');
    const [imgHeight, setImgHeight] = React.useState(0);
    const [imgWidth, setImgWidth] = React.useState(0);
    const [imgEdit, setImgEdit] = React.useState(false);
    const editorRef = useRef<any | null>(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleEditClose = () => {
        if (props.insertImage && editorRef && editorRef.current) {
            const data = editorRef.current.imageEditorInst.toDataURL();
            props.insertImage(data);
        }
        setOpen(false);
        setDataUri('');
        setImgEdit(false);
    };

    const handleClose = () => {
        if (props.insertImage && dataUri) {
            props.insertImage(dataUri);
        }
        setOpen(false);
        setDataUri('');
        setImgEdit(false);
    };

    const handleClearAndClose = () => {
        setOpen(false);
        setDataUri('');
        setImgEdit(false);
    };
    
    const handleTakePhoto = (dataUri : string) => {
        // Do stuff with the photo...
        console.log('takePhoto');
    }

    const handleTakePhotoAnimationDone = (dataUri : string) => {
        // Do stuff with the photo...
        setDataUri(dataUri);
    }
    
    const handleRetake = () => {
        setDataUri('');
        setImgEdit(false);
    }
    
    const [imageEditorOptions, setImageEditorOptions] = React.useState<imageEditorOptionsType | null | undefined>(null);

    const handleEdit = () => {
        setImgEdit(true);
        
        if (dataUri) {

            const imgEditorOptions = {
                // sort of option properties.
                includeUI:{
                    //theme: myTheme,
                    loadImage: {path: dataUri, name: "sample"},
                    menu: ['shape', 'filter', 'text', 'draw', 'crop', 'icon'],
                    initMenu: 'filter',
                    uiSize: {
                        width: '1000px',
                        height: '700px',
                    },
                    menuBarPosition: 'bottom',
                },
                cssMaxHeight:500,
                cssMaxWidth:700,
                selectionStyle:{
                    cornerSize: 20,
                    rotatingPointOffset: 70,
                },
            };

            const imageEditorMobileOptions = {
                // sort of option properties.
                includeUI:{
                    loadImage: {path: dataUri, name: "sample"},
                    //theme: myTheme,
                    menu: ['shape', 'filter', 'text', 'draw', 'crop', 'icon'],
                    //initMenu: 'filter',
                    initMenu: "text",
                    theme: mobileTheme,
                    uiSize: {
                        width: window.innerWidth*0.8,
                        height: '500px',
                    },
                    menuBarPosition: 'bottom',
                },
                cssMaxHeight:500,
                cssMaxWidth:350,
                selectionStyle:{
                    cornerSize: 20,
                    rotatingPointOffset: 70,
                },
                usageStatistics: false
            };
            
            if (desktop) {
                setImageEditorOptions(imgEditorOptions);
            }
            else {
                setImageEditorOptions(imageEditorMobileOptions);
            }
        }
    }

    const isFullscreen = false;

    return (
        <>
            <IconButton color="default" aria-label="gesture-icon" onClick={handleClickOpen}>
                <CameraAltIcon/>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Take Photo</DialogTitle>
                <DialogContent sx={{padding: desktop ? '20px': '0px'}}>
                    {(imgEdit) &&
                        <ImageEditor
                            ref={editorRef}
                            {...imageEditorOptions}
                        /> 
                    }
                    {(dataUri && !imgEdit) &&
                        <ImagePreview dataUri={dataUri}
                                      isFullscreen={isFullscreen}
                        />}
                    {(!dataUri && !imgEdit) &&
                        <Camera
                            onTakePhoto={(dataUri) => {
                                handleTakePhoto(dataUri);
                            }}
                            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                            idealFacingMode={FACING_MODES.ENVIRONMENT}
                            isImageMirror={false}
                            //imageType = {IMAGE_TYPES.JPG}
                        />
                    }
                    
                </DialogContent>
                <DialogActions>
                    {dataUri &&
                        <Button onClick={handleEdit}>Edit</Button>}
                    {dataUri &&
                        <Button onClick={handleRetake}>Retake</Button>}
                    <Button onClick={handleClearAndClose}>Cancel</Button>
                    {dataUri && !imgEdit &&
                        <Button variant="contained" color="success" onClick={handleClose}>
                            Insert
                        </Button>
                    }
                    {dataUri && imgEdit &&
                        <Button variant="contained" color="success" onClick={handleEditClose}>
                            Insert-Edit
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
}