import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GestureIcon from '@mui/icons-material/Gesture';
import {Box, IconButton, useMediaQuery} from "@mui/material";
import CanvasDraw from "react-canvas-draw";
import {useRef} from "react";
import UndoIcon from '@mui/icons-material/Undo';
import ClearAllIcon from '@mui/icons-material/ClearAll';

type DrawingDialogProps =
    {
        children?: any,
        insertImage?: (imgSource : any, height : number, width : number) => void;
    };

export default function DrawingDialog(props : DrawingDialogProps) {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    const [open, setOpen] = React.useState(false);
    const canvasRef = useRef<any | null>(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (props.insertImage && canvasRef && canvasRef.current) {
            props.insertImage(canvasRef.current.canvasContainer.children[1].toDataURL(), 50, 50);
        }
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton color="default" aria-label="gesture-icon" onClick={handleClickOpen}>
                <GestureIcon/>
            </IconButton>
            <Dialog open={open} onClose={handleClose} fullWidth PaperProps={{sx:{width: desktop? '580px' : window.innerWidth*0.9, margin: desktop ? '32px' : '5px'} }} >
                <DialogTitle>Draw</DialogTitle>
                <DialogContent sx={{padding:'5px'}}>
                    <Box sx={{display:'flex', flexDirection:'row', }} >
                        <IconButton sx={{marginRight:'3px'}}
                            onClick={() => {
                                canvasRef.current.eraseAll();
                            }}
                        >
                            <ClearAllIcon/>
                        </IconButton>
                        <IconButton 
                            onClick={() => {
                                canvasRef.current.undo();
                            }}
                        >
                            <UndoIcon/>
                        </IconButton>

                    </Box>
                    <CanvasDraw
                        ref={canvasDraw => (canvasRef.current = canvasDraw)}
                        brushRadius={2}
                        lazyRadius={0}
                        canvasWidth= {mobile ? window.innerWidth * 0.9 : 570}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={handleClose}>
                        Insert
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}