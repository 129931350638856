import Moment from 'moment';

const getFriendlyTime = (time : string | undefined) => {
    const fiveDays = Moment().add(-5, 'days');

    if (Moment(time).isBefore(fiveDays)) {
        return "on " + Moment(time).format("MMM DD, YYYY");
    } else {
        return Moment(time).fromNow();
    }
};

export default {
    getFriendlyTime
}