import {
    Box,
    Card,
    Chip,
    useMediaQuery,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, {useContext, useEffect, useRef, useState} from "react";
import IconButton from "@mui/material/IconButton";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import ShareIcon from "@mui/icons-material/Share";
import CardActions from "@mui/material/CardActions";
// @ts-ignore
import ModalImage from "react-modal-image";

import { ThemeProvider, createTheme,  } from '@mui/material/styles';

import ThemedAvatarChips, {ThemedCard} from "./StylizedComponents";
import { PrimaryTheme } from './themes';
import {AnswerPreviewUserDto, AnswersService, OpenAPI, QuestionsService} from "../services/autogen";
import DOMPurify from "dompurify";

import TimeHelper from "../helpers/TimeHelper";
import {strip} from "../helpers/DomManipulator";
import {MathJaxBaseContext} from "better-react-mathjax";
import useHookWithRefCallback from "../hooks/useHookWithRefCallback";
import ShowMoreText from "react-show-more-text";
import {Link, useNavigate} from "react-router-dom";
import {getCloudinarySmallUrl} from "../helpers/DomManipulator";


import {WhatsappShareButton, 
        EmailShareButton,
        TelegramShareButton,
        WhatsappIcon, 
        TelegramIcon,
        EmailIcon} from "react-share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DescriptionImageThumbnails from "./DescriptionImageThumbnails";
import AnswerComments from "./AnswerComments";

type AnswerCardProps = {
    children?: React.ReactNode;
    theme?: React.ReactNode;
    answerPreview?: AnswerPreviewUserDto;
    isAnswerPreview?: boolean | undefined;
    isAnswerPage?: boolean;
}



export default function AnswerCard( props: AnswerCardProps ) {
    const answerContainerRef = useRef<any>(null);
    const navigate = useNavigate();
    const [answerWidth, setAnswerWidth] = useState<number>(570);
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    let answerPreview = props.answerPreview;
    const canEditAnswer = answerPreview?.isPublisher;
    const [answerMenuAnchorEl, setAnswerMenuAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(answerMenuAnchorEl);
    const handleOpenAnswerMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnswerMenuAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnswerMenuAnchorEl(null);
    };
    
    const onEditAnswerClicked = () => {
        handleClose();
        navigate(`/postanswer/${answerPreview?.questionId}/${answerPreview?.id}`);
    }
    
    const [answerLabels, setAnswerLabels] = useState<Array<string> | undefined>();

    const mjContext = useContext(MathJaxBaseContext);
    const [answerContentRef, setAnswerContentRef] = useHookWithRefCallback((current:any)=> {
        if (mjContext && current) {
            mjContext.promise.then((mathJax:any) => {
                mathJax.Hub.Queue(["Typeset", mathJax.Hub, current])
            });
        }
    }, []);

    useEffect(() => {
            setAnswerWidth(answerContainerRef.current?.offsetWidth - 30);
        },[]);

    let [upvoted, setUpvoted] = useState<boolean>(props.answerPreview?.isUpVoted || false);

    const submitUpvote = () => {
        async function upvote() : Promise<void> {
            let res = await AnswersService.postApiV1AnswersUpVoteAnswer({ answerId : props.answerPreview?.id || "" } || null );

            if (res) {
                setUpvoted(!upvoted);
            }
        }

        upvote();
    }

    let linkToAnswerPage = `/answers/${props.answerPreview?.id}`;
    let serverAddress = window.location.origin;

    return (
        <ThemeProvider theme={props.theme ? props.theme : PrimaryTheme}>
            <ThemedCard color="secondary" sx={{ borderRadius: 3, marginBottom: '22px', marginTop: props.isAnswerPage ? 'inherit' : '-17px', width:'95%'}}>
                <CardContent sx={{ display: 'flex', paddingBottom:0}}>
                    <Box sx={{flexShrink: 0}}>
                        <ThemedAvatarChips name={answerPreview?.publisher?.userName} color="primary"
                                           imgSrc={answerPreview?.publisher?.profilePicture!}
                                           colorOverride={PrimaryTheme.palette.common.white}
                                           userId={answerPreview?.publisher?.id}
                        />
                        {!props.isAnswerPreview &&
                            <Typography variant="subtitle2" component="span" align="center"
                                        sx={{display: 'block', fontSize: '0.75rem'}}>
                                Rating: 5800
                            </Typography>
                        }
                    </Box>
    
                    <Typography variant="h1" component="span" align="center" sx={{display: 'block', fontWeight: '600' , fontSize: '1.5rem', marginLeft:'5px'}}>
                        A
                    </Typography>
                    <Box sx={{display:'flex', justifyContent:'center'}}>
                        {desktop && answerLabels &&
                            <Box sx={{display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap'}}>
                                {["#Mechanics", "#Newton", "#Pulley"].map(d => (
                                    <Chip variant="outlined"
                                          label={d}
                                          size='small'
                                          sx={{marginLeft: 1}}
                                          key={d} // TODO: use their ID
                                    />
                                ))}
                            </Box>
                        }
                        {mobile && answerLabels &&
                            <Box sx={{display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap'}}>
                                {["#Mechanics", "#Newton", "#Pulley"].map(d => (
                                    <Chip variant="outlined"
                                          label={d}
                                          size='small'
                                          sx={{marginLeft: 1,
                                              '&.MuiChip-root': {
                                                  fontSize: '0.65rem',
                                                  height: '18px'
                                              }
                                          }}
                                          key={d} // TODO: use their ID
                                    />
                                ))}
                            </Box>
                        }

                    </Box>
                    <Box sx={{flex: 1}}/>
                    {canEditAnswer &&
                    <Box>
                        <IconButton aria-label="settings" sx={{alignItems: 'start', padding: '0px'}}
                                    aria-controls={open ? 'answer-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleOpenAnswerMenu}>
                            <MoreVertIcon/>
                        </IconButton>
                        <Menu
                            id="answer-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={answerMenuAnchorEl}
                            open={open}
                            onClose={handleClose}>
                            <MenuItem key="EditAnswer" onClick={onEditAnswerClicked}>Edit Answer</MenuItem>
                        </Menu>
                    </Box>
                    }
                </CardContent>
                <CardContent ref={answerContainerRef} sx={{padding: 1, fontSize: desktop ? "0.9rem" : "0.8rem"}}>
                    {
                        props.isAnswerPreview && answerPreview && answerPreview.firstImageUrl &&
                        <Box sx={{maxWidth: 100}}>
                            <ModalImage
                                small={getCloudinarySmallUrl(answerPreview.firstImageUrl)}
                                large={answerPreview.firstImageUrl}
                                alt="Preview"/>
                        </Box>
                    }
                    {
                        props.isAnswerPreview && answerPreview &&
                        <Link to={`answer/${answerPreview.id}`} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                            <ShowMoreText
                                /* Default options */
                                lines={5}
                                more={<Link to={`answer/${answerPreview.id}`}>Read More</Link>}
                                less="Read less"
                                className="content-css"
                                expanded={false}
                                width={answerWidth}
                                truncatedEndingComponent={"... "}
                            >
                                <Box>
                                        {strip(answerPreview?.content!)}
                                </Box>
                            </ShowMoreText>
                        </Link>
                        
                    }
                    {
                        !props.isAnswerPreview && answerPreview && answerPreview.id && 
                            <>
                                <Box ref={setAnswerContentRef}  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answerPreview.content!,
                                    {
                                        USE_PROFILES: {mathMl: true, svg: true, html: true},
                                        ADD_ATTR: ['linebreak']
                                    }
                                ) }}>
                                </Box>
                                <DescriptionImageThumbnails imageUrls={answerPreview.answerImages}/>
                                <AnswerComments 
                                    comments={answerPreview.comments || []} 
                                    answerId={answerPreview.id!}
                                    
                                ></AnswerComments>
                            </>
                    }
                    
                </CardContent>
                <CardActions disableSpacing sx={{display: 'flex'}}>
                    <IconButton aria-label="upvote" onClick={submitUpvote}>
                        <ThumbUpIcon fontSize= {desktop ? "small" : "small"}
                                     sx={{ marginTop: '-5px', '&.MuiSvgIcon-root': {fontSize: '1.2rem'}, color: upvoted ? PrimaryTheme.palette.primary.main : 'inherit'}}
                        />
                    </IconButton>
                    {/*
                    <IconButton aria-label="subscribe" >
                        <RssFeedIcon fontSize= {desktop ? "small" : "small"}
                                     sx={{ marginTop: '-5px', '&.MuiSvgIcon-root': {fontSize: '1.2rem'}}}
                        />
                    </IconButton>
                    */}
                    <WhatsappShareButton url={serverAddress + linkToAnswerPage}>
                        <WhatsappIcon size= {desktop ? 25 : 18} round={true}/>
                    </WhatsappShareButton>
                    <TelegramShareButton url={serverAddress + linkToAnswerPage}>
                        <TelegramIcon size= {desktop ? 25 : 18} round={true}/>
                    </TelegramShareButton>
                    <EmailShareButton url={serverAddress + linkToAnswerPage} >
                        <EmailIcon size= {desktop ? 25 : 18} round={true}/>
                    </EmailShareButton>
                    <Typography variant="subtitle1" component="div" sx={{ fontSize: desktop ? '0.75rem' : '0.6rem', marginTop: '2px', marginLeft: '5px' }}>
                        Answered {answerPreview && TimeHelper.getFriendlyTime(answerPreview.updatedAt)}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                </CardActions>
            </ThemedCard>
        </ThemeProvider>
    );
}
