import {useEffect, useState} from "react";
import {Link, Outlet} from "react-router-dom";
import {getQuestions, Question} from "../services/questionsService";


export default function Questions() {
    let [questions, setQuestions] = useState<Question[]>([]);
    useEffect(() => {
        async function foo() {
            let questionsFromService = await getQuestions();
            setQuestions(questionsFromService);
        }
        foo();
    }, []);
    
    return (
        <Outlet />
    );
}