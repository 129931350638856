import Box from "@mui/material/Box";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Checkbox,
    Container,
    Divider, FilledInput, FormControl, FormControlLabel, FormGroup, IconButton, Input, InputAdornment, InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, OutlinedInput, Paper, Rating, Stack,
    TextField, ToggleButton, useMediaQuery
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Button from "@mui/material/Button";

import { PrimaryTheme } from '../components/themes';

import {styled} from "@mui/material/styles";

import '../components/tagstyle.css';
import DrawingDialog from "../components/DrawingDialog";

import ImageEditorDialog from "../components/ImageEditorDialog";
import PhotoCameraDialog from "../components/PhotoCameraDialog";
import {AnswerDetailDto, AnswerDto, OpenAPI, QuestionDto} from "../services/autogen";
import {strip} from "../helpers/DomManipulator";
import {request as __request} from "../services/autogen/core/request";
import {useNavigate} from "react-router-dom";
import MathKeyboardDialog from "../components/MathKeyboardDialog";
import {
    create_file_picker_callback,
    desktopEditorAttributes,
    mobileEditorAttributes,
    TinyMceEditorWrapper
} from "./tinyMceCommon";

let theme = PrimaryTheme;

const suggestions = ["Maths", "Physics", "Chemistry", "Biology", "General Papers"].map(item => {
    return {
        id: item,
        text: item
    };
});

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#f5b63a',
    },
    '& .MuiRating-iconHover': {
        color: '#eac179',
    },
});

const labels: { [index: string]: string } = {
    1: '1 - 10 Coins',
    2: '11 - 20 Coins',
    3: '21 - 50 Coins',
    4: '51 - 100 Coins',
    5: '> 100 Coins',
};

function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

type PostAnswerPageProps = {
    question: QuestionDto;
    answer?: AnswerDetailDto 
}

export default function PostAnswerPage(props: PostAnswerPageProps) {
    let navigate = useNavigate();
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;

    const [loading, setLoading] = React.useState(false);

    const [initValue, setInitValue] = useState<string>("");
    useEffect(()=> {
        if(props.answer) {
            setInitValue(props.answer?.answer?.content!);
        }
    },[props.answer]);
    
    const [tags, setTags] = React.useState([{id:'General', text:'General'}]);
    
    type tagProps = {
        id: string,
        text: string,
    };

    const handleDelete = (i : number) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag : tagProps) => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag : tagProps, currPos: number, newPos: number) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const handleTagClick = (index: number) => {
        console.log('The tag at index ' + index + ' was clicked');
    };
    
    const editorRef: React.MutableRefObject<any> = useRef<any | null>(null);

    const insertImage = useCallback((source : any) => {
        console.log(editorRef.current);

        if (!!editorRef.current) {
            editorRef.current.insertContent('<img alt="canvas" src="' + source + '" style="max-width:100%" />');
        }
    }, []);

    
    interface InputState {
        amount: string;
    }

    const [values, setValues] = React.useState<InputState>({
        amount: '0',
    });

    const handleInputChange =
        (prop: keyof InputState) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    /*const insertEquation = useCallback((htmlContent) => {
        console.log(editorRef.current);

        if (!!editorRef.current) {
            editorRef.current.insertContent(htmlContent);
        }
    }, []);*/

    const insertEquation = useCallback((dataUrlImage, latex) => {
        console.log(editorRef.current);

        if (!!editorRef.current) {
            editorRef.current.insertContent(`<img data-latex="${latex}" src="${dataUrlImage}"/>`);
        }
    }, []);

    async function handleSubmit() {
        let formdata:Record<string,any> = {};
        let content = editorRef.current.getContent();
        formdata["content"] = content;
        if (content != null && content.trim() !== "") {
            formdata["contentText"] = strip(content);
        }
        formdata["preview"] = content; // TODO have a separate preview dialog
        formdata["price"] = 0; // TODO put the price tag here
        formdata["questionId"]  = props.question.id;
        formdata["answerType"] = "Answer";
        formdata["expiry"] = 1;
        formdata["minBudget"] =0;
        formdata["isPrivate"] = false;
        if (props.answer){
            formdata["answerId"] = props.answer.answer?.id;
            formdata["isEdit"] = true;
        }

        try {
            setLoading(true);
            if(props.answer) { // means it is an edit of existing answer
                await __request(OpenAPI, {
                    method: 'POST',
                    url: '/api/v1/answers',
                    formData: formdata,
                    mediaType: 'multipart/form-data',
                });
            } else{
                await __request(OpenAPI, {
                    method: 'POST',
                    url: '/api/v1/answers',
                    formData: formdata,
                    mediaType: 'multipart/form-data',
                });    
            }
            
            
            navigate(`/questions/${props.question.id}/${props.question.slugUrl}`);
        } catch(e){
            setLoading(false);
            console.log(e);
        }
        
    }

    return (

        <Box sx={{ width: '100%', height:'100%', }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', height:'100%' }}>
                <Box sx={{flexGrow: 1, height:'100%', display:'flex', justifyContent:'center'}}>
                    <Box sx={{width:'90%', display:'flex', flexDirection:'column' }}>
                        <Box sx={{ display:'flex', justifyContent: 'space-between'}}>
                            <Typography variant="subtitle1" component="span" align="center" sx={{display: 'block', fontWeight: '600', color: theme.palette.primary.main}}>
                                Your Answer
                            </Typography>
                            <Box>
                                <MathKeyboardDialog insertEquation={insertEquation}/>
                                <DrawingDialog insertImage={insertImage}/>
                                <ImageEditorDialog insertImage={insertImage} defaultImage={props.question?.firstImageUrl}/>
                                <PhotoCameraDialog insertImage={insertImage}/>
                            </Box>
                        </Box>
                        {desktop &&
                            <TinyMceEditorWrapper
                                editorRef={editorRef}
                                initValue={initValue}
                                placeholder="Answer Description ..."
                                otherAttributes={desktopEditorAttributes}
                            />
                        }
                        {mobile &&
                            <TinyMceEditorWrapper
                                editorRef={editorRef}
                                initValue={initValue}
                                placeholder="Answer Description ..."
                                otherAttributes={mobileEditorAttributes}
                            />
                        }
                        {/* TODO: disable upload file for now
                        <FileDropzone/>
                        */}

                        { /* TODO: disable tag for now
                        <Box sx={{ marginTop: '5px', display:'flex'}}>
                            <Typography variant="subtitle1" component="span" align="center" sx={{display: 'block', fontWeight: '600', color: theme.palette.primary.main}}>
                                Tags
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', width: '100%'}}>
                            <ReactTags
                                tags={tags}
                                suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleDelete}
                                handleAddition={handleAddition}
                                handleDrag={handleDrag}
                                handleTagClick={handleTagClick}
                                inputFieldPosition="inline"
                                autocomplete
                            />
                        </Box>
                        */}
                        {/* TODO: allow price setting for answers
                        <Box sx={{marginTop: '20px'}}>
                            <Divider />
                            <Box sx={{display:'flex', flexDirection:"row", marginTop:'10px', justifyContent:'space-between', alignItems:'center'}}>
                                <Box sx={{display:'flex', flexDirection:"row", }}>
                                    <Card sx={{border: "none", boxShadow: "none", backgroundColor: theme.palette.background.default}}>
                                        <CardMedia component="img" alt="moneypng" image="/images/money.png" height="30px" sx={{backgroundColor: theme.palette.background.default}}/>
                                    </Card>
                                    <Typography component="span" align="left" sx={{fontSize: desktop ? '1rem' : '0.8rem', marginLeft:'5px', display: 'block', fontWeight: '600', color: theme.palette.primary.main}}>
                                        Set a price for your answer
                                    </Typography>
                                </Box>
                                <Box sx={{display:'flex', justifyContent:'center'}}>
                                    <FormControl sx={{ m: 1, width: '8ch'  }} >
                                        <TextField
                                            id="filled-adornment-amount"
                                            size="small"
                                            value={values.amount}
                                            label="Price"
                                            onChange={handleInputChange('amount')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MonetizationOnOutlinedIcon fontSize="small" color="secondary"/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                        */}
                        <Button variant="contained" color="success" sx={{marginTop: '20px'}}
                                disabled={loading}
                                onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>

    );
}