import React from "react";
import Moment from 'moment';
import {UserDto} from "../models/userDto";
import Stack from "@mui/material/Stack";
import {Link, useMediaQuery} from "@mui/material";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import AlarmOutlinedIcon from "@mui/icons-material/AlarmOutlined";
import {QuestionPreviewDto} from "../services/autogen";

type CoinAndClockProps =
    {
        questionPreview?: QuestionPreviewDto;
    };


export default function CoinAndClock(props: CoinAndClockProps) {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    const renderCoins = (question : QuestionPreviewDto | undefined) => {
        if (question) {
            let coins: any[] = [];

            if (question.maxBudget == null) {
                coins = [1, 2, 3, 4, 5];
            } else if (question.maxBudget == 100) {
                coins = [1, 2, 3, 4];
            } else if (question.maxBudget == 50) {
                coins = [1, 2, 3];
            } else if (question.maxBudget == 20) {
                coins = [1, 2];
            } else if (question.maxBudget == 10) {
                coins = [1];
            }

            return coins.map((i, index) => {
                return (
                    <MonetizationOnOutlinedIcon key={index} color="secondary" fontSize= {desktop ? "medium" : "small"}/>
                );
            });
        } else {
            return null;
        }
    }

    const renderClocks = (question : QuestionPreviewDto | undefined) => {
        if (question && !question.isTenderExpired) {
            
            /*
            let clockColor = colors.primaryBlue;
            const timeDiff = Moment(question.tenderExpiry).diff(Moment(), 'minute');

            if (timeDiff > 24 * 60) {
                clockColor = colors.secondaryPurple;
            } else if (timeDiff > 6 * 60) {
                clockColor = colors.primaryBlue;
            } else if (timeDiff > 60) {
                clockColor = colors.primaryGreen;
            } else if (timeDiff > 15) {
                clockColor = colors.primaryPink;
            } else if (timeDiff > 0) {
                clockColor = colors.primaryRed;
            }
            
             */

            return (
                <AlarmOutlinedIcon color="warning" fontSize= {desktop ? "medium" : "small"}/>
            );
        } else {
            return null;
        }
    }

    return (
        <>
            {renderCoins(props.questionPreview)}
            {renderClocks(props.questionPreview)}
        </>
    );
}