const baseUrl = ""; // proxied in package.json
//const baseUrl = "http://192.168.43.229:3000";
//const baseUrl = "http://192.168.86.244:3000";
//const baseUrl = "https://mobilebackend-beta.synapzter.com:3300";

// User
export const API_LOGIN = baseUrl + "/api/v1/users/login";
export const API_RESET_PASSWORD = baseUrl + "/api/v1/users/ResetPassword";
export const API_REGISTER = baseUrl + "/api/v1/users/register";
export const API_LOGIN_GOOGLE = baseUrl + "/api/v1/users/LoginWithGoogle";
export const API_LOGIN_FACEBOOK = baseUrl + "/api/v1/users/LoginWithFacebook";
export const API_LOGIN_APPLE = baseUrl + "/api/v1/users/LoginWithApple";
export const API_UPLOAD_PROFILE_PICTURE = baseUrl + "/api/v1/users/UploadProfilePicture";
export const API_GET_PROFILE = baseUrl + "/api/v1/users/GetProfile";
export const API_GET_SETTING = baseUrl + "/api/v1/users/GetSetting";
export const API_GET_PUBLIC_PROFILE = baseUrl + "/api/v1/users/GetPublicProfile";
export const API_CREATE_OR_UPDATE_PROFILE = baseUrl + "/api/v1/users/CreateOrUpdateProfile";
export const API_CREATE_OR_UPDATE_SETTING = baseUrl + "/api/v1/users/CreateOrUpdateSetting";
export const API_SEARCH_USER = baseUrl + "/api/v1/users/SearchUser";
export const API_GET_RATING_STATS = baseUrl + "/api/v1/users/GetRatingStats";
export const API_SET_USER_DISPLAY_SETTING = baseUrl + "/api/v1/users/UpdateDisplaySetting";
export const API_SET_USERNAME = baseUrl + "/api/v1/users/UpdateUsername";

// Gifts
export const API_REDEEM_REWARD = baseUrl + "/api/v1/gifts/RedeemGift";
export const API_GET_REWARDS = baseUrl + "/api/v1/gifts/GetAvailableGifts";

// Group
export const API_UPLOAD_GROUP_PICTURE = baseUrl + "/api/v1/groups/UploadGroupPicture";
export const API_CREATE_OR_UPDATE_GROUP = baseUrl + "/api/v1/groups/CreateOrUpdateGroup";
export const API_GET_GROUP_DETAILS = baseUrl + "/api/v1/groups/GetGroupDetails";
export const API_GET_GROUP_MEMBERS = baseUrl + '/api/v1/groups/GetGroupMembers';
export const API_GET_PENDING_GROUP_JOIN_REQUEST = baseUrl + '/api/v1/groups/GetPendingGroupJoinRequests';
export const API_JOIN_GROUP = baseUrl + '/api/v1/groups/JoinGroup';
export const API_DISABLE_GROUP = baseUrl + '/api/v1/groups/DisableGroup';
export const API_EXIT_GROUP = baseUrl + '/api/v1/groups/ExitGroup';
export const API_GET_MY_GROUPS = baseUrl + '/api/v1/groups/GetMyGroups';
export const API_REJECT_JOIN_REQUEST = baseUrl + '/api/v1/groups/RejectGroupJoinRequests';
export const API_APPROVE_JOIN_REQUEST = baseUrl + '/api/v1/groups/ApproveGroupJoinRequests';
export const API_REMOVE_USER_FROM_GROUP = baseUrl + '/api/v1/groups/RemoveFromGroup';

// Version
export const API_GET_APP_MINIMUM_VERSION = baseUrl + '/api/v1/version/GetMinimumVersion';

// Block
export const API_BLOCK_USER = baseUrl + "/api/v1/users/BlockUser";
export const API_UNBLOCK_USER = baseUrl + "/api/v1/users/UnblockUser";
export const API_ISBLOCKED_USER = baseUrl + "/api/v1/users/IsUserBlocked";

// Leaderboard
export const API_GET_RANK = baseUrl + "/api/v1/rank/GetRank";

// RewardScore
export const API_GET_REWARDSCORE = baseUrl + "/api/v1/users/GetUserScore";

// Topic
export const API_GET_TOPICS = baseUrl + "/api/v1/topics";

// Config
export const API_GET_CONFIG_SETTINGS = baseUrl + "/api/v1/config/GetConfigSettings";

// Bookmark
export const API_ADD_BOOKMARK = baseUrl + "/api/v1/bookmarks";
export const API_REMOVE_BOOKMARK = baseUrl + "/api/v1/bookmarks";
export const API_GET_BOOKMARKS = baseUrl + "/api/v1/bookmarks";
export const API_GET_BOOKMARKS_ALL = baseUrl + "/api/v1/bookmarks/GetBookmarksAll";

// Announcement
export const API_GET_ANNOUNCEMENT = baseUrl + "/api/v1/announcements/GetAnnouncements";
export const API_MARK_ANNOUNCEMENT_AS_READ = baseUrl + "/api/v1/Announcements/MarkAnnouncementAsRead";

// Question
export const API_POST_QUESTION = baseUrl + "/api/v1/questions";
export const API_DELETE_QUESTION = baseUrl + "/api/v1/questions";
export const API_EDIT_QUESTION = baseUrl + "/api/v1/questions/EditQuestion";
export const API_GET_TOP_QUESTIONS = baseUrl + "/api/v1/questions/GetQuestions";
export const API_GET_MY_QUESTIONS = baseUrl + "/api/v1/questions/GetMyQuestions";
export const API_GET_INVITED_USERS = baseUrl + "/api/v1/questions/GetInvitedUsers";
export const API_MOVE_TO_PAST = baseUrl + "/api/v1/questions/MoveToPast";
export const API_EXTEND_TENDER = baseUrl + "/api/v1/questions/ExtendTender";
export const API_GET_QUESTION_BY_ID = baseUrl + "/api/v1/questions/";
export const API_UPLOAD_QUESTION_ATTACHMENT = baseUrl + "/api/v1/questions/UploadAttachment";
export const API_GET_QUESTION_BY_ANSWERID = baseUrl + "/api/v1/questions/GetQuestionByAnswerId";
export const API_UPDATE_VIEW_COUNT = baseUrl + "/api/v1/questions/UpdateViewCount";
export const API_CREATE_PRIVATE_QUESTION = baseUrl + "/api/v1/questions/CreatePrivateQuestion";
export const API_UPDATE_PRIVATE_QUESTION = baseUrl + "/api/v1/questions/UpdatePrivateQuestion";
export const API_GET_ACTIVITIES_FOR_PROFILE = baseUrl + "/api/v1/questions/GetActivitiesForProfile";
export const API_CHECK_QUESTION_UPDATES = baseUrl + "/api/v1/questions/CheckQuestionUpdates";
export const API_GET_LAST_QUESTION_ACTIVITY_TIME = baseUrl + "/api/v1/questions/GetLastQuestionActivityTime";
export const API_UPVOTE_QUESTION = baseUrl + "/api/v1/questions/UpVoteQuestion";
export const API_REMOVE_QUESTION_UPVOTE = baseUrl + "/api/v1/questions/RemoveUpVoteQuestion";
export const API_MARK_QUESTION_AS_READ = baseUrl + "/api/v1/questions/MarkQuestionAsRead";
export const API_GET_QUESTIONS_IN_GROUP = baseUrl + "/api/v1/questions/GetQuestionsInGroup";

// Answer
export const API_POST_ANSWER = baseUrl + "/api/v1/answers";
export const API_DELETE_ANSWER = baseUrl + "/api/v1/answers";
export const API_GET_ANSWER_FOR_QUESTION = baseUrl + "/api/v1/answers";
export const API_GET_MY_ANSWERS = baseUrl + "/api/v1/answers/GetMyAnswers";
export const API_UPLOAD_ANSWER_ATTACHMENT = baseUrl + "/api/v1/answers/UploadAttachment";
export const API_GET_MY_UNPAID_ANSWER_FOR_BUYER = baseUrl + "/api/v1/answers/GetMyUnPaidAnswersForBuyer";
export const API_GET_MY_TENDERS_FOR_BUYER = baseUrl + "/api/v1/answers/GetMyAvailableTendersForBuyer";
export const API_MARK_TENDER_AS_COMPLETE = baseUrl + "/api/v1/answers/MarkAsComplete";
export const API_COMPLETE_TENDER = baseUrl + "/api/v1/answers/CompleteTender";
export const API_GET_ANSWER_DETAIL = baseUrl + "/api/v1/answers/GetAnswerDetail";
export const API_GET_USER_ANSWER_FOR_QUESTION = baseUrl + "/api/v1/answers/GetUserAnswerForQuestion";
export const API_GET_ANSWER_REVIEWS = baseUrl + "/api/v1/answers/GetAnswerReviews";
export const API_WRITE_ANSWER_REVIEW = baseUrl + "/api/v1/answers/WriteAnswerReview";
export const API_WRITE_ANSWER_COMMENT = baseUrl + "/api/v1/answers/CreateComment";
export const API_GET_ANSWER_COMMENTS = baseUrl + "/api/v1/answers/GetAnswerComments";
export const API_UPVOTE_ANSWER_COMMENT = baseUrl + "/api/v1/answers/UpVoteAnswerComment";
export const API_DOWNVOTE_ANSWER_COMMENT = baseUrl + "/api/v1/answers/DownVoteAnswerComment";
export const API_UPVOTE_ANSWER = baseUrl + "/api/v1/answers/UpVoteAnswer";
export const API_DOWNVOTE_ANSWER = baseUrl + "/api/v1/answers/DownVoteAnswer";
export const API_GET_REVIEWS_FOR_PROFILE = baseUrl + "/api/v1/answers/GetReviewsForProfile";
export const API_REMOVE_COMMENT = baseUrl + "/api/v1/answers/RemoveComment";

// Payment
export const API_PAY_ANSWER = baseUrl + "/api/v1/payments/PayAnswer";
export const API_PAY_TENDER = baseUrl + "/api/v1/payments/PayTender";
export const API_GET_TRANSACTIONS = baseUrl + "/api/v1/payments/GetTransactionsForUser";
export const API_GET_WALLET = baseUrl + "/api/v1/payments/GetWalletForUser";
export const API_TOP_UP_WALLET = baseUrl + "/api/v1/payments/TopUp";
export const API_TERMINATE_TRANSACTION_REQUEST = baseUrl + "/api/v1/payments/CreateTransactionCancellation";
export const API_REJECT_TRANSACTION_TERMINATION = baseUrl + "/api/v1/payments/RejectTransactionCancellation";
export const API_APPROVE_TRANSACTION_TERMINATION = baseUrl + "/api/v1/payments/ApproveTransactionCancellation";

// Chat
export const API_GET_CHAT_DIALOGS = baseUrl + "/api/v1/chat/GetDialogsForUser";
export const API_IS_CHAT_ALLOWED = baseUrl + "/api/v1/chat/IsChatAllowed";
export const API_GET_USER_BY_CONNECTYCUBEID = baseUrl + "/api/v1/chat/GetUserByConnectyCubeId";
export const API_CREATE_CHAT_PAYMENT_REQUEST = baseUrl + "/api/v1/chat/CreateChatPaymentRequest";
export const API_GET_LAST_CHAT_PAYMENT_REQUEST = baseUrl + "/api/v1/chat/GetLastChatPaymentRequest";
export const API_CREATE_CHAT_TENDER = baseUrl + "/api/v1/chat/CreateChatTender";
export const API_GET_LAST_CHAT_TENDER = baseUrl + "/api/v1/chat/GetLastChatTender";
export const API_CREATE_CHAT_ANSWER = baseUrl + "/api/v1/chat/CreateChatAnswer";
export const API_GET_LAST_ANSWER_FOR_CHAT = baseUrl + "/api/v1/chat/GetLastAnswerForChat";
export const API_UPDATE_CHAT_VISIT_TIME = baseUrl + "/api/v1/chat/UpdateChatVisitTime";

// Offer
export const API_CREATE_OFFER = baseUrl + '/api/v1/offers';
export const API_ACCEPT_OFFER = baseUrl + '/api/v1/offers/AcceptOffer';
export const API_REJECT_OFFER = baseUrl + '/api/v1/offers/RejectOffer';

// Alert
export const API_GET_ALERTS = baseUrl + '/api/v1/alerts';
export const API_READ_ALERT = baseUrl + '/api/v1/alerts/ReadAlert';
export const API_ARCHIVE_ALERT = baseUrl + '/api/v1/alerts/ArchiveAlert';
export const API_UPDATE_FIREBASE_TOKEN = baseUrl + '/api/v1/alerts/UpdateFirebaseToken';
export const API_DELETE_FIREBASE_TOKEN = baseUrl + '/api/v1/alerts/DeleteFirebaseToken';
export const API_SEND_CHAT_ALERT = baseUrl + '/api/v1/alerts/SendChatAlert';
export const API_UPDATE_ALERT_VISIT_TIME = baseUrl + '/api/v1/alerts/UpdateAlertVisitTime';
export const API_GET_NO_ALERTS_SINCE_LAST_VISIT = baseUrl + '/api/v1/alerts/GetNoAlertsSinceLastVisit'

// Search
export const API_GLOBAL_SEARCH = baseUrl + '/api/v1/search/GlobalSearch';
export const API_SEARCH_AUTO_COMPLETE = baseUrl + '/api/v1/search/AutoComplete';
export const API_GET_RELATED_QUESTIONS = baseUrl + '/api/v1/search/GetRelatedQuestions';
export const API_AUTO_COMPLETE_GROUP_NAME = baseUrl + '/api/v1/search/AutoCompleteGroupName';
export const API_SEARCH_GROUPS_BY_NAME = baseUrl + '/api/v1/search/SearchGroupsByName';

// Contact
export const API_CONTACT_US = baseUrl + '/api/v1/contact/ContactUs';
export const API_REPORT_TO_US = baseUrl + '/api/v1/contact/ReportUs';