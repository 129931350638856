import {Oval} from "react-loader-spinner";
import React from "react";

export function LoadingCircle() {
    return <Oval
        height={35}
        width={35}
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#4fa94d"
        strokeWidth={2}
        strokeWidthSecondary={2}

    />
} 