import {Link, useNavigate} from "react-router-dom";
import logo from "../logo.svg";
import React from "react";

export default function Topics() {
    var navigate = useNavigate();
    function navigateToQ1() {
        navigate("/questions/1");
    }

    return (
        <div style={{ padding: "1rem 0" }}>
            <h2>Maths</h2>
            <h2>Physics</h2>
            <button onClick={navigateToQ1}>
                Navigate to question 1
            </button>
        </div>
    );
}