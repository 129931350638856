import * as React from 'react';
import {Box, Button, Divider, useMediaQuery} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { PrimaryTheme } from './themes';
import {useEffect, useState} from "react";
import {
    AlertDto,
    AlertListDto,
    AlertsService,
    AnswerDetailDto,
    AnswersService,
    OpenAPI,
    PreviewUserDto
} from "../services/autogen";
import {useAuth} from "../contexts/AuthContext";
import {bool} from "prop-types";
import TimeHelper from "../helpers/TimeHelper";

type NotificationItemProps = {
    children?: React.ReactNode;
    theme?: React.ReactNode;
    data?: AlertDto;
}

let theme = PrimaryTheme;


function GetMsgBySubject(content: string, subjectOnLeft: boolean, alert : AlertDto, sender?: PreviewUserDto ) {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;
    
    return (
        <>
            <Box sx={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                {subjectOnLeft &&
                    <Typography sx={{
                        fontSize: desktop ? '0.85rem' : '0.8rem',
                        fontWeight: '600',
                        color: theme.palette.primary.main,
                        marginLeft: '3px'
                    }}>
                        {sender?.userName || "anonymous"}
                    </Typography>
                }
                <Typography sx={{
                    fontSize: desktop ? '0.85rem' : '0.8rem',
                    fontWeight: alert.isRead ? '300' : '600',
                    color: theme.palette.primary.main,
                    marginLeft: '3px'
                }}>
                    {content}
                </Typography>
                {!subjectOnLeft &&
                    <Typography sx={{
                        fontSize: desktop ? '0.85rem' : '0.8rem',
                        fontWeight: '600',
                        color: theme.palette.primary.main,
                        marginLeft: '3px'
                    }}>
                        {sender?.userName || "anonymous"}
                    </Typography>
                }
    
            </Box>
        </>
    );
}

function getNotification(alert?: AlertDto) {
    if (!alert) {
        return <></>
    }
    
    let sender = alert.sender;

    switch (alert.subject) {
        case 'Payment Made':
            return GetMsgBySubject("You have made a payment to", false, alert, sender);
        case 'Payment Received':
            return GetMsgBySubject("You have received an payment from", false, alert, sender);
        case 'Answer Received':
            return GetMsgBySubject("You have received an answer from", false, alert, sender);
        case 'Tender Received':
            return GetMsgBySubject("Your receive a tender for your question from", false, alert, sender);
        case 'Tender Accepted':
            return GetMsgBySubject("Your tender is accepted by", false, alert, sender);
        case 'Delivery Received':
            return GetMsgBySubject("You have received a delivery for your question from", false, alert, sender);
        case 'Transaction Completed':
            return GetMsgBySubject("Your transaction with", false, alert, sender);
        case 'Comment Received':
            return GetMsgBySubject("has posted a comment", true, alert, sender);
        case 'Membership Rejection':
            return GetMsgBySubject("has rejected your request to join", true, alert, sender);
        case 'Membership Approval':
            return GetMsgBySubject("has approved your request to join", true, alert, sender);
        case 'Membership Request':
            return GetMsgBySubject("has requested to join", true, alert, sender);
        case 'New Group Question':
            return GetMsgBySubject("has posted a question in", true, alert, sender);
        case 'New Group Post':
            return GetMsgBySubject("has created a post in", true, alert, sender);
        case 'New Answer':
            return GetMsgBySubject("has posted an answer to a bookmarked question", true, alert, sender);
        case 'New Comment':
            return GetMsgBySubject("has posted a comment to a bookmarked answer", true, alert, sender);
        default:
            return GetMsgBySubject(alert.subject || "Testing", false, alert, sender);
        
    }
    
}

export default function NotificationItem( props: NotificationItemProps ) {
    const mobile = !useMediaQuery('(min-width:600px)');
    const desktop = !mobile;

    const {currentUser} = useAuth();
    
    return (
        <Box sx={{width: 250, marginTop: desktop ? '5px' : '0px', id: 'test'}}>
            <Box sx={{width: '100%', display: 'flex', flexDirection: 'row', marginBottom: '5px', alignItems: 'center'}}>
                <Avatar alt="avatarimg" src={props.data?.sender?.profilePicture || ""} /*"/images/hans.jpg"*/ sx={{
                    '&.MuiAvatar-root': {
                        '&.MuiAvatar-circular': {
                            width: desktop ? '50px' : '35px',
                            height: desktop ? '50px' : '35px',
                            marginRight: '2px',
                        }
                    }
                }}/>
                <Box sx={{marginLeft: '2px',}}>
                    {getNotification(props.data)}
                    {/*
                    <Box sx={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        <Typography sx={{
                            fontSize: desktop ? '0.85rem' : '0.8rem',
                            fontWeight: '600',
                            color: theme.palette.primary.main
                        }}>
                            user1
                        </Typography>
                        <Typography sx={{
                            fontSize: desktop ? '0.85rem' : '0.8rem',
                            color: theme.palette.primary.main,
                            marginLeft: '3px'
                        }}>
                            has sent you an answer
                        </Typography>

                    </Box>
                    */}
                    <Box sx={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start'}}>
                        {/*<Button variant="text" color="success" sx={{fontSize:'0.75rem'}}>View Answer</Button>*/}
                        <Typography variant="subtitle1" component="div" sx={{
                            fontSize: desktop ? '0.75rem' : '0.7rem',
                            marginTop: '2px',
                            marginLeft: '5px'
                        }}>
                            {TimeHelper.getFriendlyTime(props?.data?.createdAt)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Divider/>
        </Box>
        
    );
}