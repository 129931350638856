import React, {useState} from "react";
import {
    FormControl,
    Grid,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import {MetadataHierarchy} from "../services/metadata/tuitionCenter1";
import {groupBy, sortBy, toArray, uniqBy} from "lodash";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

interface MetadataFilter{
    Type: string,
    Members: MetadataHierarchy[]
}

type ValueChangedCallback = (Type: string, Name: string|null) => void;

export function constructMetadataFilter(hierarchy: MetadataHierarchy[]): MetadataFilter[] {
    let elementsOnHierarchyLevel = [...hierarchy];
    let finalConsolidated = [];
    while (elementsOnHierarchyLevel.length > 0) {
        const metadataHierarchy = elementsOnHierarchyLevel.pop();
        finalConsolidated.push(metadataHierarchy as MetadataHierarchy);
        if (metadataHierarchy?.Children !== null){
            elementsOnHierarchyLevel.push(...metadataHierarchy?.Children!)
        }
    }
    let hierarchyFilter = groupBy(finalConsolidated, fc => fc.Type);
    return Object.keys(hierarchyFilter).map(function(key) {
        let items = toArray(uniqBy(hierarchyFilter[key], "Name"));
        if (items.length > 10){
            items = sortBy(items, 'Name');
        }
        return {
            Type: key,
            Members:items,
                //[({Name: 'All', Type: key, Children: null} as MetadataHierarchy)].concat(items)
        };
    });
}


export function MetadataFilterSelector(props: {metadataFilter: MetadataFilter, onSelectedValueChange: ValueChangedCallback}){
    const mf = props.metadataFilter;
    const cb = props.onSelectedValueChange;
    return <Autocomplete
        disablePortal
        options={mf.Members.map(m => m.Name)}
        sx={{ width: '100%', mt: 1 }}
        renderInput={(params) => <TextField {...params} label={`Filter by ${mf.Type}`} placeholder={`All ${mf.Type}`}/>}
        onChange={(event, value) => cb(mf.Type, value)}
        defaultValue={null}
    />
}
