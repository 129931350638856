import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CreateIcon from "@mui/icons-material/Create";
import {ProfileDto, UpdateProfileRequest, UserDto, UsersService} from "../services/autogen";
import {WithContext as ReactTags} from "react-tag-input";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";


type tagProps = {
    id: string,
    text: string,
};

const suggestions = ["Maths", "Physics", "Chemistry", "Biology", "General Papers"].map(item => {
    return {
        id: item,
        text: item
    };
});

const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];



const handleTagClick = (index: number) => {
    console.log('The tag at index ' + index + ' was clicked');
};

interface EditUserProfileDialogProps {
    profile?: ProfileDto;
    currentUser?: UserDto;
    saveFunc?: any;
}

export default function EditUserProfileDialog(props: EditUserProfileDialogProps) {
    const [open, setOpen] = React.useState(false);
    //const [tags, setTags] = useState([{id:'Physics', text:'Physics'}]);
    const [tags, setTags] = useState<Array<tagProps>>([]);
    const [save, setSave] = useState<boolean>(false);
    
    // field entries
    const [userName, setUsername] = useState<string>("");
    const [expertise, setExpertise] = useState<Array<string>>([]);
    const [fullName, setFullName] = useState<string>("");
    const [occupation, setOccupation] = useState<string>("");
    const [qualification, setQualification] = useState<string>("");
    const [education, setEducation] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    
    
    const loadInitTags = (expertiseList: string[] ) => {
        let tagList : tagProps[] = [];
        expertiseList.map(item => {
            let tag = {id: item, text: item}
            tagList = tagList.concat(tag);
        });
        setTags(tagList);
    };
    
    const handleDelete = (i : number) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag : tagProps) => {
        if (tags.length <= 5) {
            setTags([...tags, tag]);
        }
    };

    const handleDrag = (tag : tagProps, currPos: number, newPos: number) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const onValidate= (tag : string) => {
        return (tag.length <= 10);
    }
    
    let {currentUser, profile} = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        console.log(tags);
    };
    
    
    function saveProfile() {
        //let profile = props.profile;
        let user = props.currentUser;
        
        let expertises = tags.map(t => t.id!);
        
        // create the request
        if (profile && user) {
            let request : UpdateProfileRequest = {
                userId: user.id,
                userName: userName,
                location: location,
                realName: fullName,
                occupation: occupation,
                qualification: qualification,
                education: education,
                description: description,
                expertise: expertises,
                topic: null
            };
            if (profile.topic && profile.topic.length > 0) {
                request.topic = profile.topic.map(t => (t.id!));
            }
            
            UsersService.postApiV1UsersCreateOrUpdateProfile(request).then((response) => 
            {
                if (props.saveFunc) {
                    props.saveFunc(true);
                }
            });
        }
        
    }

    const handleSave = () => {
        //TODO: save the contents of the updated profile
        console.log(tags);
        saveProfile();
        setOpen(false);
    };
    
    useEffect(()=> {
        if (currentUser && profile) {
            if (profile.expertise) {
                loadInitTags(profile.expertise);
            }
            setUsername(currentUser.userName || "");
            setFullName(profile.realName || "");
            setEducation(profile.education || "");
            setExpertise(profile.expertise || []);
            setDescription(profile.description || "");
            setLocation(profile.location || "");
            setOccupation(profile.occupation || "");
        }
        
    },[profile]);
    

    return (
        <>
            <Button variant="outlined" startIcon={<CreateIcon></CreateIcon>} onClick={handleClickOpen}>
                Edit Profile
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Profile</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        autoFocus
                        id="name"
                        label="Username"
                        type="text"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        defaultValue={currentUser?.userName}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        id="fullName"
                        label="Full Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        defaultValue={profile?.realName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        id="education"
                        label="Education"
                        type="text"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        defaultValue={profile?.education}
                        onChange={(e) => setEducation(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        id="occupation"
                        label="Occupation"
                        type="text"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        defaultValue={profile?.occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        id="location"
                        label="Location"
                        type="text"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        defaultValue={profile?.location}
                        onChange={(e) => setLocation(e.target.value)}
                    />
                    {/*
                    <TextField
                        autoFocus
                        id="expertise"
                        label="Subject Expertise"
                        type="text"
                        fullWidth
                        variant="standard"
                        margin="normal"
                    />*/}
                    <Box sx={{ marginTop: '10px', display:'flex'}}>
                        <Typography component="span" align="center" sx={{fontSize: '0.7rem', display: 'block', fontWeight: '300', /*color: theme.palette.primary.main*/}}>
                            Expertise
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', width: '100%'}}>
                        <ReactTags
                            tags={tags}
                            //suggestions={suggestions}
                            delimiters={delimiters}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            handleDrag={handleDrag}
                            handleTagClick={handleTagClick}
                            inputFieldPosition="inline"
                            autocomplete
                            maxLength={10}
                            allowUnique={true}
                            placeholder="Add new tag & enter (max 5)"
                        />
                    </Box>
                    <TextField
                        autoFocus
                        id="description"
                        label="Description of yourself"
                        type="text"
                        fullWidth
                        multiline
                        variant="standard"
                        margin="normal"
                        defaultValue={profile?.description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={handleSave}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}