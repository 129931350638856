import {Box, Divider, Stack, Typography} from "@mui/material";
import {getCloudinarySmallUrl} from "../helpers/DomManipulator";
import React from "react";
import { PrimaryTheme, PrimaryCardTheme } from './themes';

// @ts-ignore
import ModalImage from "react-modal-image";

function DescriptionImageThumbnails(props: { imageUrls: string[] | null | undefined}) {
    const {imageUrls} = props;
    if (!imageUrls || imageUrls.length == 0) {
        return <></>;
    }

    return <>
            <Divider textAlign="left"><Typography variant="caption">Images</Typography></Divider>
            <Stack direction="row" spacing={1} sx={{pt: 1}}>
                {
                    imageUrls.map(ai =>
                        !ai?.endsWith(".svg") &&
                        <Box sx={{width: 100, height: 60, overflow: "hidden"}}>
                            <ModalImage
                                small={getCloudinarySmallUrl(ai)}
                                large={ai}
                                alt="Preview"/>
                        </Box>
                    )
                }
            </Stack>
        </>         ;
};

export default DescriptionImageThumbnails;