
export interface MetadataHierarchy {
    Name: string,
    Type: string,
    Children: MetadataHierarchy[] | null
}

export const metadataHierarchies: MetadataHierarchy[] = [
    {
        Name: "Primary",
        Type: "Grade",
        Children: [
            {
                Name: "Math",
                Type: "Subject",
                Children: [
                    {
                        Name: "Fractions",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Decimals",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Algebra",
                        Type: "Topic",
                        Children: null
                    }
                ]
            },
            {
                Name: "Science",
                Type: "Subject",
                Children: [
                    {
                        Name: "Cell System",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Electrical System",
                        Type: "Topic",
                        Children: null
                    }
                ]
            },
        ]
    },
    {
        Name: "LowerSecondary",
        Type: "Grade",
        Children: [
            {
                Name: "Math",
                Type: "Subject",
                Children: [
                    {
                        Name: "Geometry",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Matrices",
                        Type: "Topic",
                        Children: null
                    },
                ]
            },
            {
                Name: "Physics",
                Type: "Subject",
                Children: [
                    {
                        Name: "Transfer of Heat",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Electricity",
                        Type: "Topic",
                        Children: null
                    }
                ]
            },
            {
                Name: "Chemistry",
                Type: "Subject",
                Children: [
                    {
                        Name: "Chemical Composition",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Chemical Changes",
                        Type: "Topic",
                        Children: null
                    }
                ]
            },
            {
                Name: "Biology",
                Type: "Subject",
                Children: [
                    {
                        Name: "Ecosystem",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Transport System",
                        Type: "Topic",
                        Children: null
                    }
                ]
            },
        ]
    },
    {
        Name: "UpperSecondary",
        Type: "Grade",
        Children: [
            {
                Name: "Advanced Math",
                Type: "Subject",
                Children: [
                    {
                        Name: "Differentiation",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Integration",
                        Type: "Topic",
                        Children: null
                    },
                ]
            },
            {
                Name: "Math",
                Type: "Subject",
                Children: [
                    {
                        Name: "Equalities",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Polynomials",
                        Type: "Topic",
                        Children: null
                    },
                ]
            },
            {
                Name: "Physics",
                Type: "Subject",
                Children: [
                    {
                        Name: "Kinematics",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Dynamics",
                        Type: "Topic",
                        Children: null
                    }
                ]
            },
            {
                Name: "Chemistry",
                Type: "Subject",
                Children: [
                    {
                        Name: "Electrolysis",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Chemical Bonding",
                        Type: "Topic",
                        Children: null
                    }
                ]
            },
            {
                Name: "Biology",
                Type: "Subject",
                Children: [
                    {
                        Name: "Biological Molecules",
                        Type: "Topic",
                        Children: null
                    },
                    {
                        Name: "Homeostasis",
                        Type: "Topic",
                        Children: null
                    }
                ]
            },
        ]
    }
]
    