import React from 'react';
import PropTypes from 'prop-types';

import './styles/imagePreview.css';

export const ImagePreview = ({ dataUri, isFullscreen, getSize }) => {
    let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';

    const onImgLoad = ({ target: img }) => {
        const { offsetHeight, offsetWidth } = img;
        getSize(offsetHeight, offsetWidth);
        console.log(offsetHeight, offsetWidth);
    };

    return (
        <div className={'demo-image-preview ' + classNameFullscreen}>
            <img onLoad={onImgLoad} src={dataUri}  alt={'photo'}/>
        </div>
    );
};

ImagePreview.propTypes = {
    dataUri: PropTypes.string,
    isFullscreen: PropTypes.bool,
    getSize: PropTypes.func
};

export default ImagePreview;