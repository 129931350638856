import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Alert, Container, Link, Snackbar, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import {PrimaryTheme} from '../components/themes';
import {ApiError, UsersService} from "../services/autogen";
import {AuthContext} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";

let theme = PrimaryTheme;

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export function Signup() {
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    let navigate = useNavigate();
    
    let [email, setEmail] = useState<string>("");
    let [invalidEmail, setInvalidEmail] = useState<boolean>();
    let [emailValidationHelperText, setEmailValidationHelperText] = useState<string>("");
    let [password, setPassword] = useState<string>("");
    let [confirmPassword, setConfirmPassword] = useState<string>("");
    let [passwordMismatch, setPasswordMismatch] = useState<boolean>(false);
    let [username, setUsername] = useState<string>("");
    let [invalidUsername, setInvalidUsername] = useState<boolean>(false);
    let [usernameValidationHelperText, setUsernameValidationHelperText] = useState<string>("");
    
    let [passwordValidationHelperText, setPasswordValidationHelperText] = useState<string>("");

    let [tncConfirmed, setTncConfirmed] = useState<boolean>(true);
    let [alertMessage, setAlertMessage] = useState<string>("");


    function onEmailChanged (event: React.ChangeEvent<HTMLInputElement>){
        setEmail(event.target.value);
    }

    function onUsernameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setUsername(event.target.value);
    }

    function onPasswordChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
    }

    function onConfirmPasswordChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setConfirmPassword(event.target.value);
    }
    
    useEffect(()=>{
        let hasMismatch = password !== confirmPassword;
        setPasswordMismatch(hasMismatch);
        setPasswordValidationHelperText(hasMismatch ? "Password mismatch" : "");
    },[password, confirmPassword])

    function validateEmail () {
        let hasValidationError = !!email && !emailRegex.test(email);
        setInvalidEmail(hasValidationError);
        setEmailValidationHelperText(hasValidationError ? "Invalid email address" : "");
    }


    function onTncConfirmedChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setTncConfirmed(event.target.checked);
    }

    function validateUsername() {
        if(username.trim().length < 5){
            setInvalidUsername(true);
            setUsernameValidationHelperText("Username must have more than 5 characters")
        } else{
            setInvalidUsername(false);
            setUsernameValidationHelperText("")
        }
    }

    async function doSignUp() {
        try {
            let userDto = await UsersService.postApiV1UsersRegister({
                email: email,
                password,
                userName: username
            });
            if (userDto.token) {
                localStorage.setItem("token", userDto.token);
                setCurrentUser(userDto);
                navigate('/', {replace: true});
            }
        } catch (e) {
            if (e instanceof ApiError){
                setAlertMessage(e.body.message);
                setSnackbarOpen(true);
            }
        }
    }

    const [snackbarIsOpen, setSnackbarOpen] = React.useState(false);
    const handleAlertClick = () => {
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    return (
        <>
            <Box sx={{ display: "flex", backgroundColor: theme.palette.background.paper, minHeight: '1000px', height:'100vw', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: "flex", width:'75vw', maxWidth:'750px', minWidth: '500px', backgroundColor: theme.palette.primary.main, height:'100%', justifyContent: 'center',  }}>
                    <Box sx={{ display: "flex", flexDirection: 'column', width:'75%', maxWidth:'600px',backgroundColor: "white", minWidth: '400px', height:'60%', alignItems: 'center', marginTop: '50px' }}>

                        <Box sx={{ display: 'flex', marginTop: '30px'
                        }}>
                            <Link href="/" underline="none">
                                <Container sx={{display:"inline", width: "auto", margin: "unset", paddingRight: 1}} disableGutters={true}>
                                    <img src="/images/logo.jpg" alt="Logo" width="50" height="50" />
                                </Container>
                                <Typography
                                    variant="h2"
                                    noWrap
                                    component="span"
                                    sx={{ display: { xs: 'none', sm: 'inline', fontSize:'25' }, color: "#008526" }}
                                >
                                    Peak
                                </Typography>
                                <Typography
                                    variant="h2"
                                    noWrap
                                    component="span"
                                    sx={{ display: { xs: 'none', sm: 'inline',  }, color: "#D21A1A" }}
                                >
                                    Qitt
                                </Typography>
                            </Link>
                        </Box>
                        <Typography variant="caption" component="div" sx={{ fontWeight: '600', fontSize: '0.9rem', marginTop: '3px', marginBottom: '10px', color: theme.palette.primary.main }}>
                            Connect the society through knowledge
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ fontWeight: '600', marginTop: '10px', marginBottom: '10px',  }}>
                            Sign Up
                        </Typography>
                        <Box sx={{ width:'80%' }}>
                            <TextField onChange={onEmailChanged} error={invalidEmail} helperText={emailValidationHelperText} value={email} onBlur={validateEmail}
                                       fullWidth id="email-field" label="Email Address" variant="standard" margin="dense"/>
                            <TextField onChange={onUsernameChanged} value={username} error={invalidUsername} onBlur={validateUsername}
                                       helperText={usernameValidationHelperText}
                                       fullWidth id="user-name" label="Username" variant="standard" margin="dense"/>
                            <TextField onChange={onPasswordChanged} value={password} fullWidth type="password" id="password-field" label="Password" variant="standard" autoComplete="current-password" margin="dense" />
                            <TextField onChange={onConfirmPasswordChanged} error={passwordMismatch} helperText={passwordValidationHelperText} value={confirmPassword} fullWidth type="password" id="confirm-password-field" 
                                       label="Confirm Password" variant="standard" autoComplete="current-password" margin="dense" />
                        </Box>

                        <Box sx={{ width:'80%',  }}>
                            <Link href="#">
                                <Button fullWidth variant="contained" color="success" sx={{ marginTop: '30px'}} 
                                        disabled={invalidUsername || !tncConfirmed || passwordMismatch || invalidEmail}
                                        onClick={doSignUp}>
                                    Register
                                </Button>
                            </Link>
                            <Box sx={{ display:'flex', flexDirection: 'column', alignItems: 'center', marginTop: '15px' }}>
                                <Box sx={{ display:'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked value={tncConfirmed}  onChange={onTncConfirmedChanged} sx={{'& .MuiSvgIcon-root': { fontSize: '1rem' }}} />} label="I agree to PeakQitt’s" sx={{'& .MuiTypography-root': { fontSize: '0.75rem' }, marginRight: '3px'}}  />
                                    </FormGroup>
                                    <Typography variant="subtitle1" component="div" sx={{ fontSize: '0.75rem',  }}>
                                        <Link href="https://sites.google.com/synapzter.com/eula" underline="always" color="secondary">
                                            {'Terms of Service and Privacy Policy.'}
                                        </Link>
                                    </Typography>
                                </Box>
                                <Typography variant="caption" component="div" sx={{ fontWeight:'600', marginTop: '30px' }}>
                                    Already registered? Login&nbsp;
                                    <Link href="/login" underline="always" color="secondary">
                                        {'here'}
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>
            <Snackbar open={snackbarIsOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
