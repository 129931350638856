export function strip(html:string){
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}


export function getCloudinarySmallUrl(imageUrl: string): (string|null|undefined) {
    if (imageUrl.startsWith("https://res.cloudinary.com")){
        const tokens = imageUrl.split("/upload/");
        return `${tokens[0]}/upload/c_limit,h_100,w_100/${tokens[1]}`;
    }
    return imageUrl;
}

