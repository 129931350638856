import Typography from "@mui/material/Typography";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";

import {useAuth} from "../contexts/AuthContext";
import {PrimaryTheme} from "./themes";
import {useNavigate} from "react-router-dom";
import {ReactSearchAutocomplete} from "react-search-autocomplete";
import {ThemeProvider, createTheme, styled, alpha,} from '@mui/material/styles';
import {GlobalSearchDto, GlobalSearchItem, OpenAPI, QuestionsService, SearchService} from "../services/autogen";
import InputBase from "@mui/material/InputBase";
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import {Box, CircularProgress, FormControl, ListItemText, MenuItem, Paper, Select, TextField} from "@mui/material";
import debounce from 'lodash/debounce';
import DOMPurify from "dompurify";
//import Select, {SingleValue} from "react-select";

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


type SearchBarProps = {
    children?: React.ReactNode;
    theme?: React.ReactNode;
    setMobileSearch?: any;
    mobileMode?: boolean;
}

const items = [
    {
        id: 0,
        highlight: 'Cobol'
    },
    {
        id: 1,
        highlight: 'JavaScript'
    },

]

const handleOnHover = (result: any) => {
    // the item hovered
    console.log(result)
}

const handleOnSelect = (item : any) => {
    // the item selected
    console.log(item)
}

const handleOnFocus = () => {
    console.log('Focused')
}


export default function SearchBar(props: SearchBarProps) {
    const {currentUser} = useAuth();
    let navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState<null | string>(null);
    const [searchMsg, setSearchMsg] = useState<null | string>("");

    const handleOnSearch = (string : any, results : any) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    const formatResult = (item : any) => {
        console.log(searchResultList);
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.highlight}</span>
            </>
        )
    }

    const Search = styled('div')(({ theme }) => ({
        flex: 1,
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.4),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.35),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    }));

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
    }));

    

    const [selectedOption, setSelectedOption] = useState<any>(null);

    function sleep(delay = 0) {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }

    const [searchResult, setSearchResult] = useState<null | GlobalSearchDto>(null);
    const [searchResultList, setSearchResultList] = useState<Array<GlobalSearchItem>>([]);
    const [searchResultNone, setSearchResultNone] = useState<boolean>(false);
    
    const [open, setOpen] = useState(false);
    //const loading = open && searchResultList.length === 0;
    const [loading, setLoading] = useState(false);
    const [toggleFlag, setToggleFlag] = useState(0);
    
    
    const invokeSearch = async (searchKey: string) => {
        let token = localStorage.getItem("token");
        if (token) {
            OpenAPI.TOKEN = token;
            
            let res = await SearchService.getApiV1SearchAutoComplete(searchKey);

            if (res) {
                setSearchResultList(res.items || []);
                
                if (!res.items) {
                    setSearchResultNone(true)
                }
                
                return res;
            }
            
        }
        
    }

    const getOptionsDelayed = useCallback(
        debounce((text, callback) => {
            setSearchResultList([]);
            invokeSearch(text).then(callback);
        }, 2000),
        []
    );
    
    useEffect(() => {
        
        if ((searchQuery == "") || !searchQuery) {
            return;
        }
        
        setLoading(true);
        
        getOptionsDelayed( searchQuery, () => {
            //invokeSearch(searchQuery || "");
            setLoading(false);
        });
        
    }, [searchQuery, getOptionsDelayed]);


    useEffect(() => {
        if (!open) {
            setSearchResultList([]);
            //setLoading(false);
        }
    }, [open]);
    
    const handleAlertClose = () => {
        setSearchResultNone(false)
    }

    const getHighlightedText = (text : string) => {
        let newtext = ""
        // split on highlight tags to get the arrays.
        let textArray : any = text.split(/(<.*?>.*?<\/.*?>)/g);

        // odd indexes in array will be tags to be replaced with text tag.
        for (var i = 1; i < textArray.length; i += 2) {

            // extract the text string to highlight.
            var word = textArray[i].replace(/<.*?>(.*?)<\/.*?>/, '$1');

            //apply the style and return the text tag.
            textArray[i] = <Typography sx={{ fontWeight: '600', display: 'inline-block' }} key={i}>&nbsp;{word}&nbsp;</Typography>;
            newtext = newtext.concat(textArray[i])

        }
        return textArray;
        //return newtext;
    };

    const getNormalizedText = (text : string) => {
        let newtext = ""
        // split on highlight tags to get the arrays.
        let textArray : any = text.split(/(<.*?>.*?<\/.*?>)/g);

        // odd indexes in array will be tags to be replaced with text tag.
        for (var i = 1; i < textArray.length; i += 2) {

            // extract the text string to highlight.
            var word = textArray[i].replace(/<.*?>(.*?)<\/.*?>/, '$1');

            //apply the style and return the text tag.
            textArray[i] = word;

        }
        for (var i = 0; i < textArray.length; i += 1) {
            
            newtext = newtext.concat(textArray[i])

        }
        //return textArray;
        return newtext;
    };

    const CustomPaper = (props : any) => {
        return <Paper {...props} sx={{backgroundColor: PrimaryTheme.palette.common.white, padding:'0px',}} />;
    };
    
    const loadSelectedItem = (value : any) => {
        if (value?.type == "User") {
            navigate(`/user/${value?.id}`, {
                
            });
        }
        else if (value?.type == "Question") {
            navigate(`/questions/${value?.id}/content`);
        }
        setSearchResultList([]);
        setToggleFlag(toggleFlag+1);
        console.log(value);
        if (props.setMobileSearch) {
            // if called from mobile view, close the search bar on exit
            props.setMobileSearch(false);
        }
    }
    
    return (
        <>
            <ThemeProvider theme={props.theme ? props.theme : PrimaryTheme}>
                <Autocomplete
                    id="asynchronous-search"
                    freeSolo
                    key={toggleFlag}
                    filterOptions={(x) => x}
                    sx={{ width: props.mobileMode? 350 : 600, backgroundColor: PrimaryTheme.palette.background.paper}}
                    size={"small"}
                    PaperComponent={CustomPaper}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    isOptionEqualToValue={(option : GlobalSearchItem, value) =>  option.highlight === value.highlight  }
                    getOptionLabel={(option : GlobalSearchItem ) => (getNormalizedText(option.highlight || "" ) || "")}
                    options={searchResultList}
                    loading={loading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{borderRadius: '5%', backgroundColor: PrimaryTheme.palette.common.white}}
                            label="Search"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            onChange={(e) => {
                                setSearchQuery((e.target as HTMLInputElement).value);
                                //console.log((e.target as HTMLInputElement).value)
                            }}
                        />
                    )}
                    onChange={(event, value) => loadSelectedItem(value)}
                    renderOption={(props, option : GlobalSearchItem) =>
                        <Typography {...props} >
                            {getHighlightedText(option.highlight || "")}
                        </Typography>}
                        
                     
                    
                    
                />
                { searchResultNone &&
                <Snackbar open={open} 
                          autoHideDuration={6000}
                          anchorOrigin={{ vertical:'top', horizontal:'center' }}
                          onClose={handleAlertClose}>
                    <Alert onClose={handleAlertClose} severity="info" sx={{ width: '100%' }}>
                        No Search Results Found
                    </Alert>
                </Snackbar>
                }

            </ThemeProvider>
        </>);
}
    